import {
  Grid,
  Box,
  FormControl,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
  Alert,
  TextField,
  Snackbar,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FileUploader, JobsTable, WorkflowLandingTabs } from "../../components";
import http from "../../net/http-common";
import Loaders from "../../components/common/Loaders";
import { JOB_SERVER_URL } from "../../config";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { useUserAuth } from "../../context";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchSingleSmileData,
  leaderReinventProperties,
} from "./leaderReinventSlice";
import { WorkflowLandingTab } from "../../models";

const LeaderReinvent = () => {
  const MODEL_NAME = "reinvent";
  const [doLoadJobs, setDoLoadJobs] = useState(true);
  const [jobID, setJobID] = useState(-1);
  const [showSubmitMsg, SetShowSubmitMsg] = useState(false);
  const [rows, setRows] = useState<any[]>();
  const [SMILESFile, setSMILESFile] = useState<File>(null);
  const [smiles, setSmiles] = useState("");
  const [headerName, setHeaderName] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [relevantProperty, setRelevantProperty] = useState("");
  const state = useLocation();
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);

  const globalDispatch = useAppDispatch();
  const { properties } = useAppSelector(leaderReinventProperties);
  const singleSmilejobStatus = properties.loading;
  const { user } = useUserAuth();
  const GLOBAL_VARS = {
    DEFAULT_NO_SAMPLE: 10,
    MIN_NO_SAMPLE: 1,
    MAX_NO_SAMPLE: 100000,
  };
  const [moleculesPerLigand, setMoleculesPerLigand] = useState(
    GLOBAL_VARS.DEFAULT_NO_SAMPLE
  );
  const isValidNOSample =
    moleculesPerLigand >= GLOBAL_VARS.MIN_NO_SAMPLE &&
    moleculesPerLigand <= GLOBAL_VARS.MAX_NO_SAMPLE;

  const tabs: Array<WorkflowLandingTab> = [
    {
      id: "0",
      label: "Description",
      value: (
        <>
          <Typography paragraph>
            A powerful system designed to generate molecules with enhanced
            molecular properties and increased drug-likeness. With the input of
            a single molecule SMILES string or text file containing a set of
            molecule SMILES, seamlessly integrated state-of-the-art AI models
            with logical strategies for lead generation enables users to sample
            the most relevant small molecule space with similar or improved
            properties compared to the input set of ligands. The pipeline also
            provides the scores on selected toxicity endpoints along with values
            of molecular properties like QED and SA, offered in a customized
            molecule-filtering dashboard for quickly zeroing in on the most
            relevant molecules.
          </Typography>
          <Box
            component="img"
            src="/images/new-descriptive-images/leader_descriptive.png"
            alt="Leader Image"
            sx={{
              width: "80%",
              mx: "auto",
              display: "block", // Ensures the Box behaves like a block element
              // p: 2, // Adds padding around the image inside the Box
            }}
          />
        </>
      ),
    },
  ];

  const handleFileUpload = async (file: File, name: string) => {
    switch (name) {
      case "smiles":
        setSMILESFile(file);
        break;
      default:
        //console.log("file type unknown");
        break;
    }
  };

  const chemicalProperties: string[] = [
    "SA-Score",
    "QED",
    "HIA(Human-Intestinal-Absorption)",
    "VDSS",
    "Half-Life",
    "Hepatotoxicity",
    "Cardiotoxicity",
    "Clinical-Toxicity",
    "Carcinogenecity",
    "Respiratory-toxicity",
    "Mutagenicity",
    "Cytotoxicity",
    "Lowest-Observed-Adverse-Effect-Level",
  ];

  const handleDownloadSample = () => {
    const link = document.createElement("a");
    link.download = "leader_input.csv";
    link.href = "/samples/reinvent/reinvent.csv";
    link.click();
  };

  useEffect(() => {
    if (!doLoadJobs || !user) return;
    if (state.state?.smilename) {
      setSmiles(state.state.smilename);
      globalDispatch(
        fetchSingleSmileData({
          smileName: state.state?.smilename,
          relevantProperty: relevantProperty,
          moleculesPerLigand: moleculesPerLigand,
        })
      );
      return;
    }
    http
      .get(`${JOB_SERVER_URL}/userjobs`, {
        params: {
          user_id: user.uid,
          model_name: MODEL_NAME,
          start: "0",
          end: "10",
        },
        headers: {
          accept: "application/json",
        },
      })
      .then((res) => {
        setDoLoadJobs(false);
        setRows(res.data);
      })
      .catch((error) => {
        setShowError(true);
        setErrorMsg(errorMessages.jobTableError);
        setDoLoadJobs(false);
        setRows([]);
      });
  }, [doLoadJobs]);

  const handleSubmit = async (e: any) => {
    if (smiles) {
      const smileName = smiles;
      globalDispatch(
        fetchSingleSmileData({
          smileName,
          relevantProperty,
          moleculesPerLigand,
        })
      );
      return;
    }
    setInProgress(true);
    const data = new FormData();
    const jobServerUrl = `${JOB_SERVER_URL}/reinvent/submit`;
    data.append("uid", user.uid);
    data.append("ligand_csv_file", SMILESFile);

    http
      .post(jobServerUrl, data, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
        params: {
          ligand_col_name: headerName,
          relevant_property: relevantProperty,
          num_mols_per_ligand: moleculesPerLigand,
        },
      })
      .then((response: any) => {
        setJobID(response.data.task_id);
        setInProgress(false);
        setDoLoadJobs(true);
        SetShowSubmitMsg(true);
      })
      .catch((error) => {
        setInProgress(false);
        setShowError(true);
        setErrorMsg(errorMessages.submitJobError);
      });
  };

  return (
    <Box py={2}>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Grid container px={3} spacing={2} alignItems="stretch">
        <Grid item sm={12} md={8} sx={{ width: "100%" }} display={"flex"}>
          <WorkflowLandingTabs tabs={tabs} />
        </Grid>
        <Grid item sm={12} md={4} sx={{ width: "100%" }} display={"flex"}>
          <Card sx={{ height: 1, width: "100%" }}>
            <Grid item px={2} py={1.3} sx={{ backgroundColor: "grey.100" }}>
              <i className="ri-login-box-line"></i>
              <Typography
                display={"inline-block"}
                variant={"h6"}
                sx={{ color: "grey.900" }}
                ml={0.5}
                fontWeight={"600"}
              >
                {"Input"}
              </Typography>
            </Grid>
            <CardContent>
              <Typography variant="h6" my={1}>
                Submit Jobs to Leader
              </Typography>
              <FormControl fullWidth>
                <Stack
                  direction="column"
                  spacing={1.5}
                  sx={{
                    p: 2,
                    border: "1px solid #d7d6d6",
                    borderRadius: "0.2rem",
                  }}
                >
                  <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                    <Typography
                      sx={{
                        mt: 1,
                        color: "var(--shade-2900, #29283B)",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      SMILES file (.csv)
                    </Typography>

                    <Button variant="text" onClick={handleDownloadSample}>
                      {"Download Sample"}
                    </Button>
                  </Box>

                  <FileUploader
                    accept={".csv"}
                    handleFileUpload={(files) => {
                      handleFileUpload(files[0], "smiles");
                    }}
                    headerSelector={true}
                    handleSelectedHeader={setHeaderName}
                    deleteHandlerDisable={() => setSMILESFile(null)}
                    isDisabled={!!smiles}
                  />
                  <Typography textAlign={"center"} my={1.5}>
                    -- OR --
                  </Typography>
                  <Typography component="label" htmlFor="smiles-string">
                    Enter a SMILES string
                  </Typography>
                  <TextField
                    placeholder="SMILES string"
                    fullWidth
                    id="smiles-string"
                    name="smiles-string"
                    value={smiles}
                    onChange={(e) => setSmiles(e.target.value)}
                    disabled={!!SMILESFile}
                  />
                </Stack>
                <Typography
                  component="label"
                  htmlFor="relevant-property"
                  mt={2}
                >
                  Select Property
                </Typography>
                <Select
                  fullWidth
                  id="relevant-property"
                  value={relevantProperty}
                  onChange={(e) => setRelevantProperty(e.target.value)}
                  displayEmpty
                >
                  {chemicalProperties.map((chemicalProperty, idx) => {
                    return (
                      <MenuItem key={idx} value={chemicalProperty}>
                        {chemicalProperty}
                      </MenuItem>
                    );
                  })}
                </Select>

                <Typography
                  component="label"
                  htmlFor="molecules-per-ligand"
                  mt={2}
                >
                  Total number of molecules to be generated (Max: 100,000)
                </Typography>

                <FormControl fullWidth error={!isValidNOSample}>
                  <TextField
                    id="molecules-per-ligand"
                    fullWidth
                    type="number"
                    onChange={(e) =>
                      setMoleculesPerLigand(parseInt(e.target.value))
                    }
                    value={moleculesPerLigand}
                  ></TextField>
                  {!isValidNOSample && (
                    <FormHelperText>
                      Error: No of Molecules should be between 1 and 100000
                    </FormHelperText>
                  )}
                </FormControl>

                {singleSmilejobStatus === true && (
                  <>
                    <Typography mt={1}>
                      Your request is being processed. You will be notified once
                      it is completed.
                    </Typography>
                    <LinearProgress sx={{ marginTop: "5px" }} color="success" />
                  </>
                )}
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    mt: 2,
                    "&.Mui-disabled": {
                      background: "#BDADF9",
                      color: "#fff",
                    },
                  }}
                  disabled={
                    singleSmilejobStatus ||
                    !isValidNOSample ||
                    !relevantProperty ||
                    (!smiles && !SMILESFile)
                  }
                >
                  Submit
                </Button>
              </FormControl>
              <IndefiniteLoader state={inProgress} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid sx={{ width: "50%", mx: "auto" }}>
        {showSubmitMsg && (
          <Alert
            onClose={() => {
              SetShowSubmitMsg(false);
            }}
            sx={{ mt: 2 }}
            variant="filled"
            severity="success"
          >{`Job submitted with id ${jobID}`}</Alert>
        )}
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className="jobs-container"
        mt={2}
        px={3}
      >
        <Card sx={{ width: 1 }}>
          <CardContent>
            {doLoadJobs && <Loaders type={"table"} />}
            {!doLoadJobs && (
              <JobsTable
                jobs={rows}
                disableVisualize={false}
                setDoLoadJobs={setDoLoadJobs}
                nextUrl="visualize"
                setErrorMsg={setErrorMsg}
                setShowError={setShowError}
              ></JobsTable>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Box>
  );
};

export default LeaderReinvent;
