import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Divider,
} from "@mui/material";

interface Result {
  MolWt: number;
  MolLogP: number;
  SA: number;
  docking_score: number | null;
  smiles: string;
  QED: number;
  modification_desc: string;
  svg: string;
}

interface Props {
  open: boolean;
  handleClose: () => void;
  results: Result[];
  updateSmiles: (smiles: string) => void;
}

const ResultsModal: React.FC<Props> = ({ open, handleClose, results, updateSmiles }) => {
  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Modifications Results</DialogTitle>
        <DialogContent dividers>

          {
            results.length == 0 &&
            <Typography variant="h6" align="center" color={"red"}>
              No Results Generated
            </Typography>
          }

          {results.map((result, index) => (
            <Box key={index} mb={3}>
              <Typography variant="subtitle1" color="primary">
                Molecule {index + 1}
              </Typography>
              <Box
                component={"img"}
                src={`data:image/svg+xml;base64,${btoa(result.svg)}`}
                sx={{
                  display: "block",
                  m: "auto",
                  width: 300,
                  height: 300,
                  transition: "transform 0.8s ease-in-out",
                }}
              />
              <Typography variant="body2">
                <strong>MolWt:</strong> {result.MolWt}
              </Typography>
              <Typography variant="body2">
                <strong>MolLogP:</strong> {result.MolLogP}
              </Typography>
              <Typography variant="body2">
                <strong>Synthetic Accessibility (SA):</strong> {result.SA}
              </Typography>
              <Typography variant="body2">
                <strong>SMILES:</strong> {result.smiles}
              </Typography>
              <Typography variant="body2">
                <strong>QED:</strong> {result.QED}
              </Typography>
              <Typography>
                <strong>Modification Description:</strong> {result.modification_desc}
              </Typography>
              <Button sx={{ mt: 1 }}
                variant="contained"
                fullWidth
                onClick={() => {
                  updateSmiles(result.smiles)
                  handleClose()
                }}
              >
                Load to Editor
              </Button>
              {index < results.length - 1 && <Divider sx={{ mt: 2 }} />}
            </Box>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ResultsModal;

