import React, { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppActionKind, useAppDispatch } from "../../context";

type PageWrapperProps = {
  children: ReactNode | Array<ReactNode>;
  title: string;
};
function PageWrapper({ title, children }: PageWrapperProps) {
  const appDispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    appDispatch({
      type: AppActionKind.CHANGE_PAGE_TITLE,
      payload: title,
    });
    const element = document.getElementById("main-scroll-anchor");
    if (element) {
      element.scrollIntoView({ block: "start" });
    }
  }, [location]);

  return <React.Fragment>{children}</React.Fragment>;
}

export default PageWrapper;
