// ModuleManager.ts
export class DashBoardManager {
  private comingSoonModules: string[] = ["MolFil", "Neural Sieve","Lodestar"];

  private aiModules: string[] = [
    "MAIMol",
    "MolFil",
    "GenFil",
    "Molecule CoPilot",
    "Bioisosteric Replacement",
    "Leader",
    "Neural Sieve",
    "BioSwap",
  ];

  // Method to get the coming soon modules
  public getComingSoonModules(): string[] {
    return this.comingSoonModules;
  }

  public getAIModules(): string[] {
    return this.aiModules;
  }
}
