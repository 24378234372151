import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Typography,
} from "@mui/material";

import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";

interface CompareMoleculesProps {
  openComapreDialog: boolean;
  setOpenComapreDialog: React.Dispatch<React.SetStateAction<boolean>>;
  dataToCompare: any[];
}
interface LooseObject {
  [key: string]: any;
}

export const CustomToolbar = ({
  props,
  isExport = true,
}: {
  props: any;
  isExport?: boolean;
}) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      {isExport && (
        <GridToolbarExport
          printOptions={{
            disableToolbarButton: true,
          }}
        />
      )}
    </GridToolbarContainer>
  );
};

const CompareMolecules: React.FC<CompareMoleculesProps> = ({
  openComapreDialog,
  setOpenComapreDialog,
  dataToCompare,
}) => {
  const [tabularData, setTabularData] = useState<any>();
  useEffect(() => {
    try {
      const dataObj = [
        dataToCompare[0]["UNL:Z:1"],
        dataToCompare[1]["UNL:Z:1"],
      ];

      const result: LooseObject = {};
      dataObj.forEach((data) => {
        for (let key in data) {
          if (!Array.isArray(data[key]) || data[key].length === 0) continue; // skip if not an array or if lenght = 0
          if (result.hasOwnProperty(key)) {
            // for appending rows with index 1
            let currLength = result[key].row.length + 1;
            let rowList = result[key].row;
            let colList = result[key].cols;

            for (let i = 0; i < data[key].length; i++) {
              rowList.push({
                ...data[key][i],
                id: currLength++,
                smiles: dataToCompare[1].smiles,
              });
            }
            result[key] = {
              row: rowList,
              cols: colList,
            };
          } else {
            const columns: GridColDef[] = [];
            let cols: string[] = Object.keys(data[key][0]);
            cols.forEach((c: string) => {
              columns.push({
                field: c,
                headerName: c,
                width: 100,
                editable: false,
              });
            });
            columns.push({
              field: "smiles",
              headerName: "smiles",
              align: "center",
              headerAlign: "center",
              width: 150,
              editable: false,
            });
            const rowList = data[key].map((r: any) => ({
              ...r,
              smiles: dataToCompare[0].smiles,
            }));
            result[key] = {
              row: rowList,
              cols: columns,
            };
          }
        }
      });
      setTabularData(result);
    } catch (error) {
      // console.log("error: ", error);
    }
  }, [dataToCompare]);

  return (
    <Dialog
      open={openComapreDialog}
      onClose={() => setOpenComapreDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          maxWidth: "80% !important",
          width: "80%",
        },
      }}
    >
      <DialogTitle variant="h5" sx={{ textAlign: "center" }}>
        Compare Molecules
      </DialogTitle>
      <DialogContent
        sx={{
          width: "100%",
          height: "900px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Typography variant="h5" mb={2} mt={2}>
              2D DIAGRAM
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // border : "1px solid"
              }}
            >
              {dataToCompare.map((data, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: dataToCompare.length === 1 ? "50%" : "100%", // put a check based on arr length
                  }}
                >
                  {data?.["UNL:Z:1"]?.image ? (
                    <Box
                      key={index}
                      component="img"
                      src={`data:image/png;base64,${data?.["UNL:Z:1"]?.image}`}
                      sx={{
                        display: "block",
                        margin: "auto",
                        width: "100%",
                        height: "auto",
                        border: "1px dashed",
                      }}
                    />
                  ) : (
                    <Typography textAlign={"center"} mt={3} color={"red"}>
                      No Data Available
                    </Typography>
                  )}
                  <Typography
                    variant="body1"
                    mt={1}
                    sx={{ textAlign: "center", fontWeight: 500 }}
                  >
                    Sr.No: {data.srNo}
                  </Typography>
                  <Typography
                    variant="body1"
                    mt={1}
                    sx={{ textAlign: "center", fontWeight: 500 }}
                  >
                    SMILES: {data.smiles}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item md={12}>
            <Typography variant="h5" my={2}>
              GRAPH
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {dataToCompare.map((data, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: dataToCompare.length === 1 ? "50%" : "100%",
                  }}
                >
                  {data?.plot ? (
                    <Box
                      key={index}
                      component="img"
                      src={`data:image/png;base64,${data?.plot || ""}`}
                      sx={{
                        display: "block",
                        margin: "auto",
                        width: "100%",
                        border: "1px dashed",
                      }}
                    />
                  ) : (
                    <Typography textAlign={"center"} mt={3} color={"red"}>
                      No Data Available
                    </Typography>
                  )}
                  <Typography
                    variant="body1"
                    mt={1}
                    sx={{ textAlign: "center", fontWeight: 500 }}
                  >
                    Sr.No: {data.srNo}
                  </Typography>
                  <Typography
                    variant="body1"
                    mt={1}
                    sx={{ textAlign: "center", fontWeight: 500 }}
                  >
                    SMILES: {data.smiles}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item md={12}>
            <Box sx={{ width: "100%" }}>
              {tabularData &&
                Object.keys(tabularData).map((key: string, idx: number) => (
                  <Box key={idx} mt={2}>
                    <Typography variant="h6" gutterBottom>
                      {key.toUpperCase()}
                    </Typography>
                    <Box>
                      <DataGridPro
                        rows={tabularData[key].row}
                        columns={tabularData[key].cols}
                        autoHeight
                        sx={{
                          "& .MuiDataGrid-columnHeaderTitle": {
                            whiteSpace: "normal",
                            lineHeight: "normal",
                            fontWeight: "550", // Force font weight to bold
                            fontSize: "0.9rem",
                          },
                          "& .MuiDataGrid-columnHeaders": {
                            // Forced to use important since overriding inline styles
                            maxHeight: "175px !important",
                            textAlign: "center",
                            fontWeight: "800 !important",
                          },
                        }}
                        slots={{
                          toolbar: (props) => <CustomToolbar {...props} />,
                        }}
                        pagination
                        initialState={{
                          pagination: {
                            paginationModel: { pageSize: 10 },
                          },
                          pinnedColumns: { left: ["id", "smiles"] },
                        }}
                        pageSizeOptions={[10, 20]}
                      />
                    </Box>
                  </Box>
                ))}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CompareMolecules;
