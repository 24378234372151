import { configureStore } from "@reduxjs/toolkit";
// import { pdbApi } from "../views/PDBInfo/pdbInfoAPI";
import pdbInfoReducer from "../views/PDBInfo/pdbInfoSlice";
import ligandPrepReducer from "../views/LigandPrep/ligandPrepSlice";
import propertiesReducer from "../views/Properties/propertiesSlice";
import propertiesReducerV2 from "../views/Properties/propertiesSliceV2";
import propertiesReducesV3 from "../views/Properties/propertiesSmilesStringSliceV3";
import diffMolReducer from "../views/DiffMol/diffMolSlice";
import admePropertyReducer from "../views/AdmeTox/AdmeSlice";
import dockingModulesReducer from "../views/Docking/dockingSlice";
import promiscuityReducer from "../views/Promiscuity/promiscuitySlice";
import taskStatusReducer from "../components/layout/SnakebarSlice";
import screeniePropertyReducer from "../views/Properties/PropertiesSliceV3";
import liftFilterReducer from "../views/LiftFilter/liftFilterSlice";
import rankerProperty from "../views/Ranker/rankerSlice";
import leaderReinventReducer from "../views/leaderReinvent/leaderReinventSlice";
import bioisoReducer from "../views/Bioisosteric/BioisostericSlice";
import copilotReducer from "../views/Copilot/copilotSlice";

export const store = configureStore({
  reducer: {
    pdbInfo: pdbInfoReducer,
    ligandPrep: ligandPrepReducer,
    properties: propertiesReducer,
    propertiesV2: propertiesReducerV2,
    propertiesV3: propertiesReducesV3,
    diffMol: diffMolReducer,
    admeProperty: admePropertyReducer,
    dockingModule: dockingModulesReducer,
    promiscuity: promiscuityReducer,
    taskStatusProperty: taskStatusReducer,
    screeniePropertyV3: screeniePropertyReducer,
    liftFilters: liftFilterReducer,
    rankerProperty: rankerProperty,
    leaderReinventProperty: leaderReinventReducer,
    bioiso : bioisoReducer,
    copilot : copilotReducer
  },
});

// setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
