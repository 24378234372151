export const rbfeRows: any[] = [
  {
    uuid: "rbfe_87deae34",
    name: "screenie.infer",
    state: "SUCCESS",
    received: 1724132790948.967,
    sent: null,
    started: 1724132790.951456,
    rejected: null,
    succeeded: 1724132793.0300245,
    failed: null,
    retried: null,
    revoked: null,
    args: "({'bucket': 'molgen-prod', 'work_dir': 'infer/screenie/87deae34-cb8c-46e7-8b61-e48483a16749', 'job_id': '87deae34-cb8c-46e7-8b61-e48483a16749', 'user_id': 'ANNcYQ8CRHPmiE71zEzPhDMeFrg1', 'smiles_path': 'inputs/input_screenie.csv', 'smiles_column_name': 'smiles'},)",
    kwargs: "{}",
    eta: null,
    expires: null,
    retries: 0,
    worker: "screenie@f251030c211d",
    result: "None",
    exception: null,
    timestamp: 1724132793.0300245,
    runtime: 2.078234852757305,
    traceback: null,
    exchange: null,
    routing_key: null,
    clock: 11763315,
    client: null,
    root: "87deae34-cb8c-46e7-8b61-e48483a16749",
    root_id: "87deae34-cb8c-46e7-8b61-e48483a16749",
    parent: null,
    parent_id: null,
    children: [],
    id: "rbfe-87deae34",
    model: "screenie",
    jobargs:
      '{"smiles_column": "smiles", "smiles_path": "inputs/input_screenie.csv"}',
  },
  {
    uuid: "rbfe_0a5208cd",
    state: "RECEIVED",
    jobargs:
      '{"smiles_column": "smiles", "smiles_path": "inputs/input_screenie.csv"}',
    received: "2024-06-29T04:22:17.296782+00:00",
    id: "rbfe_0a5208cd",
  },
  {
    uuid: "rbfe_716a4a24",
    name: "screenie.infer",
    state: "FAILURE",
    received: 1726732043435.779,
    sent: null,
    started: 1726733064.8465724,
    rejected: null,
    succeeded: null,
    failed: 1726733068.2691143,
    retried: null,
    revoked: null,
    args: "({'bucket': 'molgen-prod', 'work_dir': 'infer/screenie/716a4a24-5250-457c-a14e-e2085f3b7900', 'job_id': '716a4a24-5250-457c-a14e-e2085f3b7900', 'user_id': 'ANNcYQ8CRHPmiE71zEzPhDMeFrg1', 'smiles_path': 'inputs/input_screenie.csv', 'smiles_column_name': 'smiles'},)",
    kwargs: "{}",
    eta: null,
    expires: null,
    retries: 0,
    worker: "screenie@a92e48c27089",
    result: null,
    exception: "KeyError('smiles')",
    timestamp: 1726733068.2691143,
    runtime: null,
    traceback:
      'Traceback (most recent call last):\n  File "/usr/local/lib/python3.9/site-packages/celery/app/trace.py", line 453, in trace_task\n    R = retval = fun(*args, **kwargs)\n  File "/usr/local/lib/python3.9/site-packages/celery/app/trace.py", line 736, in __protected_call__\n    return self.run(*args, **kwargs)\n  File "/deploy/worker.py", line 74, in infer_screenie\n    raise e\n  File "/deploy/worker.py", line 71, in infer_screenie\n    result = pipeline()\n  File "/deploy/pipeline.py", line 194, in __call__\n    mai_df["smi_cum_count"] = mai_df.groupby(self.smiles_col_name).cumcount()\n  File "/usr/local/lib/python3.9/site-packages/pandas/core/frame.py", line 8252, in groupby\n    return DataFrameGroupBy(\n  File "/usr/local/lib/python3.9/site-packages/pandas/core/groupby/groupby.py", line 931, in __init__\n    grouper, exclusions, obj = get_grouper(\n  File "/usr/local/lib/python3.9/site-packages/pandas/core/groupby/grouper.py", line 985, in get_grouper\n    raise KeyError(gpr)\nKeyError: \'smiles\'\n',
    exchange: null,
    routing_key: null,
    clock: 24908199,
    client: null,
    root: "716a4a24-5250-457c-a14e-e2085f3b7900",
    root_id: "716a4a24-5250-457c-a14e-e2085f3b7900",
    parent: null,
    parent_id: null,
    children: [],
    id: "rbfe_716a4a24",
    model: "screenie",
    jobargs:
      '{"smiles_column": "smiles", "smiles_path": "inputs/input_screenie.csv"}',
  },
  {
    uuid: "rbfe-e77c6c2b",
    state: "RECEIVED",
    jobargs:
      '{"smiles_column": "smiles", "smiles_path": "inputs/input_screenie.csv"}',
    received: "2024-06-25T06:09:41.227213+00:00",
    id: "rbfe-e77c6c2b",
  },
];

export const mdSimulationRows: any[] = [
  {
    uuid: "md_simulation_87deae34",
    name: "screenie.infer",
    state: "SUCCESS",
    received: 1724132790948.967,
    sent: null,
    started: 1724132790.951456,
    rejected: null,
    succeeded: 1724132793.0300245,
    failed: null,
    retried: null,
    revoked: null,
    args: "({'bucket': 'molgen-prod', 'work_dir': 'infer/screenie/87deae34-cb8c-46e7-8b61-e48483a16749', 'job_id': '87deae34-cb8c-46e7-8b61-e48483a16749', 'user_id': 'ANNcYQ8CRHPmiE71zEzPhDMeFrg1', 'smiles_path': 'inputs/input_screenie.csv', 'smiles_column_name': 'smiles'},)",
    kwargs: "{}",
    eta: null,
    expires: null,
    retries: 0,
    worker: "screenie@f251030c211d",
    result: "None",
    exception: null,
    timestamp: 1724132793.0300245,
    runtime: 2.078234852757305,
    traceback: null,
    exchange: null,
    routing_key: null,
    clock: 11763315,
    client: null,
    root: "87deae34-cb8c-46e7-8b61-e48483a16749",
    root_id: "87deae34-cb8c-46e7-8b61-e48483a16749",
    parent: null,
    parent_id: null,
    children: [],
    id: "md_simulation_87deae34",
    model: "screenie",
    jobargs:
      '{"smiles_column": "smiles", "smiles_path": "inputs/input_screenie.csv"}',
  },
  {
    uuid: "md_simulation_0a5208cd",
    state: "RECEIVED",
    jobargs:
      '{"smiles_column": "smiles", "smiles_path": "inputs/input_screenie.csv"}',
    received: "2024-06-29T04:22:17.296782+00:00",
    id: "md_simulation_0a5208cd",
  },
  {
    uuid: "md_simulation_716a4a24",
    name: "screenie.infer",
    state: "FAILURE",
    received: 1726732043435.779,
    sent: null,
    started: 1726733064.8465724,
    rejected: null,
    succeeded: null,
    failed: 1726733068.2691143,
    retried: null,
    revoked: null,
    args: "({'bucket': 'molgen-prod', 'work_dir': 'infer/screenie/716a4a24-5250-457c-a14e-e2085f3b7900', 'job_id': '716a4a24-5250-457c-a14e-e2085f3b7900', 'user_id': 'ANNcYQ8CRHPmiE71zEzPhDMeFrg1', 'smiles_path': 'inputs/input_screenie.csv', 'smiles_column_name': 'smiles'},)",
    kwargs: "{}",
    eta: null,
    expires: null,
    retries: 0,
    worker: "screenie@a92e48c27089",
    result: null,
    exception: "KeyError('smiles')",
    timestamp: 1726733068.2691143,
    runtime: null,
    traceback:
      'Traceback (most recent call last):\n  File "/usr/local/lib/python3.9/site-packages/celery/app/trace.py", line 453, in trace_task\n    R = retval = fun(*args, **kwargs)\n  File "/usr/local/lib/python3.9/site-packages/celery/app/trace.py", line 736, in __protected_call__\n    return self.run(*args, **kwargs)\n  File "/deploy/worker.py", line 74, in infer_screenie\n    raise e\n  File "/deploy/worker.py", line 71, in infer_screenie\n    result = pipeline()\n  File "/deploy/pipeline.py", line 194, in __call__\n    mai_df["smi_cum_count"] = mai_df.groupby(self.smiles_col_name).cumcount()\n  File "/usr/local/lib/python3.9/site-packages/pandas/core/frame.py", line 8252, in groupby\n    return DataFrameGroupBy(\n  File "/usr/local/lib/python3.9/site-packages/pandas/core/groupby/groupby.py", line 931, in __init__\n    grouper, exclusions, obj = get_grouper(\n  File "/usr/local/lib/python3.9/site-packages/pandas/core/groupby/grouper.py", line 985, in get_grouper\n    raise KeyError(gpr)\nKeyError: \'smiles\'\n',
    exchange: null,
    routing_key: null,
    clock: 24908199,
    client: null,
    root: "716a4a24-5250-457c-a14e-e2085f3b7900",
    root_id: "716a4a24-5250-457c-a14e-e2085f3b7900",
    parent: null,
    parent_id: null,
    children: [],
    id: "md_simulation_716a4a24",
    model: "screenie",
    jobargs:
      '{"smiles_column": "smiles", "smiles_path": "inputs/input_screenie.csv"}',
  },
  {
    uuid: "md_simulation_e77c6c2b",
    state: "RECEIVED",
    jobargs:
      '{"smiles_column": "smiles", "smiles_path": "inputs/input_screenie.csv"}',
    received: "2024-06-25T06:09:41.227213+00:00",
    id: "md_simulation_e77c6c2b",
  },
];

export const postMdSimulationRows: any[] = [
  {
    uuid: "post_md_simulation_87deae34",
    name: "screenie.infer",
    state: "SUCCESS",
    received: 1724132790948.967,
    sent: null,
    started: 1724132790.951456,
    rejected: null,
    succeeded: 1724132793.0300245,
    failed: null,
    retried: null,
    revoked: null,
    args: "({'bucket': 'molgen-prod', 'work_dir': 'infer/screenie/87deae34-cb8c-46e7-8b61-e48483a16749', 'job_id': '87deae34-cb8c-46e7-8b61-e48483a16749', 'user_id': 'ANNcYQ8CRHPmiE71zEzPhDMeFrg1', 'smiles_path': 'inputs/input_screenie.csv', 'smiles_column_name': 'smiles'},)",
    kwargs: "{}",
    eta: null,
    expires: null,
    retries: 0,
    worker: "screenie@f251030c211d",
    result: "None",
    exception: null,
    timestamp: 1724132793.0300245,
    runtime: 2.078234852757305,
    traceback: null,
    exchange: null,
    routing_key: null,
    clock: 11763315,
    client: null,
    root: "87deae34-cb8c-46e7-8b61-e48483a16749",
    root_id: "87deae34-cb8c-46e7-8b61-e48483a16749",
    parent: null,
    parent_id: null,
    children: [],
    id: "post_md_simulation_87deae34",
    model: "screenie",
    jobargs:
      '{"smiles_column": "smiles", "smiles_path": "inputs/input_screenie.csv"}',
  },
  {
    uuid: "post_md_simulation_0a5208cd",
    state: "RECEIVED",
    jobargs:
      '{"smiles_column": "smiles", "smiles_path": "inputs/input_screenie.csv"}',
    received: "2024-06-29T04:22:17.296782+00:00",
    id: "post_md_simulation_0a5208cd",
  },
  {
    uuid: "post_md_simulation_716a4a24",
    name: "screenie.infer",
    state: "FAILURE",
    received: 1726732043435.779,
    sent: null,
    started: 1726733064.8465724,
    rejected: null,
    succeeded: null,
    failed: 1726733068.2691143,
    retried: null,
    revoked: null,
    args: "({'bucket': 'molgen-prod', 'work_dir': 'infer/screenie/716a4a24-5250-457c-a14e-e2085f3b7900', 'job_id': '716a4a24-5250-457c-a14e-e2085f3b7900', 'user_id': 'ANNcYQ8CRHPmiE71zEzPhDMeFrg1', 'smiles_path': 'inputs/input_screenie.csv', 'smiles_column_name': 'smiles'},)",
    kwargs: "{}",
    eta: null,
    expires: null,
    retries: 0,
    worker: "screenie@a92e48c27089",
    result: null,
    exception: "KeyError('smiles')",
    timestamp: 1726733068.2691143,
    runtime: null,
    traceback:
      'Traceback (most recent call last):\n  File "/usr/local/lib/python3.9/site-packages/celery/app/trace.py", line 453, in trace_task\n    R = retval = fun(*args, **kwargs)\n  File "/usr/local/lib/python3.9/site-packages/celery/app/trace.py", line 736, in __protected_call__\n    return self.run(*args, **kwargs)\n  File "/deploy/worker.py", line 74, in infer_screenie\n    raise e\n  File "/deploy/worker.py", line 71, in infer_screenie\n    result = pipeline()\n  File "/deploy/pipeline.py", line 194, in __call__\n    mai_df["smi_cum_count"] = mai_df.groupby(self.smiles_col_name).cumcount()\n  File "/usr/local/lib/python3.9/site-packages/pandas/core/frame.py", line 8252, in groupby\n    return DataFrameGroupBy(\n  File "/usr/local/lib/python3.9/site-packages/pandas/core/groupby/groupby.py", line 931, in __init__\n    grouper, exclusions, obj = get_grouper(\n  File "/usr/local/lib/python3.9/site-packages/pandas/core/groupby/grouper.py", line 985, in get_grouper\n    raise KeyError(gpr)\nKeyError: \'smiles\'\n',
    exchange: null,
    routing_key: null,
    clock: 24908199,
    client: null,
    root: "716a4a24-5250-457c-a14e-e2085f3b7900",
    root_id: "716a4a24-5250-457c-a14e-e2085f3b7900",
    parent: null,
    parent_id: null,
    children: [],
    id: "post_md_simulation_716a4a24",
    model: "screenie",
    jobargs:
      '{"smiles_column": "smiles", "smiles_path": "inputs/input_screenie.csv"}',
  },
  {
    uuid: "post_md_simulation_e77c6c2b",
    state: "RECEIVED",
    jobargs:
      '{"smiles_column": "smiles", "smiles_path": "inputs/input_screenie.csv"}',
    received: "2024-06-25T06:09:41.227213+00:00",
    id: "post_md_simulation_e77c6c2b",
  },
];
