export const interactionTypesList: string[] = [
  "hydrophobic interactions",
  "metal complexes",
  "hydrogen bonds",
  "pi stacks",
  "halogen bonds",
  "salt bridges",
  "pi cation",
  "wate bridges",
];
