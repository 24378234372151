import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
  Alert,
  FormHelperText,
  LinearProgress,
  Select,
  MenuItem,
  Snackbar,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context";
import FileUploader from "../common/FileUploader";
// import { valid } from "semver";
// import { boolean } from "ngl/dist/declarations/utils";

import "../../css/beta.css";
import BetaModal from "../common/BetaModal";
import { JOB_SERVER_URL, SOLAR_DEV } from "../../config";
import { RUNTIME } from "../../config";
import http from "../../net/http-common";
import IndefiniteLoader from "../common/IndefiniteLoader";

interface Prop {
  handleSubmitMsg: (status: boolean) => void;
  handleErrorMsg: (status: boolean) => void;
  handleLoadJobs: (status: boolean) => void;
  handleJobID: (id: any) => void;
  modelName: string;
}

function DiffMolFileInputs(prop: Prop) {
  // const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
  const [formVisible, setFormVisible] = useState(true);
  const [jobstatusVisible, setJobstatusVisible] = useState(false);
  const [jobID, setJobID] = useState(-1);
  const [protFile, setProtFile] = useState<File>();
  const [ligandFile, setLigandFile] = useState<File>();
  const [pocketFile, setPocketFile] = useState<File>();
  const [prefix, setPrefix] = useState<string>("");
  const [fetchingFields, setfetchingFields] = useState(false);

  const [showError, setShowError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const [residuesAndProts, setResiduesAndProts] = useState<string[]>([]);
  const [residuesAndProtein, setResiduesAndProtein] = useState("");

  const GLOBAL_VARS = {
    DEFAULT_NO_SAMPLE: 10,
    MIN_NO_SAMPLE: 1,

    MAX_NO_SAMPLE: prop.modelName === "MAIMol" ? 1000 : 1000,
  };

  const [numSamples, setNumSamples] = useState(GLOBAL_VARS.DEFAULT_NO_SAMPLE);
  const [submit, setSubmit] = useState(false);
  const [betaOpen, setBetaOpen] = useState(false);
  const { user } = useUserAuth();

  const runInProd = RUNTIME === "PROD";

  const fileInputs = [
    {
      accept: ".pdb,chemical/x-pdb",
      validExtension: ".pdb",
      type: "protein",
      label: "Protein File (.pdb)",
      onUpload(files: FileList) {
        setProtFile(files[0]);
      },
      handleDownloadSample() {
        const link = document.createElement("a");
        link.download =
          prop.modelName === "MAIMol"
            ? "maimol_protein_sample_input.pdb"
            : "protein_sample_input";
        link.href =
          prop.modelName === "MAIMol"
            ? "/samples/maimol/2p16.pdb"
            : "/samples/diffmol/protein_sample.pdb";
        link.click();
        // //console.log("Protein")
      },
    },
    {
      accept: ".sdf,chemical/x-mdl-sdfile",
      validExtension: ".sdf",
      type: "ligand",
      label: "Ligand File (.sdf)",
      onUpload(files: FileList) {
        setLigandFile(files[0]);
      },
      handleDownloadSample() {
        const link = document.createElement("a");
        link.download =
          prop.modelName === "MAIMol"
            ? "maimol_ligand_sample_input.sdf"
            : "ligand_sample_input.sdf";
        link.href =
          prop.modelName === "MAIMol"
            ? "/samples/maimol/2p16_ligand.sdf"
            : "/samples/diffmol/ligand_sample.sdf";
        link.click();
        ////console.log("Ligand")
      },
    },
    {
      accept: ".pdb,chemical/x-pdb",
      validExtension: ".pdb",
      type: "pocket",
      label: "Protein Pocket File (.pdb)",
      onUpload(files: FileList) {
        setPocketFile(files[0]);
      },
      handleDownloadSample() {
        const link = document.createElement("a");
        link.download = "protein_pocket_sample_input.pdb";
        link.href = "/samples/diffmol/pocket_sample.pdb";
        link.click();
        ////console.log("Pocket")
      },
    },
  ];

  const handleSubmit = async (e: any) => {
    //console.log("submit clicked");

    if (!protFile) {
      setShowError(true);
      setErrorMsg("Please Input Protein File");
      return;
    }

    let resAndProt = residuesAndProtein.split(",");

    let residue = resAndProt[0];
    let protChain = resAndProt[1];

    if (prop.modelName === "MAIMol" && (!residue || !protChain)) {
      setShowError(true);
      setErrorMsg("Residue or Protein Chain is missing");

      return;
    }

    setInProgress(true);
    prop.handleSubmitMsg(false);
    prop.handleErrorMsg(false);
    setSubmit(false);
    const data = new FormData();
    data.append("uid", user.uid);

    data.append("num_samples", "1000");

    if (prop.modelName !== "MAIMol" && !pocketFile) {
      if (!protFile || !ligandFile) {
        setShowError(true);
        setErrorMsg(
          "You need to submit either a protein pocket file or both protein and ligand file"
        );
        setInProgress(false);
        return;
      }
    }

    if (!pocketFile) {
      data.append("protein_file", protFile);
      data.append("ligand_file", ligandFile);
    } else {
      data.append("protein_pocket_file", pocketFile);
    }

    let jobServerUrl = `${JOB_SERVER_URL}/targetdiff/submit`;
    if (prop.modelName == "TagMOL")
      jobServerUrl = `${JOB_SERVER_URL}/targetdiff/submit?model_name=tagmol`;

    if (prop.modelName === "MAIMol") {
      jobServerUrl = `${JOB_SERVER_URL}/maimol_pipeline/submit/v2?protein_chain=${protChain}&residue=${residue}&ligand_present=${true}&model_name=maimol`;
      data.append("protein_file", protFile);
    }

    http
      .post(jobServerUrl, data, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      })
      .then((response: any) => {
        // console.log(response.data);
        setFormVisible(false);
        setJobID(response.data.task_id);
        prop.handleJobID(response.data.task_id);
        setJobstatusVisible(true);
        prop.handleSubmitMsg(true);
        setSubmit(true);
        setInProgress(false);
        prop.handleLoadJobs(true);
      })

      .catch((error) => {
        prop.handleErrorMsg(true);
        setInProgress(false);
      });
  };

  const isValidNOSample =
    numSamples >= GLOBAL_VARS.MIN_NO_SAMPLE &&
    numSamples <= GLOBAL_VARS.MAX_NO_SAMPLE;

  const onBetaClick = () => {
    //    console.log("clicked beta access");
    setBetaOpen(true);
  };

  useEffect(() => {
    if (!protFile) return;

    const form = new FormData();
    form.append("protein_file", protFile);

    setfetchingFields(true);

    const response = http
      .post(`${JOB_SERVER_URL}/maimol_pipeline/parse_pdb`, form, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      })
      .then((response: any) => {
        setfetchingFields(false);
        const result = response.data.result;
        if (result.length === 0) {
          setShowError(true);
          setErrorMsg(
            "Ligand not detected, please submit PDB file with ligand present"
          );
          return;
        }
        const modifiedResult = result.map((item: string[]) => item.join(","));
        setResiduesAndProts(modifiedResult);

        // console.log('resandprot',resAndProt);

        setResiduesAndProtein(modifiedResult[0]);
      })
      .catch((error) => {
        setfetchingFields(false);
      });
  }, [protFile]);

  useEffect(() => {
    if (prop.modelName === "TargetDiff") {
      setPrefix("diffmol_");
    } else if (prop.modelName === "TagMOL") {
      setPrefix("tagmol_");
    } else if (prop.modelName === "MAIMol") {
      setPrefix("maimol_");
    }
  }, [prop.modelName]);

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Card sx={{ height: 1 }}>
        <Grid item px={2} py={1.3} sx={{ backgroundColor: "grey.100" }}>
          <i className="ri-login-box-line"></i>
          <Typography
            display={"inline-block"}
            variant={"h6"}
            sx={{ color: "grey.900" }}
            ml={0.5}
            fontWeight={"600"}
          >
            {"Input"}
          </Typography>
        </Grid>
        <CardContent sx={{ height: 1 }}>
          <Grid container direction="column">
            <Typography variant="h6" mt={1}>
              Submit Jobs to{" "}
              {prop.modelName === "TargetDiff" ? "DiffMol" : prop.modelName}
            </Typography>
            {runInProd && (
              <Grid container direction="column" className="beta-anchor">
                <Grid item xs={12} sx={{ mt: 5 }}>
                  <Typography>
                    This is a Premium feature. Try with{" "}
                    <Button variant="contained" onClick={onBetaClick}>
                      BETA ACCESS
                    </Button>
                    <BetaModal
                      open={betaOpen}
                      openHandler={setBetaOpen}
                    ></BetaModal>
                  </Typography>
                </Grid>
              </Grid>
            )}
            <FormControl className={RUNTIME === "PROD" ? "blurry-bg" : ""}>
              <Grid container spacing={1} mt={1} direction="column">
                {fileInputs
                  .slice(0, prop.modelName === "MAIMol" ? 1 : 2)
                  .map(
                    (
                      { label, accept, onUpload, handleDownloadSample },
                      index
                    ) => (
                      <Grid item key={index}>
                        <Stack direction="column" spacing={1}>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            mb={1}
                          >
                            <Typography
                              sx={{
                                mt: 1,
                                color: "var(--shade-2900, #29283B)",
                                fontSize: "1rem",
                                fontWeight: "bold",
                              }}
                            >
                              {label}
                            </Typography>

                            <Button
                              variant="text"
                              onClick={handleDownloadSample}
                            >
                              {"Download Sample"}
                            </Button>
                          </Box>
                          <FileUploader
                            accept={accept}
                            handleFileUpload={onUpload}
                            deleteHandlerDisable={() => {
                              if (label === "Protein File") setProtFile(null);
                              if (label === "Ligand File") setLigandFile(null);
                              setResiduesAndProtein("");
                              setResiduesAndProts([]);
                              return false;
                            }}
                          />
                        </Stack>
                        {index === 0 && prop.modelName === "MAIMol" && (
                          <>
                            <Stack direction="column" spacing={1.5}>
                              <FormControl>
                                <Typography
                                  sx={{
                                    mt: 1,
                                    color: "var(--shade-2900, #29283B)",
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Residue and Protein Chain
                                </Typography>
                                {fetchingFields ? <LinearProgress /> : ""}
                                <Select
                                  disabled={residuesAndProts?.length === 0}
                                  value={residuesAndProtein}
                                  label="Residue and Protein Chain"
                                  onChange={(e) =>
                                    setResiduesAndProtein(e.target.value)
                                  }
                                >
                                  {residuesAndProts?.map((v, i) => {
                                    return (
                                      <MenuItem key={i} value={v}>
                                        {v}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Stack>
                          </>
                        )}
                      </Grid>
                    )
                  )}
                {prop.modelName !== "MAIMol" && (
                  <Typography textAlign={"center"} my={1.5}>
                    -- OR --
                  </Typography>
                )}

                {prop.modelName !== "MAIMol" &&
                  fileInputs
                    .slice(2)
                    .map(
                      ({ label, accept, onUpload, handleDownloadSample }, idx) => (
                        <Grid item key={idx}>
                          <Stack direction="column" spacing={1}>
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              mb={1}
                            >
                              <Typography
                                sx={{
                                  mt: 1,
                                  color: "var(--shade-2900, #29283B)",
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                }}
                              >
                                {label}
                              </Typography>

                              <Button
                                variant="text"
                                onClick={handleDownloadSample}
                              >
                                {"Download Sample"}
                              </Button>
                            </Box>
                            {/* passing accept parameter occasionally allowing sdf file only */}
                            <FileUploader
                              accept={".pdb,chemical/x-pdb"}
                              handleFileUpload={onUpload}
                              deleteHandlerDisable={() => {
                                if (label === "Protein Pocket File")
                                  setPocketFile(null);
                              }}
                            />
                          </Stack>
                        </Grid>
                      )
                    )}
                {prop.modelName === "MAIMol" ? (
                  <Grid item xs={4}></Grid>
                ) : (
                  <Grid item xs={4}>
                    <Stack direction="column" spacing={2} mt={2}>
                      <Typography
                        sx={{
                          color: "var(--shade-2900, #29283B)",
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                      >
                        Number of Molecules (max 1000)
                      </Typography>
                      <FormControl fullWidth error={!isValidNOSample}>
                        <TextField
                          fullWidth
                          type="number"
                          onChange={(e) =>
                            setNumSamples(parseInt(e.target.value))
                          }
                          value={numSamples}
                        ></TextField>
                        {!isValidNOSample && (
                          <FormHelperText>
                            Error: No of Molecules should be between 1 and 1000
                          </FormHelperText>
                        )}
                      </FormControl>
                      <Typography
                        sx={{
                          color: "var(--shade-2900, #29283B)",
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                      >
                        Molecule Prefix
                      </Typography>
                      <FormControl fullWidth error={!isValidNOSample}>
                        <TextField
                          fullWidth
                          type="string"
                          value={prefix}
                          onChange={(e) => setPrefix(e.target.value)}
                        ></TextField>
                      </FormControl>
                    </Stack>
                  </Grid>
                )}
                <Grid item mt={"auto"}>
                  <Button variant="contained" onClick={handleSubmit} fullWidth>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
        </CardContent>
      </Card>
      <IndefiniteLoader state={inProgress} />
    </>
  );
}

export default DiffMolFileInputs;
