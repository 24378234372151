import {
  Box,
  Button,
  CardActions,
  CardContent,
  Stack,
  Typography,
  Alert,
  Snackbar,
  Grid,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { LIFT_SERVER_URL, RUNTIME } from "../../config";

import http from "../../net/http-common";

import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { FileUploader } from "../../components";

function LiftVisualizerInput() {
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);
  const [PDBFile, setPDBFile] = useState<File>(null);
  const [pdbId, setPdbId] = useState<string>("");
  const [inProgress, setInProgress] = useState<boolean>(false);

  const navigate = useNavigate();

  const submitButtonDisabled = pdbId || PDBFile ? true : false;

  const handleFileUpload = async (file: File, name: string) => {
    switch (name) {
      case "pdb":
        setPDBFile(file);
        break;
      default:
        break;
    }
  };

  // function to delete file
  // const deleteHandlerDisable = () => {
  //   setSMILESFile(null);
  // };

  const handleSubmit = async (e: any) => {
    // removed old code, 23 May 2024
    setInProgress(true);

    try {
      const form = new FormData();
      form.append("pdb_file", PDBFile);
      let response = {
        data: "",
      };

      if (PDBFile) {
        response = await http.post(
          `${LIFT_SERVER_URL}/LIFT_api/get_interaction_pdb_complex/`,
          form,
          {
            headers: {
              accept: "application/json",
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "http://localhost:3000",
            },
          }
        );
      } else if (pdbId) {
        response = await http.post(
          `${LIFT_SERVER_URL}/LIFT_api/get_interaction_pdb_id/?pdb_id=${pdbId}`,
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
            },
          }
        );
      }

      if (Object.keys(response.data).length === 0) {
        setInProgress(false);

        setShowError(true);
        setErrorMsg("No data found for this PDB File");
        return;
      }

      const visualizerResult = response.data;

      navigate("result", { state: { visualizerResult } });
    } catch (error) {
      console.log("error is", error);
      setShowError(true);
      setErrorMsg("Facing Problem while Fetching Data");
    }
    setInProgress(false);
  };

  const handleDownloadSample = () => {
    const link = document.createElement("a");
    link.download = "lift_visualizer_sample1.pdb";
    link.href = "/samples/lift/2p16.pdb";
    link.click();
  };

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Box sx={{ height: 1, width: "100%" }}>
        <Grid
          container
          className={RUNTIME === "PROD" ? "blurry-bg" : ""}
          display="flex"
          flexDirection="column"
        >
          <CardContent>
            <Box>
              <Stack direction="column" spacing={1.5}>
                <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Protein File (.pdb)
                  </Typography>

                  <Button variant="text" onClick={handleDownloadSample}>
                    {"Download Sample"}
                  </Button>
                </Box>

                <FileUploader
                  accept={".pdb"}
                  handleFileUpload={(files) =>
                    handleFileUpload(files[0], "pdb")
                  }
                  deleteHandlerDisable={() => {
                    setPDBFile(null);

                    return false;
                  }}
                  isDisabled={!!pdbId}
                />
              </Stack>
            </Box>

            <Typography textAlign={"center"} my={1.5}>
              -- OR --
            </Typography>

            <Typography component="label" htmlFor="smiles-string">
              Enter a PDB ID
            </Typography>
            <TextField
              placeholder="4ffw"
              fullWidth
              id="pdb-id"
              name="pdb-id"
              value={pdbId}
              onChange={(e) => {
                setPdbId(e.target.value);
              }}
              disabled={!!PDBFile}
            />
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                width: "100%",
                mt: 2,
                "&.Mui-disabled": {
                  background: "#BDADF9",
                  color: "#fff",
                },
              }}
              disabled={!submitButtonDisabled}
            >
              Submit
            </Button>
          </CardContent>
        </Grid>
        <IndefiniteLoader state={inProgress} />
      </Box>
    </>
  );
}

export default LiftVisualizerInput;
