import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { store, type RootState } from "../../app/store";
import { PropertiesState } from "../../models";
import axios from "axios";
import { error } from "console";
import { JOB_SERVER_URL } from "../../config";
import http from "../../net/http-common";
import { getSingleSmileData } from "./admetAPIs";

import { setTaskStatus } from "../../components/layout/SnakebarSlice";

interface stateType {
  status: string;
  properties: {
    data: any;
    loading: boolean;
    singleSmileData: any;
    singleSmileForBridge: string;
    dataOnSingleSmile?: {
      data: any;
      status: "pending" | "success" | "watched" | "error";
    };
  };
}

const initialState: stateType = {
  status: "idle",
  properties: {
    data: [],
    loading: false,
    singleSmileData: null,
    singleSmileForBridge: "",
    dataOnSingleSmile: {
      data: null,
      status: "watched",
    },
  },
};

export const fetchAdmeProperties = createAsyncThunk(
  "admeProperties/fetchAdmeProperties",
  async (task_id: string) => {
    return http
      .get(
        `${JOB_SERVER_URL}/adme_tox/results?task_id=${task_id}&as_file=false`
      )
      .then((response) => response.data)
      .catch((error) => {
        console.log(error);
      });
  }
);

// set data when user enters single smile value
export const fetchSingleSmileData = createAsyncThunk(
  "admeProperties/fetchSingleSmileData",
  async ({ smileName }: { smileName: string }, { dispatch }) => {
    try {
      dispatch(
        setTaskStatus({
          status: "pending",
          varient: "info",
          msg: "ADMET models running..",
          url: "",
          autoHideTime: 8000,
        })
      );
      const response = await getSingleSmileData({ smileName });

      // console.log('response',response);

      if(response.data.status==="Failed"){
        throw new Error("Invalid Smiles, Please Enter correct Smiles");
      }
      

      dispatch(
        setTaskStatus({
          status: "success",
          varient: "success",
          msg: "ADMET request finished successfully.",
          url: "/adme/admet-pro/evaluations",
          autoHideTime: 30000,
        })
      );
      return response.data;
    } catch (error:any) {
      console.log("error", error);
      let msg="Error! Your request failed..";
      if(error.message && error.message==="Invalid Smiles, Please Enter correct Smiles"){
        msg="Invalid Smiles, Please Enter correct Smiles";
      }

      dispatch(
        setTaskStatus({
          status: "error",
          varient: "error",
          msg: msg,
          url: "",
          autoHideTime: 8000,
        })
      );
      return { error };
    }
  }
);

const admePropertiesSlice = createSlice({
  name: "admeProperties",

  initialState,
  reducers: {
    setProperties(state, action) {
      ////console.log(action);
    },
    setSingleSmileData(state, action) {
      state.properties.singleSmileData = action.payload;
    },
    setSingleSimleForBridge(state, action) {
      //    //console.log("acion", action.payload);

      state.properties.singleSmileForBridge = action.payload;
    },
    setSingleSmileDataFromADMEPage(state, action) {
      state.properties.dataOnSingleSmile.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Fetch Properties
    builder
      .addCase(
        fetchAdmeProperties.fulfilled,
        (state: stateType, action: any) => {
          state.properties.data = action.payload;
          state.properties.loading = false;
        }
      )
      .addCase(fetchAdmeProperties.rejected, (state, action: any) => {
        state.properties.loading = false;
      })
      .addCase(fetchAdmeProperties.pending, (state) => {
        state.properties.loading = true;
      })

      .addCase(
        fetchSingleSmileData.fulfilled,
        (state: stateType, action: any) => {
          state.properties.dataOnSingleSmile.data = action.payload;
          state.properties.dataOnSingleSmile.status = "success";
        }
      )
      .addCase(fetchSingleSmileData.rejected, (state, action: any) => {
        state.properties.dataOnSingleSmile.status = "error";
        // console.log("status", state.properties.dataOnSingleSmile.status);
      })
      .addCase(fetchSingleSmileData.pending, (state) => {
        state.properties.dataOnSingleSmile.status = "pending";
      });
  },
});

export const {
  setProperties,
  setSingleSmileData,
  setSingleSimleForBridge,
  setSingleSmileDataFromADMEPage,
} = admePropertiesSlice.actions;
export const selectAdmetProperties = (state: RootState) => state.admeProperty;
export default admePropertiesSlice.reducer;
