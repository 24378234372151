import { Card, Grid } from "@mui/material";

import { DashCard } from "../../components";
import { Link } from "react-router-dom";
import { DashBoardManager } from "../../components/Dashboard/HelperModule";

const dockingCards = [
  {
    label: "Docking",
    link: "docking",
    description: "Automated Molecular docking for your targets!",
    dashIcon: "images/new-dashcards-images/docking_dashcard_moleculegen.png",
    navlabel: "Docking",
  },
  {
    label: "Screenie",
    navlabel: "Screenie",
    link: "properties-v3",
    icon: "/images/icons/property-filter.svg",
    description: "Filter hit molecules based on physicochemical properties",
    dashIcon: "/images/new-dashcards-images/screenie_dashcard_moleculegen.png",
  },
  {
    label: "LIFT Visualizer",
    link: "lift/visualizer",
    description: "Visualize the interactions for each binding site.",
    dashIcon:
      "images/new-dashcards-images/lift_visualizer_dashcard_moleculegen.png",
    navlabel: "Lift Visualizer",
  },
  {
    label: "LIFT Filter",
    link: "lift/filter-molecules",
    description: "Filter molecules based on their conserved signature.",
    dashIcon:
      "images/new-dashcards-images/lift_filter_dashcard_moleculegen.png",
    navlabel: "Filter Molecules",
  },
  {
    label: "Neural Sieve",
    navlabel: "Neural Sieve",
    link: "molfil",
    icon: "/images/icons/property-filter.svg",
    description: "Molecule filtering based on our proprietary Neural Network.",
    dashIcon:
      "/images/new-dashcards-images/neural_sieve_dashcard_moleculegen.png",
  },
  {
    label: "GenFil",
    navlabel: "GenFil",
    link: "genfil",
    icon: "/images/icons/property-filter.svg",
    description:
      "Molecule filtering based on our proprietary Generative AI model",
    dashIcon: "images/new-dashcards-images/genfil_dashcard_moleculegen.png",
  },
];

function DockingDashboard() {
  // Create an instance of the class
  const dashBoardManager = new DashBoardManager();

  // Get the coming soon modules
  const comingSooonModules = dashBoardManager.getComingSoonModules();

  return (
    <Grid container spacing={3} p={3}>
      {dockingCards.map((card, index) => (
        <Grid item key={index} xs={12} md={6} lg={4} xl={3}>
          <Link to={comingSooonModules.includes(card.label) ? "#" : card.link}>
            <Card
              variant="outlined"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 2,
                flex: "1 0 0",
                borderRadius: "18px",
                border: "1px solid var(--shade-200, #E7EBF9)",
                height: "100%",
                "&:hover": {
                  boxShadow: 7,
                },
              }}
            >
              <DashCard key={index} {...card} section="Docking" />
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}

export default DockingDashboard;
