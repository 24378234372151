import { useEffect, useState } from "react";
import { JobsTable, WorkflowLandingTabs } from "../../components";
import { WorkflowLandingTab } from "../../models";
import http from "../../net/http-common";
import {
  Grid,
  Typography,
  Box,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Tab,
} from "@mui/material";
import Loaders from "../../components/common/Loaders";
import { JOB_SERVER_URL } from "../../config";
import { useUserAuth } from "../../context";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import LodestarInputs from "../../components/Lodestar/LodestarInputs";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  mdSimulationRows,
  postMdSimulationRows,
  rbfeRows,
} from "../../components/Lodestar/LodestarHelper";

const tabs: Array<WorkflowLandingTab> = [
  {
    id: "0",
    label: "Description",
    value: (
      <>
        <Typography>
          Lodestar is a versatile computational module designed to streamline
          Relative Binding Free Energy (RBFE) calculations, Molecular Dynamics
          (MD) simulations, and comprehensive post-simulation analysis. With
          Lodestar, users can accurately simulate molecular interactions, track
          binding affinities, and gain insightful post-MD analyses, all within a
          seamless workflow tailored for high-throughput computational chemistry
          and drug discovery.
        </Typography>
        <Box
          component="img"
          src="/images/new-descriptive-images/lodestar_descriptive.png"
          alt="Protein Info Visual"
          sx={{
            width: "60%", // Reduces the width to 80% of the parent container
            height: "auto",
            mt: 2, // Adds margin-top for space above the image
            mx: "auto", // Centers the image horizontally
            display: "block", // Ensures the Box behaves like a block element
            p: 2, // Adds padding around the image inside the Box
            pb: 0,
          }}
        />
      </>
    ),
  },
];

function LodestarHome() {
  const MODEL_NAME = "lift";
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [tabValue, setTabValue] = useState("1");
  const [showSubmitMsg, SetShowSubmitMsg] = useState(false);
  const [jobID, setJobID] = useState(-1);
  const [doLoadJobs, setDoLoadJobs] = useState(true);
  const [rows, setRows] = useState<[]>();
  const { user } = useUserAuth();

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!doLoadJobs || !user) return;

    http
      .get(`${JOB_SERVER_URL}/userjobs`, {
        params: {
          user_id: user.uid,
          model_name: MODEL_NAME,
          start: "0",
          end: "10",
        },
        headers: {
          accept: "application/json",
        },
      })
      .then((res) => {
        setDoLoadJobs(false);
        setRows(res.data);
      })
      .catch((error) => {
        setShowError(true);
        setErrorMsg(errorMessages.jobTableError);
        setDoLoadJobs(false);
        setRows([]);
      });
  }, [doLoadJobs]);

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Grid px={3} container spacing={2.5} mt={-1}>
        <Grid item sm={12} md={8} sx={{ width: "100%" }} display={"flex"}>
          <WorkflowLandingTabs tabs={tabs} />
        </Grid>
        <Grid item sm={12} md={4} sx={{ width: "100%" }} display={"flex"}>
          <Card sx={{ width: "100%" }}>
            <LodestarInputs
              setJobID={setJobID}
              SetShowSubmitMsg={SetShowSubmitMsg}
              setDoLoadJobs={setDoLoadJobs}
            />
          </Card>
        </Grid>
      </Grid>

      <Grid sx={{ width: "50%", mx: "auto" }}>
        {showSubmitMsg && (
          <Alert
            onClose={() => {
              SetShowSubmitMsg(false);
            }}
            sx={{ mt: 2 }}
            variant="filled"
            severity="success"
          >{`Job submitted with id ${jobID}`}</Alert>
        )}
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className="jobs-container"
        mt={2}
        mb={2}
        px={3}
      >
        <Card sx={{ width: 1 }}>
          <CardContent>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="filter tabs"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label="RBFE Calculations" value="1" />
                  <Tab label="MD Simulations" value="2" />
                  <Tab label="Post MD Simulations Analysis" value="3" />
                </TabList>
              </Box>
              {/* rbfe calculations */}
              <TabPanel
                value="1"
                sx={{
                  p: 0,
                  mt: 0.5,
                }}
              >
                {doLoadJobs && <Loaders type={"table"} />}
                {!doLoadJobs && (
                  <JobsTable
                    jobs={rbfeRows}
                    setDoLoadJobs={setDoLoadJobs}
                    nextUrl="visualize"
                    setErrorMsg={setErrorMsg}
                    setShowError={setShowError}
                    disableVisualize={true}
                  ></JobsTable>
                )}
              </TabPanel>
              {/* md simulation */}
              <TabPanel
                value="2"
                sx={{
                  p: 0,
                  mt: 0.5,
                }}
              >
                {doLoadJobs && <Loaders type={"table"} />}
                {!doLoadJobs && (
                  <JobsTable
                    jobs={mdSimulationRows}
                    setDoLoadJobs={setDoLoadJobs}
                    nextUrl="visualize"
                    setErrorMsg={setErrorMsg}
                    setShowError={setShowError}
                    disableVisualize={true}
                  ></JobsTable>
                )}
              </TabPanel>
              {/* post md simulation */}
              <TabPanel
                value="3"
                sx={{
                  p: 0,
                  mt: 0.5,
                }}
              >
                {doLoadJobs && <Loaders type={"table"} />}
                {!doLoadJobs && (
                  <JobsTable
                    jobs={postMdSimulationRows}
                    setDoLoadJobs={setDoLoadJobs}
                    nextUrl="visualize"
                    setErrorMsg={setErrorMsg}
                    setShowError={setShowError}
                    disableVisualize={true}
                  ></JobsTable>
                )}
              </TabPanel>
            </TabContext>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default LodestarHome;
