import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Avatar,
  Divider,
  Button,
  Paper,
  Box,
  Typography,
  Stack,
  FormControl,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Snackbar,
  Alert,
  AlertColor,
  IconButton,
} from "@mui/material";
import { Editor } from "ketcher-react";
import { Ketcher } from "ketcher-core";
import FileDownloadIcon from '@mui/icons-material/FileDownloadRounded';
import { StandaloneStructServiceProvider } from "ketcher-standalone";
import MolEditorResultV3 from "../../components/MolEditor/MolEditorResultV3";
import http from "../../net/http-common";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { addResults, addModifications, getPDBFile } from "./copilotApi";
import { getSessionData } from "./copilotSlice";
import { useSelector, useDispatch } from "react-redux";
import { useUserAuth } from "../../context";
import ModificationDialog from "../../components/MoleculeCopilot/ModificationDialog";

const structServiceProvider = new StandaloneStructServiceProvider();

function PropertyEditor() {
  const [isCopilotEnabled, setIsCopilotEnabled] = useState<boolean>(true);
  const [smileValue, setSmileValue] = useState<string>("");
  const [molEditorResult, setMolEditorResult] = useState<any[]>([]);
  const [PDBFile, setPDBFile] = useState<File>(null);
  const [alertMsg, setAlertMsg] = useState<string>("");
  const [ketcher, setKetcher] = useState<any>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [renderUi, setRenderUi] = useState(false);
  const [alignment, setAlignment] = useState<string>("advance");
  const [advance, setAdvance] = useState<boolean>(true);
  const [modificationText, setModificationText] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [modificationResult, setModificationResult] = useState([]);
  const [modificationComplete, setModificationComplete] = useState(true);
  const [manualId, setManualId] = useState(0);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const copilotData = useSelector((state: any) => state.copilot);
  const dispatchForAction = useDispatch<any>();
  const scrollToRef = useRef(null);

  const handleScroll = () => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment) {
      if (newAlignment === "advance") {
        setAdvance(true);
      } else {
        setAdvance(false);
      }
      setAlignment(newAlignment);
    }
  };

  const handleGetModifications = async () => {
    try {
      setModificationComplete(false);

      const modificationResult = await addModifications({
        input_modification: modificationText,
        session_id: copilotData.editorInfo.result.session_id,
        input_smiles: smileValue,
        modification_name: `model_${
          copilotData.modifications.result.length + 1
        }`,
      });

      const addResultRequestParams = modificationResult.data.result.results.map(
        (it: any) => {
          return {
            MolLogP: it.MolLogP,
            MolWt: it.MolWt,
            QED: it.QED,
            SA: it.SA,
            SDF: it.SDF,
            docking_score: it.docking_score,
            modification_desc: it.modification_desc,
            session_id: copilotData.editorInfo.result.session_id,
            smiles: it.smiles,
            modification_name: `model_${
              copilotData.modifications.result.length + 1
            }`,
          };
        }
      );
      const requestPayload = {
        results: addResultRequestParams,
        input_smiles: smileValue,
      };
      await addResults(requestPayload);
      dispatchForAction(
        getSessionData(copilotData.editorInfo.result.session_id)
      );
    } catch (error) {
      console.log(error);
      setShowAlert(true);
      setAlertMsg("Error while getting modification(s).");
      setModificationComplete(true);
    }
  };

  useEffect(() => {
    try {
      if (copilotData.loading === "succeeded") {
        const copilotEnabled = copilotData.editorInfo.result.is_copilot_enabled;
        setIsCopilotEnabled(copilotEnabled);
        setAdvance(copilotEnabled);
        setAlignment(copilotEnabled ? "advance" : "editor");
        setMolEditorResult(copilotData.molProperties.result);
        setSmileValue(copilotData.editorInfo.result.current_smiles);
        const id = Math.max(
          0,
          ...copilotData.molProperties.result.map(
            (e: { modification_name: string }) =>
              e.modification_name.startsWith("manual_")
                ? +e.modification_name.split("_")[1]
                : 0
          )
        );
        setManualId(id + 1);
        setRenderUi(true);
        setModificationComplete(true);
      } else if (
        copilotData.loading === "failed" ||
        copilotData.loading === "idle"
      ) {
        navigate("/molecule-design/molbench");
      }
    } catch (error) {
      setShowAlert(true);
      setAlertMsg("Error while fetching session Info.");
    }
  }, [copilotData]);

  useEffect(() => {
    const fetchPDBFile = async () => {
      try {
        const PDBData = await getPDBFile({
          session_id: copilotData.editorInfo.result.session_id,
          uid: user.uid,
        });
        setPDBFile(PDBData.data);
      } catch (error) {
        setShowAlert(true);
        setAlertMsg(`Error while fetching PDB file. ${error}`);
      }
    };
    if (copilotData.loading === "succeeded" && !PDBFile) {
      fetchPDBFile();
    }
  }, [copilotData]);

  useEffect(() => {
    setTimeout(() => {
      handleScroll();
    },1500);
  }, []);
  
  const handleStructureChange = async (ketcher: Ketcher) => {
    const smiles = await ketcher.getSmiles();
    setSmileValue(smiles);
  };

  const handlePdbdDownload = async () => {
    // const sessionId = copilotData.editorInfo.result.session_id;
    const href = window.URL.createObjectURL(new Blob([PDBFile]));
    const anchorElement = document.createElement("a");
    anchorElement.href = href;
    anchorElement.download = copilotData.editorInfo.result.pdb_file_name;
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  };

  const handleSliderClick = (results: []) => {
    setDialogOpen(true);
    setModificationResult(results);
  };

  const getMoleculeProperties = async () => {
    try {
      setModificationComplete(false);
      // check for duplicate
      for (let result of molEditorResult) {
        if (result.smiles === smileValue) {
          setAlertSeverity("info");
          setAlertMsg(
            `The properties for this SMILES have already been calculated.
              Please check the results with Modification Id: ${result.modification_name}`
          );
          setShowAlert(true);
          setModificationComplete(true);
          return;
        }
      }

      const request1 = await http.post(
        `/properties/properties/v3`,
        { smiles: smileValue },
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
          },
        }
      );
      const propertyObj = request1.data.physico_chemical_properties;
      const addResultRequestParams: any = [
        {
          MolLogP: propertyObj.MolLogP,
          MolWt: propertyObj.MolWt,
          QED: propertyObj.QED,
          SA: propertyObj.SA,
          SDF: propertyObj.SDF || "",
          docking_score: null,
          modification_desc: propertyObj.modification_desc || "test",
          session_id: copilotData.editorInfo.result.session_id,
          smiles: propertyObj.smiles,
          modification_name: `manual_${manualId}`,
        },
      ];

      const requestPayload = {
        results: addResultRequestParams,
        input_smiles: smileValue,
      };
      await addResults(requestPayload);
      dispatchForAction(
        getSessionData(copilotData.editorInfo.result.session_id)
      );
    } catch (error: any) {
      setShowAlert(true);
      setAlertMsg(error?.response?.data?.error || "Unable to get Properties.");
      setModificationComplete(true);
    }
  };

  return (
    <>
      <Snackbar
        open={showAlert}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowAlert(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowAlert(false);
          }}
          severity={alertSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>
      <Box ref={scrollToRef} />
      <Box
        textAlign={"right"}
        p={2}
        mb={2}
        gap={4}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            textAlign: "left",
            ml: 2,
          }}
        >
          Session Name : {copilotData.editorInfo?.result?.session_name}
        </Typography>
        {isCopilotEnabled && (
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleToggle}
            aria-label="Platform"
            size="medium"
          >
            <ToggleButton
              value="editor"
              sx={{
                fontWeight: "bold",
              }}
            >
              Editor
            </ToggleButton>
            <ToggleButton
              value="advance"
              sx={{
                fontWeight: "bold",
              }}
            >
              Mol Properties
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </Box>
      <Grid item xs={8} md={5.8} mb={2} minWidth={20}>
        <Box
          minWidth={20}
          position="relative"
          height="100%"
          sx={{
            borderRadius: "4px",
            backgroundColor: "#FFFFFF",
            m: 2.2,
            p: 3,
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            align="center"
            sx={{
              fontWeight: "bold",
              color: "primary",
              mb: 1,
              fontSize: "1.5rem",
            }}
          >
            Molecular Properties
          </Typography>
          <Divider sx={{ width: "80%", margin: "0 auto", mb: 3 }} />
          <Stack direction="column" spacing={2}>
            <Grid container spacing={"auto"}>
              <Grid
                item
                xs={12}
                md={4}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography
                  sx={{
                    color: "var(--shade-2900, #29283B)",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  Residue Name:{" "}
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: "normal",
                      fontSize: "1.2rem",
                      color: "#6B7280",
                    }}
                  >
                    {copilotData.editorInfo?.result?.residue_name || "N/A"}
                  </Typography>
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography
                  sx={{
                    alignItems: "center",
                    color: "var(--shade-2900, #29283B)",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  Protein Chain:{" "}
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: "normal",
                      fontSize: "1.2rem",
                      color: "#6B7280",
                    }}
                  >
                    {copilotData.editorInfo?.result?.protein_chain || "N/A"}
                  </Typography>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* File Name and Download Icon Button */}
                <Typography
                  sx={{
                    alignItems: "center",
                    color: "var(--shade-2900, #29283B)",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  PDB File:{" "}
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: "normal",
                      fontSize: "1.2rem",
                      color: "#6B7280",
                    }}
                  >
                    {copilotData.editorInfo?.result?.pdb_file_name}{" "}
                  </Typography>
                  <IconButton
                    onClick={handlePdbdDownload}
                    sx={{
                      backgroundColor: "#582FF1",
                      color: "#fff",
                      borderRadius : "6px",
                      "&:hover": {
                        backgroundColor: "#3c1db3",
                      },
                    }}
                  >
                    <FileDownloadIcon fontSize="small"/>
                  </IconButton>
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Grid>
      <Grid
        container
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          px: advance ? 2 : 0,
        }}
      >
        <Grid
          item
          xs={12}
          md={advance ? 6 : 12}
          mx={advance ? 0 : 2}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            transition: "all 0.5s ease-in-out",
            overflow: "hidden",
          }}
        >
          <Box
            flex={10}
            sx={{
              minHeight: "700px",
              border: "1px solid #ddd",
              borderRadius: 1,
              display: "flex",
              flexDirection: "column",
              alignContent: "space-between",
              bgcolor: "#fff",
            }}
            position={"relative"}
            pt={0}
            mt={0}
            p={2}
          >
            {renderUi && (
              <Editor
                staticResourcesUrl={""}
                structServiceProvider={structServiceProvider}
                onInit={async (ketcher: any) => {
                  (window as any).ketcher = ketcher;
                  setKetcher(ketcher);
                  ketcher.setMolecule(smileValue);
                  ketcher.editor.subscribe("change", () =>
                    handleStructureChange(ketcher)
                  );
                }}
                errorHandler={(message: string): void => {}}
              />
            )}
          </Box>
          {!isCopilotEnabled && (
            <Box
              sx={{
                mt: 2,
                gap: 4,
                display: "flex",
                // justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <FormControl fullWidth>
                <TextField
                  placeholder="Draw a Molecule"
                  id="smiles-string"
                  name="smiles-string"
                  value={smileValue}
                  onChange={(e) => setSmileValue(e.target.value)}
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    // backgroundColor: "#F9FAFB",
                    // borderRadius: "4px",
                    "& .MuiInputBase-root": {
                      color: "#29283B",
                    },
                  }}
                />
              </FormControl>
              <Button
                variant="contained"
                onClick={getMoleculeProperties}
                disabled={!smileValue}
                sx={{
                  width: "50%",
                  backgroundColor: "#582FF1",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#4A2EE5",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#BDADF9",
                    color: "#FFFFFF",
                  },
                }}
              >
                Get Properties
              </Button>
            </Box>
          )}
        </Grid>

        {/* Right Column */}

        {isCopilotEnabled && advance && (
          <Grid
            item
            xs={12}
            md={advance ? 5.9 : 0}
            sx={{
              transition: "flex-grow 0.5s ease-in-out, height 0.5s ease-in-out",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#fff",
              }}
            >
              <Typography
                align="center"
                sx={{
                  fontWeight: "bold",
                  color: "primary",
                  mb: 1,
                  mt: 3,
                  fontSize: "1.5rem",
                  borderRadius: 1,
                }}
              >
                Modifications History
              </Typography>
              <Divider sx={{ width: "80%", margin: "0 auto", mb: 1 }} />
              <Box
                sx={{
                  overflow: "auto",
                }}
                height={"20rem"}
              >
                {copilotData.modifications?.result?.length > 0 ? (
                  <Box
                    display="flex"
                    alignContent={"center"}
                    justifyContent={"center"}
                    sx={{
                      width: "max-content",
                    }}
                  >
                    {copilotData.modifications.result.map(
                      (item: any, index: number) => (
                        <Paper
                          elevation={2}
                          key={index}
                          sx={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            p: 2,
                            m: 2,
                            cursor: "pointer",
                            backgroundColor: "#fff",
                            borderRadius: 1,
                            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.18)",
                            transition: "transform 0.3s ease",
                            "&:hover": {
                              transform: "scale(1.05)",
                            },
                          }}
                          onClick={() => handleSliderClick(item.results)}
                        >
                          <Box>
                            <Typography
                              variant="subtitle1"
                              sx={{ color: "#555", fontWeight: 600 }}
                            >
                              id : {item.modification_name}
                            </Typography>
                            <Avatar
                              src={`data:image/svg+xml;base64,${btoa(
                                item.svg
                              )}`}
                              sx={{ width: 150, height: 150, mx: "auto" }}
                            />
                            <Typography
                              variant="subtitle1"
                              sx={{ color: "#555", fontWeight: 600 }}
                            >
                              {item.input_smiles}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {item.input_modification}
                            </Typography>
                          </Box>
                        </Paper>
                      )
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      color="red"
                      fontStyle="italic"
                      textAlign={"center"}
                    >
                      No Modification Data
                    </Typography>
                  </Box>
                )}

                {/* <IconButton
                sx={{
                  position: "absolute",
                  left: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  backgroundColor: "#fff",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    backgroundColor: "#f1f1f1",
                  },
                  transition: "background-color 0.3s ease",
                }}
                onClick={() => scroll("left")}
              >
                <ChevronLeftIcon />
              </IconButton>

              <IconButton
                sx={{
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                  backgroundColor: "#fff",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                  transition: "background-color 0.3s ease",
                }}
                onClick={() => scroll("right")}
              >
                <ChevronRightIcon />
              </IconButton> */}
              </Box>
              <Box
                sx={{
                  borderTop: "1px solid #ddd",
                  borderRadius: 1,
                  backgroundColor: "#f8f9fa",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
                  p: 2,
                }}
              >
                <TextField
                  multiline
                  rows={2}
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 3,
                    },
                  }}
                  value={modificationText}
                  onChange={(e) => setModificationText(e.target.value)}
                  placeholder="Enter Desired Modification"
                />
                <Button
                  sx={{
                    mt: 2,
                    backgroundColor: "#582FF1",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: "#4A2EE5",
                    },
                    "&.Mui-disabled": {
                      backgroundColor: "#BDADF9",
                      color: "#FFFFFF",
                    },
                  }}
                  fullWidth
                  disabled={
                    !smileValue || !modificationText || !modificationComplete
                  }
                  variant="contained"
                  onClick={handleGetModifications}
                >
                  {" "}
                  Get Modifications
                </Button>
                <FormControl fullWidth sx={{ mt: 4 }}>
                  <TextField
                    placeholder="Draw a Molecule"
                    id="smiles-string"
                    name="smiles-string"
                    value={smileValue}
                    onChange={(e) => setSmileValue(e.target.value)}
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      backgroundColor: "#F9FAFB",
                      borderRadius: "4px",
                      "& .MuiInputBase-root": {
                        color: "#29283B",
                      },
                    }}
                  />
                </FormControl>
                <Button
                  variant="contained"
                  onClick={getMoleculeProperties}
                  disabled={!smileValue}
                  fullWidth
                  sx={{
                    mt: 2,
                    backgroundColor: "#582FF1",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: "#4A2EE5",
                    },
                    "&.Mui-disabled": {
                      backgroundColor: "#BDADF9",
                      color: "#FFFFFF",
                    },
                  }}
                >
                  Get Properties
                </Button>
              </Box>
            </Box>
          </Grid>
        )}

        <ModificationDialog
          open={dialogOpen}
          handleClose={() => setDialogOpen(false)}
          results={modificationResult}
          updateSmiles={(smiles: string) => {
            ketcher.setMolecule(smiles);
          }}
        />
        <IndefiniteLoader
          state={!modificationComplete || copilotData.loading == "pending"}
        />
      </Grid>
      <Box m={2.2}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          bgcolor="#f9f9f9"
          borderRadius={1}
          boxShadow={3}
          p={3}
          width="100%"
        >
          {/* Header Section */}
          <Typography variant="h4" fontWeight="bold" mb={2}>
            Results
          </Typography>

          {/* Content Section */}
          <Box
            width="100%"
            border="1px solid #e0e0e0"
            borderRadius={2}
            overflow="hidden"
            p={3}
            bgcolor="white"
          >
            {molEditorResult.length === 0 ? (
              <Typography color="red" fontStyle="italic" textAlign={"center"}>
                No Results Available
              </Typography>
            ) : (
              <MolEditorResultV3
                setModificationComplete={setModificationComplete}
                molEditorResult={molEditorResult}
                setMolEditorResult={setMolEditorResult}
                editorData={{
                  residueName: copilotData.editorInfo?.result?.residue_name,
                  protChain: copilotData.editorInfo?.result?.protein_chain,
                  PDBFile: PDBFile,
                  smileValue: smileValue,
                }}
                updateSmiles={(smiles: string) => {
                  ketcher.setMolecule(smiles);
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default PropertyEditor;
