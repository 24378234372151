import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Snackbar,
  Chip,
  Autocomplete,
  Box,
  Button,
  Typography,
  TextField,
  Modal,
  Stack,
  MenuItem,
  IconButton,
  Select,
  FormControl,
  LinearProgress,
  Alert,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridFilterOperator,
  GridFilterItem,
  GridCellParams,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import http from "../../net/http-common";
import CloseIcon from "@mui/icons-material/Close";
import FileUploader from "../../components/common/FileUploader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { SERVICE_URL, SERVER_URL } from "../../../src/config";
import { useUserAuth } from "../../context";
import { useDispatch } from "react-redux";
import { getSessionData } from "./copilotSlice";
import CoPilotPopUpAlert from "../../components/MoleculeCopilot/CoPilotPop";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import AddIcon from '@mui/icons-material/Add';
import { formatTimestamp } from "../../components/common/JobsTable";

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
};

function CoPilotSessions() {
  const navigate = useNavigate();
  const [sessions, setSessions] = useState([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [PDBFile, setPDBFile] = useState<File>(null);
  const { user } = useUserAuth();
  const [fetchingFields, setfetchingFields] = useState(false);
  const [chains, setChains] = useState<string[]>([]);
  const [residues, setResidues] = useState<string[]>([]);
  const [residue, setResidue] = useState("");
  const [protChain, setProtChain] = useState("");
  const [sessionName, setSessionName] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [delSessionId, setDelSessionId] = useState("");
  const [isCopilotEnabled, setIsCopilotEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");

  const dispatchForAction = useDispatch<any>();

  const handleSessionDeleted = () => {
    fetchSessions();
  };

  const handleActionFailed = () => {
    setShowError(true);
    setErrorMsg("Unable to delete sessions.");
  };
  const renderActions = (params: GridRenderCellParams<any, number>) => {
    return (
      <>
        <IconButton
          aria-label="edit"
          onClick={(event) => {
            event.stopPropagation();
            dispatchForAction(getSessionData(params.id as string));
            navigate(`editor/${params.id as string}`);
          }}
        >
          <EditIcon style={{ color: "secondary" }} />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={(event) => {
            event.stopPropagation();
            handleDelete(params.id as string);
          }}
        >
          <DeleteIcon style={{ color: "red" }} />
        </IconButton>
      </>
    );
  };
  const customArrayFilterOperator: GridFilterOperator = {
    label: "contains",
    value: "contains",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (
        !filterItem.value ||
        !Array.isArray(filterItem.value) ||
        filterItem.value.length === 0
      ) {
        return null;
      }

      return ({ value }) => {
        if (!value || !Array.isArray(value)) {
          return false;
        }
        // Check if all the selected values from Autocomplete are in the cell's array
        return filterItem.value.every((selected: any) =>
          value.includes(selected)
        );
      };
    },
    InputComponent: ({ item, applyValue }) => {
      const [selectedValues, setSelectedValues] = useState(item.value || []);
      const handleFilterChange = (event: any, newValue: any) => {
        setSelectedValues(newValue); // Update the selected options
        applyValue({ ...item, value: newValue });
      };

      return (
        <Box width={400}>
          <Autocomplete
            sx={{ mt: 2 }}
            multiple
            options={[]} // Custom string array for options
            value={selectedValues}
            freeSolo
            onChange={handleFilterChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Select Tags"
              />
            )}
          />
        </Box>
      );
    },
  };

  const handleTags = (params: GridRenderCellParams<any, string[]>) => {
    return (
      <Box display={"flex"} flexWrap={"wrap"}>
        {params.value.map((pa, idx) => (
          <Chip
            key={idx}
            label={pa}
            sx={{
              m: 0.5,
              // background:  "#dcffdc",
              fontWeight: "bold",
            }}
          />
        ))}
      </Box>
    );
  };

  const tableCols: GridColDef[] = [
    {
      field: "session_id",
      headerName: "Session Id",
      align: "center",
      headerAlign: "center",
      width: 400,
    },
    {
      field: "session_name",
      headerName: "Session Name",
      align: "center",
      headerAlign: "center",
      width: 250,
    },
    {
      field: "is_copilot_enabled",
      headerName: "Copilot Enabled",
      align: "center",
      headerAlign: "center",
      width: 150,
      type : "boolean",
    },
    {
      field: "created_at",
      headerName: "Created At",
      align: "center",
      headerAlign: "center",
      type: "dateTime",
      width: 200,
      valueGetter: ({ value }) => value && new Date(value),
      renderCell: (params: GridCellParams<any, string>) => {
        const formattedDate = formatTimestamp(params.value);
        return <span>{formattedDate}</span>;
      },
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      align: "center",
      headerAlign: "center",
      width: 200,
      type: "dateTime",
      valueGetter: ({ value }) => value && new Date(value),
      renderCell: (params: GridCellParams<any, string>) => {
        const formattedDate = formatTimestamp(params.value);
        return <span>{formattedDate}</span>;
      },
    },
    {
      field: "tags",
      headerName: "Tags",
      editable: false,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: handleTags,
      filterOperators: [customArrayFilterOperator],
      width: 400,
    },
    {
      field: "action",
      headerName: "Actions",
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: renderActions,
    },
  ];

  const fetchSessions = async () => {
    try {
      // setLoading(true);
      const response = await http.get(
        `${SERVICE_URL}/copilot_service/copilot/get-sessions?uid=${user.uid}`
      );
      setSessions(response.data.result.sessions);
    } catch (error) {
      // console.error("Failed to fetch sessions", error);
      setShowError(true);
      setErrorMsg("Unable to get sessions.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchSessions();
  }, []);

  const handleSubmit = async () => {
    try {
      const data = new FormData();
      data.append("uid", user.uid);
      data.append("session_name", sessionName);
      data.append("pdb_file", PDBFile);
      data.append("uploaded_paper", "");
      data.append("residue_name", residue);
      data.append("protein_chain", protChain);
      data.append("current_smiles", "");
      data.append("is_copilot_enabled", isCopilotEnabled.toString());
      tags.forEach((tag) => {
        data.append("tags", tag);
      });
      const result = await http.post(
        `${SERVICE_URL}/copilot_service/copilot/create-session/`,
        data,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "http://localhost:3000",
          },
        }
      );
      dispatchForAction(getSessionData(result.data.result.session_id));
      navigate(`editor/${result.data.result.session_id}`);
    } catch (error) {
      setShowError(true);
      setErrorMsg("Unable to create new session.");
    }
  };

  const handleFileUpload = async (file: File, name: string) => {
    switch (name) {
      case "pdb":
        setPDBFile(file);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!PDBFile) return;

    const form = new FormData();
    form.append("uploaded_protein_file", PDBFile);

    setfetchingFields(true);

    http
      .post(`${SERVER_URL}/pdb/chains`, form, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      })
      .then((response: any) => {
        setfetchingFields(false);
        //  console.log(response);
        setChains(response.data[0]);
        setResidues(response.data[1]);
        setProtChain(response.data[0][0]);
        setResidue(response.data[1][0]);
      })
      .catch((error) => {
        setfetchingFields(false);
        setShowError(true);
        setErrorMsg("Error while uploading PDB File.");
      });
  }, [PDBFile]);

  const handleDownloadSample = (fileType: string) => {
    const link = document.createElement("a");

    if (fileType === "protein") {
      link.download = "CoPilot_Sample.pdb";
      link.href = "/samples/autodock/2p16.pdb";
    }

    link.click();
  };

  const handleDelete = async (sessionId: string) => {
    setOpenDeleteDialog(true);
    setDelSessionId(sessionId);
  };

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Box p={4}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h5">All Sessions</Typography>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenModal(true)}
              startIcon={<AddIcon />}
            >
              Create Session
            </Button>
          </Box>
        </Grid>

        <Box height="600px" sx={{ backgroundColor: "#FFFFFF", p: 3 }}>
          <DataGridPro
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal",
                fontWeight: "600",
                fontSize: "1rem",
                color: "#4A4A4A",
              },
              "& .MuiDataGrid-columnHeader": {
                height: "unset !important",
              },
              "& .MuiDataGrid-columnHeaders": {
                maxHeight: "175px !important",
                textAlign: "center",
                fontWeight: "700 !important",
                // backgroundColor: "#EFEFF5",
              },
              "& .MuiDataGrid-cell": {
                fontSize: "0.9rem",
                color: "#4A4A4A",
                padding: "8px 16px",
              },
            }}
            slots={{
              toolbar: (props) => <CustomToolbar {...props} />,
            }}
            slotProps={{
              filterPanel: {
                sx: {
                  width: 800, // Adjust the filter panel width as needed
                },
              },
            }}
            rows={sessions}
            columns={tableCols}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            // disableRowSelectionOnClick
            onRowClick={(params : GridRowParams) => {
              dispatchForAction(getSessionData(params.id as string));
              navigate(`editor/${params.id as string}`);
            }}
            getRowId={(row: any) => row.session_id}
            pagination
            pageSizeOptions={[10, 15, 50]}
            getRowHeight={() => "auto"}
          />
        </Box>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          sx={{ backdropFilter: "blur(4px)", bgcolor: "rgba(0, 0, 0, 0.5)" }}
        >
          <Box sx={modalStyle}>
            <Box
              display="flex"
              justifyContent="flex-end"
              mb={0}
              onClick={() => setOpenModal(false)}
            >
              <IconButton>
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
            <Typography variant="h5" mt={0} textAlign={"center"}>
              Create Editor Session
            </Typography>

            <Typography
              variant="body2"
              sx={{
                mt: 1,
                color: "var(--shade-2900, #29283B)",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
              mb={1}
            >
              Session Name
            </Typography>

            <TextField
              fullWidth
              placeholder="Enter name"
              variant="outlined"
              value={sessionName}
              onChange={(e: any) => {
                setSessionName(e.target.value);
              }}
            />

            <Autocomplete
              multiple
              value={tags}
              onChange={(event, newValue) => {
                setTags(newValue);
              }}
              sx={{ mt: 2 }}
              fullWidth
              options={[]}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Enter tags" />
              )}
            />

            {/* <Box display="flex" alignItems="center" justifyContent="space-between" mb={0}>
            <Typography variant="body2">
              Upload Research Papers
            </Typography>
            <Button variant="text" onClick={() => handleDownloadSample("protein")}>
              Download Sample
            </Button>
          </Box> */}

            {/* <Box display="flex" mt={-1}>
            <FileUploader
              accept={".pbd"}
              handleFileUpload={(files) =>
                handleFileUpload(files[0], "pdb")
              }
              headerSelector={true}
            />
          </Box> */}

            <Stack direction="column" spacing={1.5}>
              <Box display={"flex"} justifyContent={"space-between"}>
                <Typography
                  sx={{
                    mt: 1,
                    color: "var(--shade-2900, #29283B)",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Protein File (.pdb)
                </Typography>
                <Button
                  variant="text"
                  onClick={() => handleDownloadSample("protein")}
                >
                  {"Download Sample"}
                </Button>
              </Box>
              <FileUploader
                accept={".pdb"}
                handleFileUpload={(files) => handleFileUpload(files[0], "pdb")}
                deleteHandlerDisable={() => {
                  return false;
                }}
              />
            </Stack>
            <Stack direction="column" spacing={1.5}>
              <FormControl>
                <Typography
                  sx={{
                    mt: 1,
                    color: "var(--shade-2900, #29283B)",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Residue Name
                </Typography>
                {fetchingFields ? <LinearProgress /> : ""}
                <Select
                  disabled={residues?.length === 0}
                  value={residue}
                  label="Residue"
                  onChange={(e) => setResidue(e.target.value)}
                >
                  {residues?.map((v, i) => {
                    return (
                      <MenuItem key={i} value={v}>
                        {v}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
            <Stack direction="column" spacing={1.5}>
              <FormControl>
                <Typography
                  sx={{
                    mt: 1,
                    color: "var(--shade-2900, #29283B)",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Protein Chain
                </Typography>
                {fetchingFields ? <LinearProgress /> : ""}
                <Select
                  disabled={chains?.length === 0}
                  value={protChain}
                  label="Protein Chain"
                  onChange={(e) => setProtChain(e.target.value)}
                >
                  {chains?.map((v, i) => {
                    return (
                      <MenuItem key={i} value={v}>
                        {v}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
            <FormControlLabel
              sx={{ mt: 2 }}
              control={
                <Checkbox
                  checked={isCopilotEnabled}
                  onChange={(e) => setIsCopilotEnabled(e.target.checked)}
                  color="primary"
                />
              }
              label="Enable Copilot Features"
            />
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              fullWidth
              onClick={handleSubmit}
              disabled={!sessionName || !PDBFile}
            >
              Create
            </Button>
          </Box>
        </Modal>
        <CoPilotPopUpAlert
          textPrimary="Do you want to delete this session ? "
          textAlertDespriction="Once deleted the data will be lost forever."
          textAction="Delete"
          textSecondary={`Session Id : ${delSessionId}`}
          openDeleteDialog={openDeleteDialog}
          setOpenDeleteDialog={setOpenDeleteDialog}
          actionId={1}
          actionParams={{
            sessionId: delSessionId,
          }}
          onActionCompleted={handleSessionDeleted}
          onActionFailed={handleActionFailed}
        />
        <IndefiniteLoader state={loading} />
      </Box>
    </>
  );
}

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "1px solid #ccc",
  borderRadius: 2,
  p: 3,
  boxShadow: 24,
  maxHeight: "90vh",
  overflowY: "auto",
};

export default CoPilotSessions;
