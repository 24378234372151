interface AdmetCardsTypes {
    label: string;
    link: string;
    description: string;
    dashIcon: string;
    navlabel: string;
  }
  
  export const admetCards: AdmetCardsTypes[] = [
    {
      label: "ADMET Pro",
      navlabel: "ADMET Pro",
      link: "admet-pro",
      description:
        "End-to-End Lead Optimzation to generate molecules with enhanced properties.",
      dashIcon: "images/new-dashcards-images/admetpro_dashcard_moleculegen.png",
    },
  ];
  