import { useState, useEffect, useRef } from "react";
import {
  DataGridPro,
  GridColDef,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

import {
  Box,
  Card,
  Grid,
  Chip,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

// import { GridFilterPanelProps } from "@mui/x-data-grid/components/panel/filterPanel/GridFilterPanel";
// import theme from "../../theme/theme";
// import { PropertiesInput } from "../../components";
import {
  GridRenderCellParams,
  GridDensity,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { useNavigate } from "react-router-dom";
import InputModal from "../../components/LiftFilter/InputModal";

export type tableStateType = {
  density: GridDensity;
  pageSize: number;
};

// custom toolbar with custom default filters
const CustomToolbar = (props: any) => {
  const { setOpenSearchInput } = props;
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        printOptions={{
          disableToolbarButton: true,
        }}
      />
      <Box>
        {/* <Button onClick={defaultFilterHandler}>Default Filters</Button> */}
        <Button
          id="basic-button"
          aria-haspopup="true"
          onClick={() => setOpenSearchInput(true)}
          startIcon={<SearchIcon />}
        >
          Filter Interactions
        </Button>
      </Box>
    </GridToolbarContainer>
  );
};

function LiftFilterVisualizer() {
  const [rows, setRows] = useState<any[]>([]);
  const [allColumns, setAllColumns] = useState<GridColDef[]>([]);
  const [filteredInteractions, setFilteredInteractions] = useState<any>(null);
  const [coloredFilterInteractions, setcoloredFilterInteractions] = useState<string[]>([]);

  // console.log('colored inte',coloredFilterInteractions);
  

  const [openSearchInput, setOpenSearchInput] = useState<boolean>(false);

  let table = useGridApiRef();

  const liftFilterData = useSelector((state: any) => state.liftFilters);

  const navigate = useNavigate();
  const scrollToRef = useRef(null);

  /*
  const handleTableStateChange = (newState: any) => {
    setFilteredInteractions(newState.filter.filterModel);
  };
  */

  useEffect(() => {
    // console.log('colorfilter updated state:', coloredFilterInteractions);
    setAllColumns(columns);
  }, [coloredFilterInteractions]); // This will run whenever coloredFilterInteractions changes
  

  // showing interactions as a chips
  const handleInteractionsData = (
    params: GridRenderCellParams<any, string[]>
  ) => {
    // let allInteractions: string[] = [];

    // if (filteredInteractions.items.length > 0) {
    //   for (let i = 0; i < filteredInteractions.items.length; i++) {
    //     let interactionObj = filteredInteractions.items[i];

    //     if (interactionObj.value) {
    //       allInteractions = [...allInteractions, ...interactionObj.value];
    //     }
    //   }
    // }

    return (
      <Box display={"flex"} flexWrap={"wrap"}>
        {params.value.map((pa) => (
          <Chip
            label={pa}
            sx={{
              m: 0.5,
              color: coloredFilterInteractions.includes(pa) ? "green" : "",
              border: coloredFilterInteractions.includes(pa) ? "1px solid green" : "",
              background: coloredFilterInteractions.includes(pa) ? "#dcffdc" : "",
              fontWeight: coloredFilterInteractions.includes(pa) ? "bold" : "",
            }}
          />
        ))}
      </Box>
    );
  };


  /*
  // Custom filter operator for array data using Autocomplete
  const customArrayFilterOperator: GridFilterOperator = {
    label: "contains",
    value: "contains",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      // If no value or if the value is not an array, no filter is applied
      if (
        !filterItem.value ||
        !Array.isArray(filterItem.value) ||
        filterItem.value.length === 0
      ) {
        return null; // Don't apply filter if no value selected
      }

      return ({ value }) => {
        // 'value' refers to the array in the current cell data (concatInteractions array)
        if (!value || !Array.isArray(value)) {
          return false;
        }

        // Check if all the selected values from Autocomplete are in the cell's array
        return filterItem.value.every((selected: any) =>
          value.includes(selected)
        );
      };
    },
    InputComponent: ({ item, applyValue }) => {
      // console.log('applyvalue',applyValue);

      const [selectedValues, setSelectedValues] = useState(item.value || []);
      const handleFilterChange = (event: any, newValue: any) => {
        setSelectedValues(newValue); // Update the selected options
        applyValue({ ...item, value: newValue });
      };

      return (
        <Box width={700}>
          <Autocomplete
            sx={{ mt: 2 }}
            multiple
            options={liftFilterData.allInteractions} // Custom string array for options
            value={selectedValues}
            freeSolo
            onChange={handleFilterChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Select Interactions"
              />
            )}
          />
        </Box>
      );
    },
  };

  */

  //columns for data grid table
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "smiles",
      headerName: "SMILES",
      editable: false,
      headerAlign: "center",
      align: "center",
      width: 200,
    },

    {
      field: "name",
      headerName: "Name",
      editable: false,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "img",
      headerName: "2D Representation",
      description: "This column has images and is not sortable.",
      sortable: false,
      filterable: false,
      disableExport: true,
      width: 120,

      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{
            height: "200px",
            width: "80%",
            transition: "transform 0.3s ease-in-out",
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = "scale(150%)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = "scale(100%)";
          }}
        />
      ),
    },
    {
      field: "concatInteractions",
      headerName: "INTERACTIONS",
      editable: false,
      headerAlign: "center",
      renderCell: handleInteractionsData,
      width: 1150,
      filterable:false
      // filterOperators: [customArrayFilterOperator], // Apply custom filter
    },
  ];

  const handleScroll = () => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    setAllColumns(columns);

    if (liftFilterData.data) {
      setRows(liftFilterData.data);
      // Add a slight delay to ensure the DOM is fully updated
      handleScroll();
    } else if (
      liftFilterData.loading === false &&
      liftFilterData.data === null
    ) {
      navigate("/virtual-screening/lift/filter-molecules/");
    }
  }, [liftFilterData]);

  /*
  useEffect(() => {
    setAllColumns(columns);
  }, [filteredInteractions]);
  */

  return (
    <>
      <Box ref={scrollToRef}></Box>

      <Card sx={{ mx: 2, mt: 1, mb: 2 }}>
        <Grid container spacing={2} px={2}>
          <Grid item width={"100%"}>
            <Typography sx={{ mx: 2, mt: 3 }} variant="h5">
              INTERACTIONS
            </Typography>
            {
              coloredFilterInteractions.length>0 && (
                <Box sx={{
                  mx:2,
                  color:"#582FF1",
                  fontWeight:"bold"
                }}>
              <span>Searched Interactions:</span> {coloredFilterInteractions.map((item:string,index:number)=>(
                <Chip
                sx={{ m: 1 }}
                key={index}
                label={item}
              />
              ))}
            </Box>
              )
            }
            <Card sx={{ m: 2, mt: 1 }}>
              <Box sx={{ width: "100%", height: "700px" }}>
                <DataGridPro
                  apiRef={table}
                  sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                      fontWeight: "550", // Force font weight to bold
                      fontSize: "0.9rem",
                    },
                    "& .MuiDataGrid-columnHeader": {
                      // Forced to use important since overriding inline styles
                      height: "unset !important",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      // Forced to use important since overriding inline styles
                      maxHeight: "175px !important",
                      textAlign: "center",
                    },
                  }}
                  rows={rows}
                  columns={allColumns}
                  // onRowClick={handleRowClick}
                  // onStateChange={handleTableStateChange}

                  // onStateChange={(newState) => handleTableStateChange(newState)}
                  pagination
                  density={"comfortable"}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  autoHeight={false}
                  getRowHeight={(params) => {
                    // Dynamically adjust row height based on a specific field

                    if (params.model.concatInteractions) {
                      let concatedData = params.model.concatInteractions;

                      if (concatedData.length > 8) {
                        return (100 / 8) * concatedData.length;
                      }
                      return 100; // Default row height
                    }
                  }}
                  pageSizeOptions={[5, 20, 30]}
                  slots={{
                    toolbar: (props) => (
                      <CustomToolbar
                        {...props}
                        setOpenSearchInput={setOpenSearchInput}
                      />
                    ),
                  }}
                  rowSelection={false}
                  // slotProps={{
                  //   filterPanel: {
                  //     sx: {
                  //       width: 1100, // Adjust the filter panel width as needed
                  //     },
                  //   },
                  // }}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>

        <Box px={4} mt={2} mb={2}>
          <Typography mb={1} variant="h5">
            CONSERVED SIGNATURE
          </Typography>
          {liftFilterData.conserved_signatures && (
            <TableContainer
              component={Paper}
              sx={{
                height: 280,
              }}
            >
              <Table sx={{ minWidth: 500 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr.No.</TableCell>

                    <TableCell>INTERACTIONS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {liftFilterData.conserved_signatures.map(
                    (row: string, index: number) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Card>

      <InputModal
        openSearchInput={openSearchInput}
        setOpenSearchInput={setOpenSearchInput}
        allListedInteractions={liftFilterData.allInteractions}
        filteredRows={liftFilterData.data}
        setRows={setRows}
        setcoloredFilterInteractions={setcoloredFilterInteractions}
      />
      <IndefiniteLoader state={liftFilterData.loading} />
    </>
  );
}

export default LiftFilterVisualizer;
