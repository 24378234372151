interface LeaderCardsTypes {
  label: string;
  link: string;
  description: string;
  dashIcon: string;
  navlabel: string;
}

export const leaderCards: LeaderCardsTypes[] = [
  {
    label: "Leader",
    navlabel: "Leader",
    link: "leader",
    description:
      "End-to-End Lead Optimzation to generate molecules with enhanced properties.",
    dashIcon: "images/new-dashcards-images/leader_dashcard_moleculegen.png",
  },
  {
    label: "BioSwap",
    link: "bioisosteric",
    description: "AI-powered app for Bioisosteric replacements",
    dashIcon: "images/new-dashcards-images/bioswap_dashcard_moleculegen.png",
    navlabel: "BioSwap",
  },
  // {
  //   label: "Molecule CoPilot",
  //   link: "copilot",
  //   description:
  //     "Optimize molecules with interactive natural language feedback",
  //   dashIcon: "images/new-dashcards-images/copilot_dashcard_moleculegen.png",
  //   navlabel: "Molecule CoPilot",
  // },
  {
    label: "SubstituteR",
    link: "r-groups",
    description: "R-group replacement for drug generation and optimization",
    dashIcon:
      "images/new-dashcards-images/substituter_dashcard_moleculegen.png",
    navlabel: "SubstituteR",
  },
];
