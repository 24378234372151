import {
  Alert,
  AlertColor,
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Grid,
  LinearProgress,
  Snackbar,
  Stack,
  Typography,
  Tooltip,
  Zoom,
} from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import CompareIcon from "@mui/icons-material/Compare";
import GetAppIcon from "@mui/icons-material/GetApp";

import {
  GridColDef,
  GridFilterItem,
  GridFilterOperator,
  GridRowParams,
  getGridNumericOperators,
} from "@mui/x-data-grid";
import InputNumberInterval from "../Properties/InputNumberInterval";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { CustomToolbar } from "../../views/Properties/PropertiesVisualizeV3";
import Viewer from "../../components/common/DockViewer";
import { Color } from "molstar/lib/mol-util/color";
import http from "../../net/http-common";

import ViewMolecule from "../../views/Chemlets/CompareMolecules";
import { LIFT_MOLBENCH, LIFT_SERVER_URL} from "../../config";
import { updateResult } from "../../views/Copilot/copilotApi";
import { useDispatch } from "react-redux";
import { getSessionData } from "../../views/Copilot/copilotSlice";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { removeResult } from "../../views/Copilot/copilotApi";
import CompareMoleculesCopilot from "../MoleculeCopilot/CompareMolecules";


export interface Item {
  title: string;
  value: any;
  color: string;
  info: string;
}

export interface Category {
  heading: string;
  data: Item[];
}

function MolEditorResultV3({
  setModificationComplete,
  molEditorResult,
  setMolEditorResult,
  editorData,
  updateSmiles,
}: {
  setModificationComplete: (value: boolean) => void;
  molEditorResult: any[];
  setMolEditorResult: React.Dispatch<React.SetStateAction<any[]>>;
  editorData: any;
  updateSmiles: (smiles : string) => void;
}) {
  let table = useGridApiRef();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openComapreDialog, setOpenComapreDialog] = useState<boolean>(false);
  const [openViewDialog, setOpenViewDialog] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [alertMsg, setAlertMsg] = useState<string>("");
  const [compareLoader, setCompareLoader] = useState<boolean>(false);
  const [sdfFile, setSDFFile] = useState<any>(null);
  const [pdbFile, setpdbFile] = useState<any>(null);
  const [allSelectedRows, setAllSelectedRows] = useState<number[]>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<any>([]);
  const [dataToCompare, setDataToCompare] = useState<any>([]);
  const [isExportBtn, setIsExportBtn] = useState<boolean>(false);
  const dispatchForAction = useDispatch<any>();

  const viewerRef = useRef<any>(null); // Reference to the viewer instance

  const handleRowClick = (
    params: GridRowParams, // GridRowParams
    event: any
  ) => {
    if (!params) return;
    setSDFFile(params.row.SDF);
    setpdbFile(editorData.PDBFile);
    if (event.target.tagName.toLowerCase() === "button") {
      return;
    }
    if (!params.row.docking_score) {
      setOpenAlert(true);
      setAlertSeverity("error");
      setAlertMsg("Please get docking first to view visualizations.");
      setModificationComplete(true);
      return;
    }
    setOpenDialog(true);
  };

  useEffect(() => {
    if (openDialog && pdbFile && sdfFile) {
      const pdbData = new Blob([pdbFile], { type: "application/octet-stream" });
      const sdfData = new Blob([sdfFile], { type: "application/octet-stream" });

      const pdbUrl = URL.createObjectURL(pdbData);
      // const pdbUrl = "/samples/autodock/ace2.pdbqt";

      const sdfUrl = URL.createObjectURL(sdfData);

      const initViewer = () => {
        // if (!viewerRef.current) {

        Viewer.create("molviewer", [Color(0x33dd22), Color(0x1133ee)], true)
          .then((viewer) => {
            viewerRef.current = viewer; // Save the viewer instance
            viewer.loadStructuresFromUrlsAndMerge([
              { url: pdbUrl, format: "pdb" },
              { url: sdfUrl, format: "sdf" },
            ]);
          })
          .catch((error) => {
            console.error("Error creating viewer:", error);
          });
        // } else {
        //   console.log("else");

        //   viewerRef?.current?.loadStructuresFromUrlsAndMerge([
        //     { url: pdbUrl, format: "pdb" },
        //     { url: sdfUrl, format: "sdf" }
        //   ]);
        // }
      };

      setTimeout(initViewer, 1000); // Slight delay to ensure the dialog is rendered

      return () => {
        URL.revokeObjectURL(pdbUrl);
        URL.revokeObjectURL(sdfUrl);
      };
    }
  }, [openDialog, pdbFile, sdfFile]);

  // between operator config
  const BetweenOperator: GridFilterOperator[] = [
    {
      label: "Between",
      value: "between",
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
          return null;
        }
        if (filterItem.value[0] == null || filterItem.value[1] == null) {
          return null;
        }

        return ({ value }) => {
          return (
            value !== null &&
            filterItem.value[0] <= value &&
            value <= filterItem.value[1]
          );
        };
      },
      InputComponent: InputNumberInterval,
    },
    ...getGridNumericOperators(),
  ];


  const deleteRow = async (params: any) => {
    setModificationComplete(false);
    await removeResult({
      sessionId: params.row.session_id,
      resultId: params.id,
    });
    dispatchForAction(getSessionData(params.row.session_id));
  }


  const getDockingScore = async (params: any) => {

    try {
      setModificationComplete(false);
      const form = new FormData();
      const pdbBlob = new Blob([editorData.PDBFile], { type: "text/plain" });
      form.append("pdb_file", pdbBlob, "file.pdb");
      form.append("residue_name", editorData.residueName);
      form.append("protein_chain", editorData.protChain);
      form.append("ligand_smiles", params.smiles);

      const requestUrl = `https://app.moleculeai.com/unidock_api/get_docking_scores`;
      const response = await http.post(requestUrl, form, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      });


      if (!response?.data?.docking_score) {
        throw Error("Could not get docking score.")
      }

      await updateResult({
        MolLogP: params.MolLogP,
        MolWt: params.MolWt,
        QED: params.QED,
        SA: params.SA,
        SDF: response.data.SDF,
        docking_score: response.data.docking_score,
        modification_desc: params.modification_desc,
        result_id: params.result_id
      });

      dispatchForAction(getSessionData(params.session_id));
      setModificationComplete(true);
    }


    catch (error: any) {
      setOpenAlert(true);
      setAlertSeverity("error");
      setAlertMsg(error.message);
      setModificationComplete(true);
    }

  }
  // physico chemical properties columns
  const columns: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "Sr.No.",
    //   editable: false,
    //   width: 50,
    // },
    {
      field: "smiles",
      headerName: "SMILES",
      editable: false,
      headerAlign: "center",
      width: 350,
    },
    {
      field: "svg",
      headerName: "2D representation",
      editable: false,
      headerAlign: "center",
      description: "This column has images and is not sortable.",
      renderCell: (params) => (
        <Tooltip
          title={
            <img
              src={`data:image/svg+xml;base64,${btoa(params.value)}`}
              alt="2D svg representation enlarged"
              style={{
                width: "18em",
                height : "auto"
              }}
            />
          }
          arrow
          placement="top"
          TransitionComponent={Zoom} 
          TransitionProps={{
            timeout: 300,
          }}
        >
          <img
            src={`data:image/svg+xml;base64,${btoa(params.value)}`}
            alt="2D svg representation"
            style={{
              height : "80px",
              width: "80%",
              cursor: "pointer",
            }}
          />
        </Tooltip>
      ),
      sortable: false,
      filterable: false,
      disableExport: true,
    },
    {
      field: "Editor",
      headerName: "Editor",
      width: 140,
      headerAlign : "center",
      editable: false,
      filterable: false,
      disableExport: true,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Button
          onClick={(event) => {
            event.stopPropagation();
            updateSmiles(params.row.smiles);
          }}>
            Load to Editor
          </Button>
        );
      },
    },
    {
      field: "docking_score",
      headerName: "Docking Score",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 140,
      filterOperators: BetweenOperator,
      renderCell: (params) => {
        return params.value === null ? (
          <Button  color="primary" size="small" onClick={() => getDockingScore(params.row)}>
            Get score
          </Button>
        ) : (
          <Typography sx={{ fontSize: "0.9rem" }}>{params.value}</Typography>
        );
      },
    },
    {
      field: "modification_name",
      headerName: "Modification Id",
      type: "string",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "QED",
      headerName: "QED",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: BetweenOperator,
    },
    {
      field: "SA",
      headerName: "SA",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: BetweenOperator,
    },
    {
      field: "MolWt",
      headerName: "MolWt",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: BetweenOperator,
    },

    {
      field: "MolLogP",
      headerName: "MolLogP",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: BetweenOperator,
    },
    {
      field: "delete",
      headerName: "Remove",
      // width: 200,
      editable: false,
      disableExport: true,
      sortable: false,
      filterable : false,
      renderCell: (params: any) => {
        return (
          <Box>
            <IconButton
              aria-label="delete"
              onClick={(event) => {
                event.stopPropagation();
                deleteRow(params);
              }}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </Box>
        );
      },
    }
  ];

  // rows selection for download
  const handleSelectionChange = (selectedRows: any) => {
    setAllSelectedRows(selectedRows);
    const selectedRowsTempData = molEditorResult.filter((row: any) =>
      selectedRows.includes(row.result_id)
    );
    if (!isExportBtn && selectedRowsTempData.length === 1) {
      setIsExportBtn(true);
    }

    setSelectedRowsData(selectedRowsTempData);
  };

  // console.log("moleedit result", molEditorResult);

  const singleRowInteractions = async () => {
    setCompareLoader(true);
    //console.log("selectedRowsData :  ", selectedRowsData)
    // Convert SDF string to a file
    // const sdfBlob1 = new Blob([selectedRowsData[0].SDF], {
    //   type: "application/vnd.kinar",
    // });
    // const sdfFile1 = new File([sdfBlob1], "structure1.sdf", {
    //   type: "application/vnd.kinar",
    // });

    // Convert PDB string to a file
    const pdbBlob1 = new Blob([editorData.PDBFile], {
      type: "application/x-aportisdoc",
    });
    const pdbFile1 = new File([pdbBlob1], "structure1.pdb", {
      type: "application/x-aportisdoc",
    });
    const form = new FormData();

    // form1.append("sdf_file", sdfFile1);
    form.append("pdb_file", pdbFile1);

    try {
      let queryString = 
        `ligand_smiles=${selectedRowsData[0].smiles}
        &residue_name=${editorData.residueName}&protein_chain=${editorData.protChain}`;
      const response = await http.post(
        `${LIFT_SERVER_URL}/LIFT_api/get_interaction_molbench?${queryString}`,
        form,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "http://localhost:3000",
          },
        }
      );

      // const [response1, response2] = await Promise.all([request1, request2]);

      setDataToCompare([
        {
          ...response.data,
          smiles: selectedRowsData[0].smiles,
          srNo: allSelectedRows[0],
        },
      ]);

      setCompareLoader(false);
      setOpenComapreDialog(true);
    } catch (error) {
      setCompareLoader(false);
      setOpenAlert(true);
      setAlertSeverity("error");
      setAlertMsg("error while comparing molecules");
    }
  };

  const compareRows = async () => {
    // if (allSelectedRows.length === 1 && !selectedRowsData[0].docking_score) {
    //   setOpenAlert(true);
    //   setAlertSeverity("info");
    //   setAlertMsg("Please get the Docking Score(s)");
    //   return;
    // }

    if (allSelectedRows.length > 2) {
      setOpenAlert(true);
      setAlertSeverity("info");
      setAlertMsg("Please Select Only 2 Rows for Comparison.");
      return;
    }

    if (allSelectedRows.length === 1) {
      singleRowInteractions();
      return;
    }

    // console.log("allreos",allSelectedRows);

    setCompareLoader(true);

    // Convert SDF string to a file
    // const sdfBlob1 = new Blob([selectedRowsData[0].SDF], {
    //   type: "application/vnd.kinar",
    // });
    // const sdfFile1 = new File([sdfBlob1], "structure1.sdf", {
    //   type: "application/vnd.kinar",
    // });

    // const sdfBlob2 = new Blob([selectedRowsData[1].SDF], {
    //   type: "application/vnd.kinar",
    // });
    // const sdfFile2 = new File([sdfBlob2], "structure2.sdf", {
    //   type: "application/vnd.kinar",
    // });

    // Convert PDB string to a file
    const pdbBlob1 = new Blob([editorData.PDBFile], {
      type: "application/x-aportisdoc",
    });
    const pdbFile1 = new File([pdbBlob1], "structure1.pdb", {
      type: "application/x-aportisdoc",
    });

    // const pdbBlob2 = new Blob([editorData.PDBFile], {
    //   type: "application/x-aportisdoc",
    // });
    // const pdbFile2 = new File([pdbBlob1], "structure2.pdb", {
    //   type: "application/x-aportisdoc",
    // });

    const form = new FormData();
    // const form2 = new FormData();

    // form1.append("sdf_file", sdfFile1);
    form.append("pdb_file", pdbFile1);

    // form2.append("sdf_file", sdfFile2);
    // form2.append("pdb_file", pdbFile2);

    try {
      // const ligandSmiles = selectedRowsData[0].smiles; // Ensure this is safe
      const residueName = editorData.residueName;
      const protChain = editorData.protChain;
      let queryString = `ligand_smiles=${selectedRowsData[0].smiles}&residue_name=${residueName}&protein_chain=${protChain}`;
      const response1 = await http.post(
        `${LIFT_SERVER_URL}/LIFT_api/get_interaction_molbench?${queryString}`,
        form,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "http://localhost:3000",
          },
        }
      );

      queryString = `ligand_smiles=${selectedRowsData[1].smiles}&residue_name=${residueName}&protein_chain=${protChain}`;
      const response2 = await http.post(
        `${LIFT_SERVER_URL}/LIFT_api/get_interaction_molbench?${queryString}`,
        form,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "http://localhost:3000",
          },
        }
      );

      // const [response1, response2] = await Promise.all([request1, request2]);

      setDataToCompare([
        {
          ...response1.data,
          smiles: selectedRowsData[0].smiles,
          srNo: allSelectedRows[0],
        },
        {
          ...response2.data,
          smiles: selectedRowsData[1].smiles,
          srNo: allSelectedRows[1],
        },
      ]);

      setCompareLoader(false);
      setOpenComapreDialog(true);
    } catch (error) {
      setCompareLoader(false);
      setOpenAlert(true);
      setAlertSeverity("error");
      setAlertMsg("Error while Comparing molecules");
    }
  };

  const exportTableResult = () => {
    setCompareLoader(true);
    const csvOptions = {
      fileName: "compared_molecules_from_molgen.csv",
      delimiter: ",",
    };
    const exportData = table.current.getDataAsCsv(csvOptions);
    const blob = new Blob([exportData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", csvOptions.fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => {
      setCompareLoader(false);
    }, 2000);
  };

  return (
    <>
      <Snackbar
        open={openAlert}
        autoHideDuration={5000}
        onClose={() => setOpenAlert(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpenAlert(false)}
          severity={alertSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {alertMsg}
        </Alert>
      </Snackbar>

      <Box
        sx={{
          background: "#fff",
        }}
      >
        <Grid
          item
          container
          px={1}
          pt={1}
          sx={{
            //  overflowY: "auto",

            height: "auto",
          }}
        >
          <Grid item width={"100%"}>
            {molEditorResult.length > 0 ? (
              <>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  mb={1}
                  alignItems={"center"}
                  position={"sticky"}
                  zIndex={1000}
                  sx={{
                    background: "#fff",
                  }}
                >
                  <Typography textAlign={"center"} sx={{ color: "#582FF1" }}>
                    Click on the rows to see the visualization
                  </Typography>
                  <Stack spacing={1} direction={"row"}>
                    <Button
                      variant="outlined"
                      size="small"
                      disabled={selectedRowsData.length < 1}
                      onClick={compareRows}
                      startIcon={<CompareIcon />}
                      sx={{
                        px: 2,
                        fontWeight: "bold",
                      }}
                    >
                      {allSelectedRows.length === 1 ? "View" : "Compare"}
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      color="success"
                      disabled={allSelectedRows.length === 0}
                      onClick={exportTableResult}
                      startIcon={<GetAppIcon />}
                      sx={{
                        px: 3,
                        fontWeight: "bold",
                      }}
                    >
                      Export
                    </Button>
                  </Stack>
                </Box>
                {compareLoader && (
                  <Box>
                    <LinearProgress color="success" />
                  </Box>
                )}
                <Box
                // Add margin top to provide space for the fixed header
                >
                  <Card sx={{
                    width: "100%",
                    height : "35rem"
                  }}>
                    <DataGridPro
                      getRowId={(row) => row.result_id}
                      apiRef={table}
                      sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                        },
                        "& .MuiDataGrid-columnHeader": {
                          // Forced to use important since overriding inline styles
                          height: "unset !important",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          // Forced to use important since overriding inline styles
                          maxHeight: "175px !important",
                          textAlign: "center",
                        },
                      }}
                      rows={molEditorResult}
                      columns={columns}
                      onRowClick={handleRowClick}
                      pagination
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      density={"comfortable"}
                      pageSizeOptions={[10, 15]}
                      checkboxSelection
                      onRowSelectionModelChange={handleSelectionChange}
                      disableRowSelectionOnClick
                      rowSelectionModel={allSelectedRows}
                      slots={{
                        toolbar: (props) => (
                          <CustomToolbar props={props} isExport={false} />
                        ),
                      }}
                    // autoHeight
                    />
                  </Card>
                </Box>
              </>
            ) : (
              // <Box position={"relative"} top={"50%"}>
              //   <ConditionalDisplayBox msg="Draw and Get the Result" />
              // </Box>
              <></>
            )}
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .css-1q3t5pl-MuiPaper-root-MuiDialog-paper": {
            maxWidth: "1100px !important",
          },
          "& .css-esw9ho": {
            maxWidth: "1100px !important",
          },
        }}
      >
        <DialogContent
          sx={{
            width: "1100px",
            height: "900px",
          }}
        >
          <div
            id="molviewer"
            style={{
              height: "100%",
              width: "100%",
              position: "relative",
            }}
            ref={viewerRef}
          ></div>
        </DialogContent>
      </Dialog>
      <CompareMoleculesCopilot
        openComapreDialog={openComapreDialog}
        setOpenComapreDialog={setOpenComapreDialog}
        dataToCompare={dataToCompare}
      />
      <ViewMolecule
        openComapreDialog={openViewDialog}
        setOpenComapreDialog={setOpenViewDialog}
        dataToCompare={dataToCompare}
      />
    </>
  );
}

export default memo(MolEditorResultV3);
