import React, { useRef, useState, useEffect } from "react";
import { GridColDef, GridRowParams, GridToolbar } from "@mui/x-data-grid-pro";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { TabPanel } from "../../components/common/TabPanel";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Tab,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";

import { saveAs } from "file-saver";
import http from "../../net/http-common";

import { DataGridPro, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { setLigandUrl } from "./dockingSlice";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { JOB_SERVER_URL } from "../../config";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

function AutoDockVisualize() {
  const [tabValue, setTabValue] = useState("1");
  const [tableVisible, settableVisible] = useState(false);
  const [rows, setRows] = useState<[]>();
  const [downloadLoading, setdownloadLoading] = useState<boolean>(false);
  const [modelName, setModelName] = useState<string>("");
  const [allColumns, setAllColumns] = useState<GridColDef[]>([]);

  const { pathname } = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dockingModuleProperties = useSelector(
    (state: any) => state.dockingModule.properties
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleRowClick = (
    params: GridRowParams, // GridRowParams
    event: any
  ) => {
    if (!params) return;
    // Check if the clicked element is the download button

    if (event.target.tagName.toLowerCase() !== "button") {
      dispatch(
        setLigandUrl([
          params.row.interaction,
          params.row.SMILES,
          params.row.Docking_Score,
        ])
      );

      if (modelName === "unidock")
        navigate("/virtual-screening/docking/unidock/viewer");
      else {
        navigate("/virtual-screening/docking/autodock/viewer");
      }
    }
  };

  // Custom tooltip text and colors for cell value
  const handleNullValueOfColumn = (
    params: GridRenderCellParams<any, number>
  ) => {
    if (!params.value) {
      return <div>...</div>;
    }

    if (typeof params.value == "number")
      return <p>{params.value.toFixed(3)}</p>;
    else return <p>{params.value}</p>;
  };

  //columns for data grid table
  const downloadcolumns: GridColDef[] = [
    {
      field: "ligand_url",
      headerName: "Download Ligands",
      editable: false,
      headerAlign: "center",
      align: "center",
      width: 400,
      renderCell: (params) => {
        const handleDownload = (event: any) => {
          event.stopPropagation(); // Prevent event propagation
          const link = document.createElement("a");
          link.href = params.row.ligand_url;
          link.download = "filename"; // You can specify the filename here
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };

        return (
          <Button onClick={(event) => handleDownload(event)}>
            Download <FileDownloadIcon />{" "}
          </Button>
        );
      },
    },
  ];
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", headerAlign: "center", align: "center" },

    {
      field: "Name",
      headerName: "Name",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: handleNullValueOfColumn,
    },
    {
      field: "svg",
      headerName: "2D Representation",
      description: "This column has images and is not sortable.",
      sortable: false,
      filterable: false,
      disableExport: true,
      width: 120,

      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{ height: "200px", width: "80%" }}
        />
      ),
    },
    {
      field: "SMILES",
      headerName: "SMILES",
      editable: false,
      headerAlign: "center",
      align: "center",
      width: 300,
    },

    {
      field: "Docking_Score",
      headerName: "Docking Score",
      type: "number",
      editable: false,
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: handleNullValueOfColumn,
    },
    {
      field: "Interaction",
      headerName: "Download",
      editable: false,
      headerAlign: "center",
      align: "center",
      width: 500,
      renderCell: (params) => {
        const handleDownload = (event: any) => {
          event.stopPropagation(); // Prevent event propagation
          const link = document.createElement("a");
          link.href = params.row.interaction;
          link.download = "filename"; // You can specify the filename here
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };
        const handleDownloadPreparedLigand = (event: any) => {
          event.stopPropagation();
          const link = document.createElement("a");
          link.href = params.row.prepared_ligand;
          link.download = "filename";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };

        return (
          <Box>
            <Button onClick={(event) => handleDownload(event)}>
              Interaction Output <FileDownloadIcon />{" "}
            </Button>
            {/* <Button
              onClick={(event) => handleDownloadPreparedLigand(event)}
              color="success"
            >
              Prepared Ligand <FileDownloadIcon />{" "}
            </Button> */}
          </Box>
        );
      },
    },
  ];

  const handleDownloadArchive = (e: any) => {
    async function fetchArchiveDownload(task_id: string) {
      try {
        setdownloadLoading(true);
        const response = await http.get(
          `${JOB_SERVER_URL}/dock/get_interactions?task_id=${task_id}&model_name=${modelName}`,
          { responseType: "arraybuffer" } // Set responseType to 'json'
        );

        const blob = new Blob([response.data], { type: "application/zip" });
        saveAs(blob, `${task_id}_Interactions.zip`);
      } catch (err) {
        console.error("Error:", err);
        // Handle specific error cases if needed
      } finally {
        setdownloadLoading(false);
      }
    }
    fetchArchiveDownload(dockingModuleProperties.autoDockdata.task_id);
  };

  const handleDownloadReceptor = (event: any) => {
    event.stopPropagation();
    const link = document.createElement("a");
    link.href = dockingModuleProperties.autoDockdata.protein_url;
    link.download = "filename";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const findKeysType = (obj: any, key: any) => {
    return typeof obj[key];
  };

  useEffect(() => {
    if (
      typeof dockingModuleProperties.autoDockdata != "undefined" &&
      dockingModuleProperties.autoDockdata.results
    ) {
      const modifiedRows = dockingModuleProperties.autoDockdata.results.map(
        (propertyObject: any) => {
          return {
            ...propertyObject,
            id: propertyObject.id + 1,
          };
        }
      );

      const predefinedFields = columns.map((col) => col.field);
      const tobeRemoved = [
        "interaction",
        "smiles",
        "prepared_ligand",
      ];
      predefinedFields.push(...tobeRemoved);
      console.log("predefinedFields : ", predefinedFields);

      // Identify dynamic fields (User Defined Field)
      const dynamicFields = modifiedRows.reduce((fields: any, item: any) => {
        Object.keys(item).forEach((key) => {
          if (!predefinedFields.includes(key) && !fields.includes(key)) {
            fields.push(key);
          }
        });
        return fields;
      }, []);

      // Create dynamic columns
      const dynamicColumns = dynamicFields.map((field: any) => ({
        field,
        headerName: field.toUpperCase(),
        headerAlign: "center",
        align: "center",
        width: 150,
        type: findKeysType(modifiedRows[0], field),
        renderCell: (params: any) => {
          const typeOfValue = typeof params.value;
          if (typeOfValue === "boolean") {
            if (params.value) {
              return (
                <Tooltip title={"TRUE"} placement="top" arrow>
                  <div>TRUE</div>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip title={"FALSE"} placement="top" arrow>
                  <div>TRUE</div>
                </Tooltip>
              );
            }
          } else if (typeOfValue === "number") {
            return (
              <Tooltip title={params.value} placement="top" arrow>
                <div>{params.value.toFixed(3)}</div>
              </Tooltip>
            );
          }

          return (
            <Tooltip title={params.value} placement="top" arrow>
              <div>{params.value}</div>
            </Tooltip>
          );
        },
      }));
      columns.push(...dynamicColumns);
      setAllColumns(columns);
      modifiedRows.sort((a: any, b: any) => a.id - b.id);
      setRows(modifiedRows);
      settableVisible(true);
    } else if (
      (typeof dockingModuleProperties.autoDockdata == "undefined" ||
        typeof dockingModuleProperties.autoDockdata.results == "undefined") &&
      !dockingModuleProperties.loading
    ) {
      navigate(`/virtual-screening/docking/${modelName}`);
    }
  }, [dockingModuleProperties]);

  useEffect(() => {
    const pathSegment = pathname.split("/");

    if (pathSegment[3] === "autodock") setModelName("autodock");
    else if (pathSegment[3] === "unidock") setModelName("unidock");
  }, [pathname]);

  return (
    <>
      <Grid container spacing={2} p={2} height={"95%"}>
        <Grid item container px={0} pt={3} height={"100%"}>
          <Grid item width={"100%"} sx={{ overflowY: "auto" }} height={"100%"}>
            {tableVisible ? (
              <Card sx={{ position: "relative" }}>
                <TabContext value={tabValue}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      value={tabValue}
                      onChange={handleTabChange}
                      aria-label="filter tabs"
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab label="Predictions" value="1" />
                    </TabList>
                  </Box>
                  {/* Predictions */}
                  <TabPanel value="1">
                    <Box sx={{ width: "100%", height: "100%" }}>
                      <Box>
                        <Button size="small" onClick={handleDownloadArchive}>
                          Download Interactions <FileDownloadIcon />
                        </Button>

                        <Button
                          size="small"
                          onClick={handleDownloadReceptor}
                          color="success"
                        >
                          Download Receptor <FileDownloadIcon />
                        </Button>
                        {downloadLoading && ( // Render LinearProgress only if isBoxDisabled is false
                          <Box sx={{ width: "100%" }}>
                            <LinearProgress />
                          </Box>
                        )}
                      </Box>

                      <DataGridPro
                        sx={{
                          "& .MuiDataGrid-columnHeaderTitle": {
                            whiteSpace: "normal",
                            lineHeight: "normal",
                          },
                          "& .MuiDataGrid-columnHeader": {
                            // Forced to use important since overriding inline styles
                            height: "unset !important",
                          },
                          "& .MuiDataGrid-columnHeaders": {
                            // Forced to use important since overriding inline styles
                            maxHeight: "175px !important",
                            textAlign: "center",
                          },
                        }}
                        rows={rows}
                        columns={allColumns}
                        onRowClick={handleRowClick}
                        rowHeight={100}
                        pagination
                        initialState={{
                          pagination: {
                            paginationModel: { pageSize: 5 },
                          },
                          // pinnedColumns: { left: ["id", "smiles"] },
                        }}
                        pageSizeOptions={[5, 10, 20]}
                        autoHeight
                        slots={{ toolbar: GridToolbar }}
                      />
                    </Box>
                  </TabPanel>
                </TabContext>
              </Card>
            ) : (
              <Card
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "50%",
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ textAlign: "center" }}
                    variant="h5"
                    component={"div"}
                  >
                    No Data Available
                  </Typography>
                  <p style={{ textAlign: "center" }}>
                    Visit Docking AutoDock page to generate AutoDock
                    Visualization Result
                  </p>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
        <IndefiniteLoader state={dockingModuleProperties.loading} />
      </Grid>
    </>
  );
}

export default AutoDockVisualize;
