import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchSessionInfo, fetchResults, fetchAllModifications } from "./copilotApi";

interface copiltState {
    loading: "idle" | "pending" | "succeeded" | "failed"
    editorInfo: []
    molProperties: []
    modifications: []
};

const initialState: copiltState = {
    loading: "idle",
    editorInfo: [],
    molProperties: [],
    modifications: []
};

export const getSessionData = createAsyncThunk(
    "copilot/getSessionData",
    async (sessionId: string) => {
        try {
            const [sessionInfo, results, modifications] = await Promise.all([
                fetchSessionInfo(sessionId),
                fetchResults(sessionId),
                fetchAllModifications(sessionId)
            ]);
            return {
                sessionInfo: sessionInfo.data,
                results: results.data,
                modifications: modifications.data
            };
        } catch (error) {
            console.error("Error fetching session data: ", error);
            throw error;
        }
    }
);

const copilotSlice = createSlice({
    name: "copilot",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.
            addCase(getSessionData.pending,
                (state: copiltState, action: any) => {
                    state.loading = "pending";

                })
            .addCase(getSessionData.fulfilled,
                (state: copiltState, action: any) => {
                    state.editorInfo = action.payload.sessionInfo;
                    state.modifications = action.payload.modifications;
                    state.molProperties = action.payload.results;
                    state.loading = "succeeded";
                })
            .addCase(getSessionData.rejected,
                (state: copiltState, action: any) => {
                    state.loading = "failed";
                });
    },
});

export default copilotSlice.reducer;