import {
  Grid,
  Box,
  FormControl,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
  Alert,
  TextField,
  Snackbar,
  Modal,
  Autocomplete,
  LinearProgress,
  FormHelperText,
} from "@mui/material";
import {
  DataGridPro,
  useGridApiRef,
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import InfoIcon from "@mui/icons-material/Info";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FileUploader, JobsTable, WorkflowLandingTabs } from "../../components";
import http from "../../net/http-common";
import Loaders from "../../components/common/Loaders";
import { JOB_SERVER_URL } from "../../config";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { useUserAuth } from "../../context";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { WorkflowLandingTab } from "../../models";
import { bioisoProperties, fetchSingleSmileData } from "./BioisostericSlice";
import { SOLAR_DEV } from "../../config";

// /TODO : add filter in modal
const allFragmentss: any = [
  {
    id: 1,
    name: "Fragment A",
    svg: "https://via.placeholder.com/50?text=A", // Placeholder image for SVG
  },
  {
    id: 2,
    name: "Fragment B",
    svg: "https://via.placeholder.com/50?text=B",
  },
  {
    id: 3,
    name: "Fragment C",
    svg: "https://via.placeholder.com/50?text=C",
  },
  {
    id: 4,
    name: "Fragment D",
    svg: "https://via.placeholder.com/50?text=D",
  },
  {
    id: 5,
    name: "Fragment E",
    svg: "https://via.placeholder.com/50?text=E",
  },
];

const tabs: Array<WorkflowLandingTab> = [
  {
    id: "0",
    label: "Description",
    value: (
      <>
        <Typography paragraph>
          Generate bioisosteric replacements that preserve essential
          non-covalent interactions,while optimizing key drug properties like
          QED and synthetic accessibility (SA), enabling focused enhancements
          without compromising essential molecular interactions.Users can enter
          a SMILES string and select the properties and fragments they would
          like to be considered.
        </Typography>
        <Box
          component="img"
          src="images/new-descriptive-images/bioiso_descriptive.png"
          alt=""
          sx={{
            width: "60%",
            height: "auto",
            mt: 0, // Adds margin-top for space above the image
            mb: 2, // Adds margin-bottom for space below the image
            mx: "auto", // Centers the image horizontally
            display: "block", // Ensures the Box behaves like a block element
            p: 2, // Adds padding around the image inside the Box
          }}
        />
      </>
    ),
  },
];
const BioisostericSubmitJobs = () => {
  const MODEL_NAME = "bioisosteric";
  const [doLoadJobs, setDoLoadJobs] = useState(true);
  const [jobID, setJobID] = useState(-1);
  const [showSubmitMsg, SetShowSubmitMsg] = useState(false);
  const [rows, setRows] = useState<any[]>();
  const [SMILESFile, setSMILESFile] = useState<File>(null);
  const [smiles, setSmiles] = useState("");
  const [headerName, setHeaderName] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState<string>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [allFragments, setAllFramgments] = useState<any[]>(allFragmentss);
  const [selectedFragments, setSelectedFragments] = useState<GridRowId[]>([]);
  // const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const GLOBAL_VARS = {
    DEFAULT_NO_SAMPLE: 10,
    MIN_NO_SAMPLE: 1,
    MAX_NO_SAMPLE: 10000,
  };
  const [molCount, setmolCount] = useState(GLOBAL_VARS.DEFAULT_NO_SAMPLE);
  const isValidNOSample =
  molCount >= GLOBAL_VARS.MIN_NO_SAMPLE &&
  molCount <= GLOBAL_VARS.MAX_NO_SAMPLE;
  
  const molProperties = [
    "logp",
    "mw",
    "QED",
    "SA",
    "logp, mw",
    "logp, QED",
    "logp, SA",
    "mw, QED",
    "mw, SA",
    "logp, mw, QED, SA",
  ];

  const { properties } = useAppSelector(bioisoProperties);
  const singleSmilejobStatus = properties.loading;

  const state = useLocation();

  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);

  const globalDispatch = useAppDispatch();
  const { user } = useUserAuth();

  const handleFileUpload = async (file: File, name: string) => {
    switch (name) {
      case "smiles":
        setSMILESFile(file);
        break;
      default:
        //console.log("file type unknown");
        break;
    }
  };

  const handleDownloadSample = () => {
    const link = document.createElement("a");
    link.download = "sample_bio.csv";
    link.href = "/samples/reinvent/reinvent.csv";
    link.click();
  };
  // user cannot select 3 properties

  // const handlePropertyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const value = event.target.value; // Get the value of the checkbox

  //     // Update selectedProperties array based on whether checkbox is checked or not
  //     if (event.target.checked) {
  //         setSelectedProperties((prev) => [...prev, value]); // Add to array if checked
  //     } else {
  //         setSelectedProperties((prev) => prev.filter((property) => property !== value)); // Remove from array if unchecked
  //     }

  //     console.log("setSelectedProperties : ", selectedProperties)
  // };

  const handleClose = () => {
    setOpenModal(false);
  };

  const columns: GridColDef[] = [
    { field: "Fragment", headerName: "Fragment", width: 250 },
    // { field: "Fragment Cleaned", headerName: "Fragment Cleaned", width: 250 },
    {
      field: "image",
      headerName: "2D Representation",
      sortable: false,
      filterable: false,
      disableExport: true,
      width: 300,

      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{ height: "100%" }}
        />
      ),
    },
  ];

  const table = useGridApiRef();

  const handleChooseFragment = () => {
    // setInProgress(true);
    const data = new FormData();
    data.append("smiles", smiles);
    http
      .get(`${SOLAR_DEV}/bioiso_api/get_fragments`, {
        params: {
          input_smiles: smiles,
        },
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      })
      .then((response: any) => {
        //TODO: simplify this if possible OR MAYBE HANDLE IN DATA GRID ITSELF
        let id = 0;
        response = response.data.map((item: any) => {
          id++;
          return {
            ...item,
            id,
          };
        });
        setAllFramgments(response);
        setInProgress(false);
        setOpenModal(true);
      })
      .catch((error) => {
        setInProgress(false);
        setShowError(true);
        setErrorMsg(error.message);
      });
  };
  useEffect(() => {
    if (!doLoadJobs || !user) return;
    http
      .get(`${JOB_SERVER_URL}/userjobs`, {
        params: {
          user_id: user.uid,
          model_name: MODEL_NAME,
          start: "0",
          end: "10",
        },
        headers: {
          accept: "application/json",
        },
      })
      .then((res) => {
        setDoLoadJobs(false);
        // setRows(jobsData);
        //  setRows(res.data);
      })
      .catch((error) => {
        setShowError(true);
        setErrorMsg(errorMessages.jobTableError);
        setDoLoadJobs(false);
        setRows([]);
      });
  }, [doLoadJobs]);

  const handleSubmit = async (e: any) => {
    if (smiles) {
      const smileName = smiles;
      let leavingGroup = "Any"; // default values
      let leavingIdx = -1; // default values
      const properties = selectedProperties.split(", ");
      if (selectedFragments.length != 0) {
        const selectedFragIdx = selectedFragments[0];
        leavingGroup =
          allFragments[(selectedFragIdx as any) - 1]["Fragment Cleaned"];
        leavingIdx = allFragments[(selectedFragIdx as any) - 1]["Index"];
      }
      const moleculeCount = Number(molCount);
      globalDispatch(
        fetchSingleSmileData({
          smileName,
          properties,
          moleculeCount,
          leavingGroup,
          leavingIdx,
        })
      );
      return;
    }
    setInProgress(true);
    const data = new FormData();
    const jobServerUrl = `${JOB_SERVER_URL}/reinvent/submit`;
    data.append("uid", user.uid);
    data.append("ligand_csv_file", SMILESFile);

    http
      .post(jobServerUrl, data, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
        params: {
          ligand_col_name: headerName,
        },
      })
      .then((response: any) => {
        setJobID(response.data.task_id);
        setInProgress(false);
        setDoLoadJobs(true);
        SetShowSubmitMsg(true);
      })
      .catch((error) => {
        setInProgress(false);
        setShowError(true);
        setErrorMsg(errorMessages.submitJobError);
      });
  };

  // interface Fragment {
  //     id: number;
  //     name: string;
  //     svg: string;
  // }

  interface ModalProps {
    openModal: boolean;
    handleClose: () => void;
    allFragments: any[];
  }

  //   const FragmentModal: React.FC<ModalProps> = ({ openModal, handleClose, allFragments }) => {

  // const handleSelectFragment = (id: number) => {
  //     setSelectedFragments((prev) =>
  //         prev.includes(id) ? prev.filter((fragmentId) => fragmentId !== id) : [...prev, id]
  //     );
  // };

  const handleRowSelectionChange = (ids: any) => {
    setSelectedFragments(ids);
    console.log("Selected IDs: ", ids);
  };

  return (
    <Box py={2}>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Grid container px={3} spacing={2} alignItems="stretch">
        <Grid item sm={12} md={8} sx={{ width: "100%" }} display={"flex"}>
          <WorkflowLandingTabs tabs={tabs} />
        </Grid>
        <Grid item sm={12} md={4} sx={{ width: "100%" }} display={"flex"}>
          <Card sx={{ height: 1, width: "100%" }}>
            <Grid item px={2} py={1.3} sx={{ backgroundColor: "grey.100" }}>
              <i className="ri-login-box-line"></i>
              <Typography
                display={"inline-block"}
                variant={"h6"}
                sx={{ color: "grey.900" }}
                ml={0.5}
                fontWeight={"600"}
              >
                {"Input"}
              </Typography>
            </Grid>
            <CardContent>
              <FormControl fullWidth>
                <Stack direction="column" spacing={1.5}>
                  <Box display={"flex"} justifyContent={"space-between"} mb={1}>
                    <Typography
                      sx={{
                        mt: 1,
                        color: "var(--shade-2900, #29283B)",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      SMILES file (.csv)
                    </Typography>

                    <Button variant="text" onClick={handleDownloadSample}>
                      {"Download Sample"}
                    </Button>
                  </Box>

                  {/* <FileUploader
                                        accept={
                                            ".csv"
                                        }
                                        handleFileUpload={(files) => {
                                            handleFileUpload(files[0], "smiles")
                                        }
                                        }
                                        headerSelector={true}
                                        handleSelectedHeader={setHeaderName}
                                        deleteHandlerDisable={() => setSMILESFile(null)}
                                    /> */}
                  {/* <Typography textAlign={"center"} my={1.5}>
                                        -- OR --
                                    </Typography> */}
                </Stack>
                <Stack spacing={1}>
                  <Typography
                    component="label"
                    htmlFor="smiles-string"
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Enter a SMILES string
                  </Typography>

                  <TextField
                    placeholder="SMILES string"
                    fullWidth
                    id="smiles-string"
                    name="smiles-string"
                    value={smiles}
                    onChange={(e) => {
                      setSmiles(e.target.value);
                      setSelectedFragments([]);
                      setSelectedProperties(null);
                    }}
                  />
                  {smiles && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleChooseFragment}
                      fullWidth
                    >
                      Choose Fragments
                    </Button>
                  )}
                  {/* </Stack> */}
                  <Modal open={openModal}>
                    <Box
                      sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        height: "95%",
                        minHeight: "500px",
                        width: "60%",
                        overflowY: "auto",
                        borderRadius: "8px",
                      }}
                    >
                      <h2>Fragments</h2>

                      <DataGridPro
                        apiRef={table}
                        rows={allFragments}
                        columns={columns}
                        rowHeight={200}
                        sx={{
                          "& .MuiDataGrid-columnHeaderTitle": {
                            whiteSpace: "normal",
                            lineHeight: "normal",
                            fontWeight: "550", // Force font weight to bold
                            fontSize: "0.9rem",
                          },
                          "& .MuiDataGrid-columnHeader": {
                            // Forced to use important since overriding inline styles
                            height: "unset !important",
                          },
                          "& .MuiDataGrid-columnHeaders": {
                            // Forced to use important since overriding inline styles
                            maxHeight: "175px !important",
                            textAlign: "center",
                          },
                        }}
                        disableMultipleRowSelection={true} // Limits to one row, but we control this manually
                        pagination
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 5,
                            },
                          },
                        }}
                        onRowSelectionModelChange={(
                          newSelection: GridRowSelectionModel
                        ) => {
                          if (newSelection.length > 1) {
                            setSelectedFragments([
                              newSelection[newSelection.length - 1],
                            ]); // Only allow one row to be selected
                          } else {
                            setSelectedFragments(newSelection);
                          }
                        }}
                        rowSelectionModel={selectedFragments}
                        pageSizeOptions={[5, 10, 20]}
                        checkboxSelection // Keep checkboxes enabled
                        autoHeight
                      />
                      <Button
                        variant="contained"
                        onClick={handleClose}
                        fullWidth
                        sx={{ mt: 2 }}
                      >
                        Done
                      </Button>
                    </Box>
                  </Modal>

                  <Stack />
                  <Autocomplete
                    ListboxProps={{ style: { maxHeight: "11rem" } }}
                    onChange={(event, value) => setSelectedProperties(value)}
                    value={selectedProperties}
                    disablePortal
                    options={molProperties}
                    renderInput={(params) => (
                      <TextField {...params} label="Choose Properties" />
                    )}
                  />
                  <Typography
                    component="label"
                    htmlFor="smiles-string"
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                    mt={2}
                  >
                    Enter Number of Molecules (Max: 10,000)
                  </Typography>

                  <FormControl fullWidth error={!isValidNOSample}>
                    <TextField
                      fullWidth
                      type="number"
                      onChange={(e) => setmolCount(parseInt(e.target.value))}
                      value={molCount}
                    ></TextField>
                    {!isValidNOSample && (
                      <FormHelperText>
                        No of Molecules should be between 1 and 10000
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
                {singleSmilejobStatus === true && (
                  <>
                    <Typography mt={1}>
                      Your request is being processed. You will be notified once
                      it is completed.
                    </Typography>
                    <LinearProgress sx={{ marginTop: "5px" }} color="success" />
                  </>
                )}
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    mt: 2,
                    "&.Mui-disabled": {
                      background: "#BDADF9",
                      color: "#fff",
                    },
                  }}
                  disabled={
                    // [0,3].includes(selectedProperties.length) ||
                    selectedProperties == null ||
                    molCount == null ||
                    Number(molCount) < 1 ||
                    singleSmilejobStatus === true ||
                    (!smiles && !SMILESFile)
                  }
                >
                  Submit
                </Button>
              </FormControl>
              <IndefiniteLoader state={inProgress} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid sx={{ width: "50%", mx: "auto" }}>
        {showSubmitMsg && (
          <Alert
            onClose={() => {
              SetShowSubmitMsg(false);
            }}
            sx={{ mt: 2 }}
            variant="filled"
            severity="success"
          >{`Job submitted with id ${jobID}`}</Alert>
        )}
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className="jobs-container"
        mt={2}
        px={3}
      >
        <Card sx={{ width: 1 }}>
          {/* <CardContent>
                        {doLoadJobs && <Loaders type={"table"} />}
                        {
                            !doLoadJobs
                            &&
                            (
                                <JobsTable
                                    jobs={rows}
                                    disableVisualize={false}
                                    setDoLoadJobs={setDoLoadJobs}
                                    nextUrl="visualize"
                                    setErrorMsg={setErrorMsg}
                                    setShowError={setShowError}
                                ></JobsTable>
                            )}
                    </CardContent> */}
        </Card>
      </Grid>
    </Box>
  );
};

export default BioisostericSubmitJobs;
