import httpCommon from "../../net/http-common";
import { REINVENT_SERVER_URL } from "../../config";

export const getSingleSmileData = async ({
  smileName,
  relevantProperty,
  moleculesPerLigand,
}: {
  smileName: string;
  relevantProperty: string;
  moleculesPerLigand: number;
}) => {
  return await httpCommon.post(
    `${REINVENT_SERVER_URL}/submit_generation/`,
    {
      smiles: smileName,
      property: relevantProperty,
      num_mols_per_ligand : moleculesPerLigand,
    },
    {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      }
    }
  );
};