import { createBrowserRouter, RouteObject } from "react-router-dom";
import Layout from "../components/layout/Layout";
import {
  Dashboard,
  KViewer,
  LigandPrep,
  ProtienPreprocess,
  PDBInfo,
  Properties,
  PropertiesHome,
  DiffMolHome,
  LigandHome,
  PDBInfoDetails,
  DockingDashboard,
  PageNotFound,
  AutoDockVisualize,
  AutoDockViewer,
} from "../views";
 
import Login from "../components/user/Login";
import Signup from "../components/user/Signup";

// import Properties from "../components/propertyfilter/Properties";
import PropertiesEvaluations from "../views/Properties/PropertiesEvaluations";
import { ProtectedRoute, PageWrapper } from "../components";
import ADMESubmitJobs from "../views/AdmeTox/ADMESubmitJobs";
import ADMEEvaluations from "../views/AdmeTox/ADMEEvaluations";
import DockingHome from "../views/Docking/DockingHome";
import PropertiesV2 from "../views/Properties/PropertiesV2";
import DockingClient from "../views/Docking/DockingClient";
import MolViewerHome from "../views/DrViz/MolViewerHome";
import LigandInteractionTable from "../components/protein-ligand/LigandInteractionTable";
import ADMEPropertyFilter from "../views/AdmeTox/ADMEPropertyFilter";
import DockingResults from "../views/Docking/DockingResults";
import PromiscuitySubmitJobs from "../views/Promiscuity/PromiscuitySubmitJobs";
import GenieDashboard from "../views/Genie/GenieDashboard";
import PromiscuityVisualize from "../views/Promiscuity/PromiscuityVisualize";
import PromiscuityViewer from "../views/Promiscuity/PromiscuityViewer";
import RGroupsChemlet from "../views/Chemlets/RGroupsChemlet";
import ChemletDashboard from "../views/Chemlets/ChemletDashboard";
import SequentialFiltering from "../views/Chemlets/SequentialFiltering";
// import SheetsSample from "../views/Chemlets/SheetsSample";
import Fragments from "../views/Chemlets/Fragments";
import MolSimilarity from "../views/Chemlets/MolSimilarity";
import PLIP from "../views/Chemlets/PLIP";
import MolProperties from "../views/Chemlets/MolProperties";
import MolEditor from "../views/Chemlets/MolEditor";
import ProfilePage from "../views/ProfilePage/ProfilePage";
import Pricing from "../views/Pricing/pricing";
import PropertiesHomeV3 from "../views/Properties/PropertiesHomeV3";
import PropertiesVisualizeV3 from "../views/Properties/PropertiesVisualizeV3";
import LeaderDashboard from "../views/Leader/LeaderDashboard";
// import InteractionAdderHome from "../views/InteractionAdder/InteractionAdderHome";
import MoleculeCopilotHome from "../views/MoleculeCopilot/MoleculeCopilotHome";
import Molfil from "../views/Molfil/Molfil";
import LiftVisualizer from "../views/LiftVisualizer/LiftVisualizer";
import LiftVisualResult from "../views/LiftVisualizer/LiftVisualResult";
import LiftFilterMolecules from "../views/LiftFilter/LiftFilterMolecules";
import LiftFilterVisualizer from "../views/LiftFilter/LiftFilterVisualizer";
import MoleculeCopilot from "../views/MoleculeCopilot/MoleculeCopilot";
import MoleculeCopilotResult from "../views/MoleculeCopilot/MoleculeCopilotResult";
// import Comingsoon from "../views/ComingSoon/Comingsoon";
import CoPilotSessions from "../views/Copilot/CoPilotSessions";
import PropertyEditor from "../views/Copilot/PropertyEditor";
import RankerVisualize from "../views/Ranker/RankerVisualize";
import RankerSubmitJobs from "../views/Ranker/RankerSubmitJobs";
import RankerEvaluations from "../views/Ranker/RankerEvaluations";
import LeaderReinvent from "../views/leaderReinvent/LeaderReinvent";
import LeaderReinventVisualize from "../views/leaderReinvent/LeaderReinventVisualize";
import BioisostericSubmitJobs from "../views/Bioisosteric/BioisostericSubmitJobs";
import BioisoVisualize from "../views/Bioisosteric/BioisoVisualize";
import MoleculeClustring from "../views/Chemlets/MoleculeClustering";
import InternalDockingDashboard from "../components/InternalDocking/InternalDockingCards";
import Hopper from "../views/Chemlets/Hopper";
import Genfil from "../views/Chemlets/Genfil";
import LodestarHome from "../views/Lodestar/LodestarHome";
import MoleculeDesignDashboard from "../views/MoleculeDesign/MoleculeDesignDashboard";
import AdmetDashboard from "../views/AdmeTox/AdmetDashboard";

type MoonvizRoutes = RouteObject & {
  title?: string;
  children?: Array<RouteObject & MoonvizRoutes>;
};
export const routes: Array<MoonvizRoutes> = [
  {
    path: "/",
    element: (
      <ProtectedRoute section="Dashboard">
        <Dashboard />
      </ProtectedRoute>
    ),
    title: "Dashboard",
    id: "home",
  },
  {
    path: "virtual-screening",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <DockingDashboard />
      </ProtectedRoute>
    ),
    title: "Virtual Screening",
    id: "virtual-screening-dashboard",
  },
  // Docking Viewer
  {
    path: "virtual-screening/docking/viewer",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <DockingHome />
      </ProtectedRoute>
    ),
    title: "Docking Viewer",
    id: "docking-viewer",
  },
  {
    path: "virtual-screening/docking",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <InternalDockingDashboard />
      </ProtectedRoute>
    ),
    title: "Docking",
    id: "internal-docking-cards",
  },
  {
    path: "virtual-screening/genfil",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <Genfil />
      </ProtectedRoute>
    ),
    title: "GenFil",
    id: "genfil",
  },
  {
    path: "genie",
    element: (
      <ProtectedRoute section="De Novo Generation">
        <GenieDashboard />
      </ProtectedRoute>
    ),
    title: "De Novo Generation Dashboard",
    id: "genie-dashboard",
  },
  {
    path: "genie/maimol",
    element: (
      <ProtectedRoute section="De Novo Generation">
        <DiffMolHome />
      </ProtectedRoute>
    ),
    title: "MAIMol",
    id: "maimol",
  },
  {
    path: "genie/ranker",
    element: (
      <ProtectedRoute section="De Novo Generation">
        <RankerSubmitJobs />
      </ProtectedRoute>
    ),
    title: "Ranker",
    id: "ranker",
  },
  {
    path: "genie/ranker/visualize",
    element: (
      <ProtectedRoute section="De Novo Generation">
        <RankerVisualize />
      </ProtectedRoute>
    ),
    title: "Ranker",
  },
  {
    path: "genie/ranker/evaluations",
    element: (
      <ProtectedRoute section="De Novo Generation">
        <RankerEvaluations />
      </ProtectedRoute>
    ),
    title: "Ranker",
  },
  {
    path: "adme",
    element: (
      <ProtectedRoute section="ADMET">
        {/* <ADMESubmitJobs /> */}
        <AdmetDashboard/>
      </ProtectedRoute>
    ),
    title: "ADME and Tox",
    id: "adme_tox",
  },
  {
    path: "adme/admet-pro",
    element: (
      <ProtectedRoute section="ADMET">
        <ADMESubmitJobs />
      </ProtectedRoute>
    ),
    title: "ADMET Pro",
    id: "admet-pro",
  },
  {
    path: "adme/admet-pro/evaluations",
    element: (
      <ProtectedRoute section="ADMET">
        <ADMEEvaluations />
      </ProtectedRoute>
    ),
    title: "ADMET Evaluation Results",
    id: "adme_tox_eval",
  },
  {
    path: "adme/admet-pro/visualize",
    element: (
      <ProtectedRoute section="ADMET">
        <ADMEPropertyFilter />
      </ProtectedRoute>
    ),
    title: "ADME and Tox",
  },
  {
    path: "molbench-copilot",
    element: (
      <ProtectedRoute section="MolBench Copilot">
        <CoPilotSessions />
      </ProtectedRoute>
    ),
    title: "MolBench Copilot",
  },
  {
    path: "screenie/properties",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <PropertiesHome />
      </ProtectedRoute>
    ),
    title: "Screenie",
    id: "properties-home",
  },
  {
    path: "virtual-screening/properties-v2",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <PropertiesHome />
      </ProtectedRoute>
    ),
    title: "Screenie",
    id: "properties-home-v2",
  },
  {
    path: "virtual-screening/properties-v3",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <PropertiesHomeV3 />
      </ProtectedRoute>
    ),
    title: "Screenie",
    id: "properties-home-v3",
  },
  {
    path: "virtual-screening/properties-v3/visualize",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <PropertiesVisualizeV3 />
      </ProtectedRoute>
    ),
    title: "Screenie",
    id: "properties-visualize-v3",
  },
  {
    path: "virtual-screening/properties/filter",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <Properties />
      </ProtectedRoute>
    ),
    title: "Screenie",
    id: "properties-filter",
  },
  {
    path: "/user/profile",
    element: <ProfilePage />,
    title: "Profile",
    id: "profile",
  },

  {
    path: "virtual-screening/properties-v2/filter",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <PropertiesV2 />
      </ProtectedRoute>
    ),
    title: "Screenie",
    id: "properties-filter-v2",
  },

  {
    path: "pricing",
    element: <Pricing />,
    title: "Pricing",
    id: "pricing",
  },

  {
    path: "user/login",
    element: (
      // <ProtectedRoute  >
      <Login />
      // </ProtectedRoute>
    ),
    title: "Login",
    id: "login",
  },
  {
    path: "user/signup",
    element: (
      // <ProtectedRoute >
      <Signup />
      // </ProtectedRoute>
    ),
    title: "SignUp",
    id: "signup",
  },

  // Docking client
  {
    path: "virtual-screening/docking/autodock",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <DockingClient />
      </ProtectedRoute>
    ),

    title: "AutoDock Docking",
    id: "docking-autodock",
  },
  {
    path: "virtual-screening/docking/unidock",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <DockingClient />
      </ProtectedRoute>
    ),

    title: "UniDock Docking",
    id: "docking-unidock",
  },
  {
    path: "virtual-screening/docking/autodock/visualize",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <AutoDockVisualize />
      </ProtectedRoute>
    ),

    title: "Autodock Visualization",
    id: "docking-autodock-visualize",
  },
  {
    path: "virtual-screening/docking/unidock/visualize",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <AutoDockVisualize />
      </ProtectedRoute>
    ),

    title: "UniDock Visualization",
    id: "docking-unidock-visualize",
  },
  {
    path: "virtual-screening/docking/autodock/viewer",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <AutoDockViewer />
      </ProtectedRoute>
    ),

    title: "Autodock Viewer",
    id: "docking-autodock-viewer",
  },
  {
    path: "virtual-screening/docking/unidock/viewer",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <AutoDockViewer />
      </ProtectedRoute>
    ),

    title: "UniDock Viewer",
    id: "docking-unidock-viewer",
  },
  {
    path: "virtual-screening/docking/autodock/results",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <DockingResults />
      </ProtectedRoute>
    ),

    title: "AutoDock Results",
    id: "results-autodock",
  },
  {
    path: "virtual-screening/docking/unidock/results",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <DockingResults />
      </ProtectedRoute>
    ),

    title: "UniDock Results",
    id: "results-unidock",
  },
  {
    path: "ligand-interaction",
    element: (
      <ProtectedRoute section="Dashboard">
        <LigandInteractionTable />
      </ProtectedRoute>
    ),

    title: "Protein Ligand Interaction",
    id: "ligand-interaction",
  },
  {
    path: "virtual-screening/properties-v3/evaluations",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <PropertiesEvaluations />
      </ProtectedRoute>
    ),
    title: "Screenie Evaluation",
    id: "Properties Evaluation",
  },
  {
    path: "virtual-screening/molfil",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <Molfil />
      </ProtectedRoute>
    ),
    title: "MolFil",
    id: "molfil",
  },
  {
    path: "virtual-screening/lift/visualizer",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <LiftVisualizer />
      </ProtectedRoute>
    ),

    title: "LIFT Visualizer",
    id: "lift-visualizer",
  },

  {
    path: "virtual-screening/lift/visualizer/result",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <LiftVisualResult />
      </ProtectedRoute>
    ),

    title: "LIFT Visualizer",
    id: "lift-visualizer-result",
  },
  {
    path: "virtual-screening/lift/filter-molecules",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <LiftFilterMolecules />
      </ProtectedRoute>
    ),

    title: "LIFT Filter",
    id: "filter-molecules",
  },
  {
    path: "virtual-screening/lift/filter-molecules/visualize",
    element: (
      <ProtectedRoute section="Virtual Screening">
        <LiftFilterVisualizer />
      </ProtectedRoute>
    ),

    title: "LIFT Filter",
    id: "filter-molecules-visualize",
  },
  {
    path: "leader",
    element: (
      <ProtectedRoute section="Lead Optimisation">
        <LeaderDashboard />
      </ProtectedRoute>
    ),

    title: "Lead Optimisation",
    id: "leader",
  },
  // {
  //   path: "leader/interactions",
  //   element: (
  //     <ProtectedRoute section="Lead Optimisation">
  //        <InteractionAdderHome/>
  //     </ProtectedRoute>
  //   ),

  //   title: "Interaction Adder",
  //   id: "interaction-adder",
  // },
  {
    path: "molecule-design",
    element: (
      <ProtectedRoute section="Molecule Design">
        <MoleculeDesignDashboard/>
      </ProtectedRoute>
    ),

    title: "Molecule Design",
    id: "molecule-design",
  },
  {
    path: "molecule-design/copilot",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <MoleculeCopilot />
      </ProtectedRoute>
    ),

    title: "Molecule CoPilot",
    id: "lab-molecule-copilot",
  },
  {
    path: "molecule-design/copilot/modifications",
    element: (
      <ProtectedRoute section="Lead Optimisation">
        <MoleculeCopilotResult />
      </ProtectedRoute>
    ),

    title: "Molecule CoPilot",
    id: "molecule-modifications",
  },
  {
    path: "molecule-design/molbench",
    element: (
      <ProtectedRoute section="Molecule Design">
        <CoPilotSessions />
      </ProtectedRoute>
    ),

    title: "MolBench",
    id: "molecule-design-molbench",
  },
  {
    path: "molecule-design/molbench/editor/:sessionId",
    element: (
      <ProtectedRoute section="Molecule Design">
        <PropertyEditor />
      </ProtectedRoute>
    ),
  
    title: "MolBench Editor",
    id: "molbench-editor",
    },
  
  
  {
    path: "leader/bioisosteric",
    element: (
      <ProtectedRoute section="Lead Optimisation">
        <BioisostericSubmitJobs />
      </ProtectedRoute>
    ),

    title: "BioSwap",
    id: "bioisosteric-replacement",
  },
  {
    path: "leader/bioisosteric/visualize",
    element: (
      <ProtectedRoute section="Lead Optimisation">
        <BioisoVisualize />
      </ProtectedRoute>
    ),

    title: "BioSwap",
    id: "bioisosteric-replacement-visualize",
  },
  {
    path: "lab/molbench",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <MolEditor></MolEditor>
      </ProtectedRoute>
    ),

    title: "MolBench",
    id: "lab-molbench",
  },
  
  {
    path: "lab/lift",
    element: (
      <ProtectedRoute section="LIFT">
        <PLIP></PLIP>
        {/* <LiftDashboard /> */}
      </ProtectedRoute>
    ),

    title: "LIFT",
    id: "LIFT",
  },
  {
    path: "leader/r-groups",
    element: (
      <ProtectedRoute section="R Groups">
        <RGroupsChemlet />
      </ProtectedRoute>
    ),

    title: "SubstituteR",
    id: "chemlet-rgroups",
  },
  {
    path: "lab",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <ChemletDashboard />
      </ProtectedRoute>
    ),
    title: "MoleculeAI Labs",
    id: "lab-dashboard",
  },
  {
    path: "lab/seq-filtering",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <SequentialFiltering />
      </ProtectedRoute>
    ),

    title: "Sequential Ligand Filtering",
    id: "chemlet-seq-filtering",
  },
  {
    path: "lab/fragments",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <Fragments />
      </ProtectedRoute>
    ),

    title: "Fragment-based Ligand Design",
    id: "fragments",
  },
  {
    path: "lab/mol-similarity",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <MolSimilarity />
      </ProtectedRoute>
    ),

    title: "Molecular Similarity",
    id: "mol-similarity",
  },
  {
    path: "lab/mol-props",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <MolProperties />
      </ProtectedRoute>
    ),

    title: "Molecular Properties",
    id: "mol-props",
  },
  // Viewer
  {
    path: "lab/mol-viewer",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <MolViewerHome />
      </ProtectedRoute>
    ),
    title: "Dr Viz",
    id: "mol-viewer",
  },
  {
    path: "lab/mol-viewer/viewer",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <KViewer />
      </ProtectedRoute>
    ),
    title: "Dr Viz",
    id: "viewer",
  },
  {
    path: "lab/pdb/prep",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <ProtienPreprocess />
      </ProtectedRoute>
    ),
    title: "Protein Prep",
    id: "protein-prep",
  },
  // Protien Info
  {
    path: "lab/protein-info",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <PDBInfo />
      </ProtectedRoute>
    ),
    title: "Protein Info",
    id: "protein-info",
  },
  {
    path: "lab/protein-info/details",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <PDBInfoDetails />
      </ProtectedRoute>
    ),
    title: "Protein Info",
    id: "protein-info-details",
  },
  // Ligand
  {
    path: "lab/ligand",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <LigandHome />
      </ProtectedRoute>
    ),
    title: "Ligand Prep",
    id: "ligand",
  },

  {
    path: "lab/ligand/prep",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <LigandPrep />
      </ProtectedRoute>
    ),
    title: "Ligand Prep",
    id: "ligand-prep",
  },
  {
    path: "lab/promiscuity",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <PromiscuitySubmitJobs />
      </ProtectedRoute>
    ),
    title: "Promiscuity Filter",
    id: "Promiscuity",
  },
  {
    path: "lab/promiscuity/visualize",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <PromiscuityVisualize />
      </ProtectedRoute>
    ),

    title: "Promiscuity Filter Visualization",
    id: "promiscuity-visualize",
  },
  {
    path: "lab/promiscuity/viewer",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <PromiscuityViewer />
      </ProtectedRoute>
    ),

    title: "Promiscuity Filter Visualization",
    id: "promiscuity-viewer",
  },
  {
    path: "lab/molecule-clustering",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <MoleculeClustring />
      </ProtectedRoute>
    ),

    title: "ClusterPro",
    id: "molecule-clustering",
  },
  {
    path: "lab/hopper",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <Hopper />
      </ProtectedRoute>
    ),

    title: "Hopper",
    id: "hopper",
  },
  {
    path: "lab/lodestar",
    element: (
      <ProtectedRoute section="MoleculeAI Labs">
        <LodestarHome />
      </ProtectedRoute>
    ),

    title: "Lodestar",
    id: "lodestar",
  },
  {
    path: "leader/leader",
    element: (
      <ProtectedRoute section="Lead Optimisation">
        <LeaderReinvent />
      </ProtectedRoute>
    ),
    title: "Leader",
    id: "leader-reinvent",
  },
  {
    path: "leader/leader/visualize",
    element: (
      <ProtectedRoute section="Lead Optimisation">
        <LeaderReinventVisualize />
      </ProtectedRoute>
    ),
    title: "Leader",
    id: "leader-visualize",
  },
  {
    path: "*",
    element: <PageNotFound />,
    title: "Page Not Found",
    id: "404",
  },
];

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    id: "root",
    children: routes.map(({ path, element, id, title, children }) => ({
      path,
      element: <PageWrapper title={title}>{element}</PageWrapper>,
      id,
      children: children ? [...children] : [],
    })),
  },
]);

export { router };
