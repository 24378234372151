import {
  Box,
  Button,
  CardActions,
  CardContent,
  Stack,
  Typography,
  Alert,
  Snackbar,
  Grid,
  FormControl,
  LinearProgress,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slider,
} from "@mui/material";
import { useEffect, useState } from "react";

import { JOB_SERVER_URL, RUNTIME, SERVER_URL } from "../../config";

import http from "../../net/http-common";

import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { FileUploader } from "../../components";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import { useUserAuth } from "../../context";

function LodestarInputs({
  setJobID,
  SetShowSubmitMsg,
  setDoLoadJobs,
}: {
  setJobID: React.Dispatch<React.SetStateAction<number>>;
  SetShowSubmitMsg: React.Dispatch<React.SetStateAction<boolean>>;
  setDoLoadJobs: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);
  const [PDBFile, setPDBFile] = useState<File>(null);
  const [rbfeSDFFile, setRbfeSDFFile] = useState<File>(null);

  const [ligandsFile, setLigandsFile] = useState<File>(null);
  const [pdbId, setPdbId] = useState<string>("");
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [fetchingFields, setfetchingFields] = useState(false);
  const [chains, setChains] = useState<string[]>([]);
  const [residues, setResidues] = useState<string[]>([]);
  const [residue, setResidue] = useState("");
  const [protChain, setProtChain] = useState("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [modelings, setModelings] = useState<string[]>([
    "RBFE Calculation",
    "MD Simulation",
  ]);
  const [moleculerModeling, setMoleculerModeling] =
    useState<string>("RBFE Calculation");
  const [ligandColName, setLigandColName] = useState("smiles");
  const [ligandSmiles, setLigandSmiles] = useState("");
  const { user } = useUserAuth();

  const [rbfeInputs, setRbfeInputs] = useState<any>({
    equilibrationTime: 0.5,
    simulationTime: 1.0,
    solventPadding: 1.2,
  });

  const [mdSimulationInputs, setMDSimulationInputs] = useState<any>({
    smiles: "",
    outputInterval: 0.01,
    simulationTime: 2,
    equilibrationTime: 0.01,
  });

  // console.log("mdsimulationinptus", mdSimulationInputs);

  // Handler to update the slider value in rbfe state
  const handleSliderChangerbfe = (event: any, newValue: any) => {
    setRbfeInputs({ ...rbfeInputs, [event.target.name]: Number(newValue) });
  };

  // Handler to update the slider value in mdsimulation state
  const handleSliderChangemdsim = (event: any, newValue: any) => {
    setMDSimulationInputs({
      ...mdSimulationInputs,
      [event.target.name]: Number(newValue),
    });
  };

  // console.log("rbfe inptus", rbfeInputs);

  // To close dialog box
  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleFileUpload = async (file: File, name: string) => {
    switch (name) {
      case "pdb":
        setPDBFile(file);
        break;
      case "csv":
        setLigandsFile(file);
        break;
      case "sdf":
        setRbfeSDFFile(file);
        break;
      default:
        //console.log("file type unknown");
        break;
    }
  };

  // const handleSubmit = async (e: any) => {
  //   setInProgress(true);

  //   const form = new FormData();
  //   form.append("uid", user.uid);

  //   form.append("residue_name", residue);
  //   form.append("protein_chain", protChain);

  //   form.append("lig_col_name", ligandColName);
  //   form.append("protein_file", PDBFile);

  //   try {
  //     if (ligandsFile) {
  //       form.append("ligands_file", ligandsFile);
  //       const response = await http.post(
  //         `${JOB_SERVER_URL}/lift/submit`,
  //         form,
  //         {
  //           headers: {
  //             accept: "application/json",
  //             "Content-Type": "multipart/form-data",
  //             "Access-Control-Allow-Origin": "http://localhost:3000",
  //           },
  //         }
  //       );

  //       setJobID(response.data.task_id);
  //       SetShowSubmitMsg(true);
  //       setDoLoadJobs(true);
  //     } else if (ligandSmiles) {
  //     }
  //   } catch (error) {
  //     setShowError(true);
  //     setErrorMsg(errorMessages.submitJobError);
  //   }

  //   setInProgress(false);
  // };

  const createModel = () => {
    console.log("starting other inputs");
    setDialogOpen(true);
  };

  useEffect(() => {
    if (!PDBFile) return;

    const form = new FormData();
    form.append("uploaded_protein_file", PDBFile);

    setfetchingFields(true);

    http
      .post(`${SERVER_URL}/pdb/chains`, form, {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "http://localhost:3000",
        },
      })
      .then((response: any) => {
        setfetchingFields(false);
        //  console.log(response);
        setChains(response.data[0]);
        setResidues(response.data[1]);
        setProtChain(response.data[0][0]);
        setResidue(response.data[1][0]);
      })
      .catch((error) => {
        setfetchingFields(false);
      });
  }, [PDBFile]);

  const handleDownloadSample = (fileType: string) => {
    const link = document.createElement("a");

    if (fileType == "protein") {
      link.download = "lift_filter_protein_sample.pdb";
      link.href = "/samples/autodock/2p16.pdb";
    } else if (fileType == "ligand") {
      link.download = "lift_filter_ligand_sample.csv";
      link.href = "/samples/screenie/input_screenie.csv";
    }

    link.click();
  };

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Box sx={{ height: 1, width: "100%" }}>
        <Grid item px={2} py={1.3} sx={{ backgroundColor: "grey.100" }}>
          <i className="ri-login-box-line"></i>
          <Typography
            display={"inline-block"}
            variant={"h6"}
            sx={{ color: "grey.900" }}
            ml={0.5}
            fontWeight={"600"}
          >
            {"Input"}
          </Typography>
        </Grid>
        <Grid
          container
          className={RUNTIME === "PROD" ? "blurry-bg" : ""}
          display="flex"
          flexDirection="column"
        >
          <CardContent>
            <FormControl
              fullWidth
              className={RUNTIME === "PROD" ? "blurry-bg" : ""}
            >
              <Stack direction="column" spacing={1.5}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Protein File (.pdb)
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => handleDownloadSample("protein")}
                  >
                    {"Download Sample"}
                  </Button>
                </Box>
                <FileUploader
                  accept={".pdb"}
                  handleFileUpload={(files) =>
                    handleFileUpload(files[0], "pdb")
                  }
                  deleteHandlerDisable={() => {
                    setPDBFile(null);
                    setResidue("");
                    setProtChain("");
                    return false;
                  }}
                />
              </Stack>
              <Stack direction="column" spacing={1.5}>
                <FormControl>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Residue Name
                  </Typography>
                  {fetchingFields ? <LinearProgress /> : ""}
                  <Select
                    disabled={residues?.length === 0}
                    value={residue}
                    label="Residue"
                    onChange={(e) => setResidue(e.target.value)}
                  >
                    {residues?.map((v, i) => {
                      return (
                        <MenuItem key={i} value={v}>
                          {v}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="column" spacing={1.5}>
                <FormControl>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Protein Chain
                  </Typography>
                  {fetchingFields ? <LinearProgress /> : ""}
                  <Select
                    disabled={chains?.length === 0}
                    value={protChain}
                    label="Protein Chain"
                    onChange={(e) => setProtChain(e.target.value)}
                  >
                    {chains?.map((v, i) => {
                      return (
                        <MenuItem key={i} value={v}>
                          {v}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>

              <Stack direction="column" spacing={1.5}>
                <FormControl>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Molecular Modeling
                  </Typography>
                  <Select
                    disabled={modelings?.length === 0}
                    value={moleculerModeling}
                    label="Molecular Modeling"
                    onChange={(e) => setMoleculerModeling(e.target.value)}
                  >
                    {modelings?.map((v, i) => {
                      return (
                        <MenuItem key={i} value={v}>
                          {v}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>

              <Button
                variant="contained"
                onClick={createModel}
                disabled={
                  !PDBFile || !residue || !protChain || !moleculerModeling
                }
                sx={{
                  mt: 2,
                }}
              >
                Create
              </Button>
            </FormControl>
          </CardContent>
        </Grid>
        <IndefiniteLoader state={inProgress} />
      </Box>

      {/* extraa inputs */}
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .css-1q3t5pl-MuiPaper-root-MuiDialog-paper": {
            width: "500px",
            maxWidth: "1100px",
          },
          "& .css-esw9ho": {
            width: "500px",
            maxWidth: "1100px !important",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{moleculerModeling}</DialogTitle>
        <DialogContent>
          {moleculerModeling === modelings[0] && (
            <FormControl
              fullWidth
              className={RUNTIME === "PROD" ? "blurry-bg" : ""}
            >
              <Stack direction="column" spacing={1.5}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Docked Ligand (.sdf)
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => handleDownloadSample("protein")}
                  >
                    {"Download Sample"}
                  </Button>
                </Box>
                <FileUploader
                  accept={".sdf"}
                  handleFileUpload={(files) =>
                    handleFileUpload(files[0], "sdf")
                  }
                  deleteHandlerDisable={() => {
                    setRbfeSDFFile(null);
                    return false;
                  }}
                />
              </Stack>
              <Stack direction="column" spacing={1.5}>
                <FormControl>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Equilibration Time
                  </Typography>
                  <Box>
                    <Slider
                      aria-label="Always visible"
                      value={rbfeInputs.equilibrationTime} // Bind the slider's value to the state
                      onChange={handleSliderChangerbfe} // Update the state on slider change
                      step={0.05}
                      // marks={marks}
                      min={0.05}
                      max={1.0}
                      valueLabelDisplay="on"
                      // color="success"
                      name="equilibrationTime"
                      size="medium"
                      sx={{
                        "& .MuiSlider-valueLabel": {
                          backgroundColor: "transparent", // Make the background transparent
                          color: "#000", // Adjust the text color as needed
                          top: 30, // Adjust this value to move the label below the slider
                          transform:
                            "translateY(-50%) translateX(-30%) !important", // Center align the label
                        },
                      }}
                    />
                  </Box>
                </FormControl>
              </Stack>

              <Stack direction="column" spacing={1.5}>
                <FormControl>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Simulation Time
                  </Typography>
                  <Box>
                    <Slider
                      aria-label="Always visible"
                      value={rbfeInputs.simulationTime} // Bind the slider's value to the state
                      onChange={handleSliderChangerbfe} // Update the state on slider change
                      step={1}
                      // marks={marks}
                      min={0}
                      max={5}
                      valueLabelDisplay="on"
                      // color="success"
                      name="simulationTime"
                      size="medium"
                      sx={{
                        "& .MuiSlider-valueLabel": {
                          backgroundColor: "transparent", // Make the background transparent
                          color: "#000", // Adjust the text color as needed
                          top: 30, // Adjust this value to move the label below the slider
                          transform:
                            "translateY(-50%) translateX(-30%) !important", // Center align the label
                        },
                      }}
                    />
                  </Box>
                </FormControl>
              </Stack>

              <Stack direction="column" spacing={1.5}>
                <FormControl>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Solvent Padding
                  </Typography>
                  <Box>
                    <Slider
                      aria-label="Always visible"
                      value={rbfeInputs.solventPadding} // Bind the slider's value to the state
                      onChange={handleSliderChangerbfe} // Update the state on slider change
                      step={1}
                      // marks={marks}
                      min={0}
                      max={5}
                      valueLabelDisplay="on"
                      // color="success"
                      name="solventPadding"
                      size="medium"
                      sx={{
                        "& .MuiSlider-valueLabel": {
                          backgroundColor: "transparent", // Make the background transparent
                          color: "#000", // Adjust the text color as needed
                          top: 30, // Adjust this value to move the label below the slider
                          transform:
                            "translateY(-50%) translateX(-30%) !important", // Center align the label
                        },
                      }}
                    />
                  </Box>
                </FormControl>
              </Stack>
            </FormControl>
          )}
          {moleculerModeling === modelings[1] && (
            <FormControl
              fullWidth
              className={RUNTIME === "PROD" ? "blurry-bg" : ""}
            >
              <Stack direction="column" spacing={1.5}>
                <FormControl fullWidth>
                  <Typography component="label" htmlFor="smiles-string">
                    Enter a SMILES string
                  </Typography>
                  <TextField
                    placeholder="SMILES"
                    fullWidth
                    id="ligand-smile-id"
                    name="ligand-smile-id"
                    value={mdSimulationInputs.smiles}
                    onChange={(e) =>
                      setMDSimulationInputs({
                        ...mdSimulationInputs,
                        smiles: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </Stack>

              <Stack direction="column" spacing={1.5}>
                <FormControl>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Output Interval
                  </Typography>
                  <Box>
                    <Slider
                      aria-label="Always visible"
                      value={mdSimulationInputs.outputInterval} // Bind the slider's value to the state
                      onChange={handleSliderChangemdsim} // Update the state on slider change
                      step={0.05}
                      // marks={marks}
                      min={0}
                      max={1}
                      valueLabelDisplay="on"
                      // color="success"
                      name="outputInterval"
                      size="medium"
                      sx={{
                        "& .MuiSlider-valueLabel": {
                          backgroundColor: "transparent", // Make the background transparent
                          color: "#000", // Adjust the text color as needed
                          top: 30, // Adjust this value to move the label below the slider
                          transform:
                            "translateY(-50%) translateX(-30%) !important", // Center align the label
                        },
                      }}
                    />
                  </Box>
                </FormControl>
              </Stack>

              <Stack direction="column" spacing={1.5}>
                <FormControl>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Simulation Time
                  </Typography>
                  <Box>
                    <Slider
                      aria-label="Always visible"
                      value={mdSimulationInputs.simulationTime} // Bind the slider's value to the state
                      onChange={handleSliderChangemdsim} // Update the state on slider change
                      step={1}
                      // marks={marks}
                      min={0}
                      max={5}
                      valueLabelDisplay="on"
                      // color="success"
                      name="simulationTime"
                      size="medium"
                      sx={{
                        "& .MuiSlider-valueLabel": {
                          backgroundColor: "transparent", // Make the background transparent
                          color: "#000", // Adjust the text color as needed
                          top: 30, // Adjust this value to move the label below the slider
                          transform:
                            "translateY(-50%) translateX(-30%) !important", // Center align the label
                        },
                      }}
                    />
                  </Box>
                </FormControl>
              </Stack>

              <Stack direction="column" spacing={1.5}>
                <FormControl>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "var(--shade-2900, #29283B)",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    Equilibration Time
                  </Typography>
                  <Box>
                    <Slider
                      aria-label="Always visible"
                      value={mdSimulationInputs.equilibrationTime} // Bind the slider's value to the state
                      onChange={handleSliderChangemdsim} // Update the state on slider change
                      step={0.05}
                      // marks={marks}
                      min={0}
                      max={1}
                      valueLabelDisplay="on"
                      // color="success"
                      name="equilibrationTime"
                      size="medium"
                      sx={{
                        "& .MuiSlider-valueLabel": {
                          backgroundColor: "transparent", // Make the background transparent
                          color: "#000", // Adjust the text color as needed
                          top: 30, // Adjust this value to move the label below the slider
                          transform:
                            "translateY(-50%) translateX(-30%) !important", // Center align the label
                        },
                      }}
                    />
                  </Box>
                </FormControl>
              </Stack>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            autoFocus
            variant="contained"
            size="small"
            disabled={
              (moleculerModeling === modelings[0] && !rbfeSDFFile) ||
              (moleculerModeling === modelings[1] && !mdSimulationInputs.smiles)
            }
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default LodestarInputs;
