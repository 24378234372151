interface ChemletCard {
  label: string;
  link: string;
  description: string;
  dashIcon: string;
  navlabel: string;
}

//  DeepPocket

export const chemletsCards: ChemletCard[] = [
  {
    label: "Molecular Properties",
    link: "mol-props",
    description:
      "Explore and Visualize Molecular Properties for set of molecules",
    dashIcon: "images/mol-similarity.jpeg",
    navlabel: "MolProps",
  },
  {
    label: "Sequential Ligand Filtering",
    link: "seq-filtering",
    description:
      "Sequential Pipeline with  Physicochemical Properties, Docking, ADME and Toxicity Fitlers",
    dashIcon: "images/screenie-dashcard.png",
    navlabel: "Sequential Ligand Filtering",
  },
  {
    label: "ClusterPro",
    link: "molecule-clustering",
    description: "Cluster molecules based on their SMILES representation",
    dashIcon: "images/dashcard-images/molecule-clustring.png",
    navlabel: "Sequential Ligand Filtering",
  },
  {
    label: "Hopper",
    link: "hopper",
    description:
      "Generate a drug-like molecules with Scaffold Hopping from known molecules",
    dashIcon: "images/new-dashcards-images/hopper_dashcard.png",
    navlabel: "Sequential Ligand Filtering",
  },
  {
    label: "Promiscuity Filter",
    navlabel: "Promiscuity Filter",
    link: "promiscuity",
    // icon: "/images/LeaderIconV2.svg",
    dashIcon: "/images/dashcard-images/promiscuity.png",
    description: "Predict potential off-target effects of small-molecule drugs",
  },
  {
    label: "Dr Viz",
    navlabel: "Dr Viz",
    link: "mol-viewer",
    dashIcon: "/images/dashcard-images/DrVizDashCard.png",
    description:
      "Visualize Proteins, Ligands, and their interactions all in a single interface",
  },
  {
    label: "Protein Info",
    navlabel: "Protein Info",
    link: "protein-info",
    dashIcon: "/images/dashcard-images/protein-info.png",
    description:
      "Get curated access to all of the protein's metadata  and several analyses",
  },

  {
    label: "Protein Prep",
    navlabel: "Protein Prep",
    link: "pdb/prep",
    description: "Intelligent protein preparation for all applications",
    dashIcon: "/images/dashcard-images/protein_prep1.png",
  },
  {
    label: "Ligand Prep",
    navlabel: "Ligand Prep",
    link: "ligand",
    // icon: "/images/icons/ligand-prep.svg",
    description:
      "Intelligent ligand preparation, minimization, and conformation generation for all applications",
    dashIcon: "/images/dashcard-images/ligand_prep_final.png",
  },
  {
    label: "Molbench",
    link: "molbench",
    description:
      "An interactive editor for creating, editing, and analyzing molecules",
    dashIcon: "/images/new-dashcards-images/molbench_dashcard_moleculegen.png",
    navlabel: "Molbench",
  },
  {
    label: "Lodestar",
    navlabel: "Lodestar",
    link: "lodestar",
    // icon: "/images/icons/ligand-prep.svg",
    description:
      "Intelligent ligand preparation, minimization, and conformation generation for all applications",
    dashIcon: "/images/new-dashcards-images/lodestar_dashcard.png",
  },
];
