import React, { useState, useEffect } from "react";
import {
  DataGridPro,
  getGridNumericOperators,
  GridColDef,
  GridFilterItem,
  GridFilterModel,
  GridFilterOperator,
  GridRowParams,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { TabPanel } from "../../components/common/TabPanel";
import {
  Box,
  Button,
  Card,
  Grid,
  Tab,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";

// import { GridFilterPanelProps } from "@mui/x-data-grid/components/panel/filterPanel/GridFilterPanel";
// import theme from "../../theme/theme";
// import { PropertiesInput } from "../../components";
import {
  GridRenderCellParams,
  GridColumnHeaderParams,
  GridDensity,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { setSingleSmileData } from "./AdmeSlice";
import { admeToxLowerUpperLimit } from "../../components/ADME/predict_vars";
import { ADD_ADME_TOX_CUSTOM_VALUES } from "../../components/ADME/orderArray";
import { getFilterType } from "./admetHelperFuncs";

export type tableStateType = {
  density: GridDensity;
  pageSize: number;
};

// custom toolbar with custom default filters
const CustomToolbar = (props: any) => {
  const { defaultFilterHandler } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        printOptions={{
          disableToolbarButton: true,
        }}
      />
      <Box>
        {/* <Button onClick={defaultFilterHandler}>Default Filters</Button> */}
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          Default Filters
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            left: "5px",
          }}
        >
          <MenuItem onClick={() => defaultFilterHandler(true)}>
            PASSING
          </MenuItem>
          <MenuItem onClick={() => defaultFilterHandler(false)}>
            FAILING
          </MenuItem>
        </Menu>
      </Box>
    </GridToolbarContainer>
  );
};

function ADMEPropertyFilter() {
  const [tabValue, setTabValue] = useState("1");
  const [ro8TableVisible, setRo8TableVisible] = useState(false);
  const [rows, setRows] = useState<[]>();
  const [allColumns, setAllColumns] = useState<GridColDef[]>([]);

  const [densityAndRows, setDensityAndRows] = React.useState<tableStateType>({
    density: "standard",
    pageSize: 5,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let table = useGridApiRef();

  const admeProperties = useSelector(
    (state: any) => state.admeProperty.properties
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const handleRowClick = (
    params: GridRowParams, // GridRowParams
    event: any
  ) => {
    if (!params) return;
    // //console.log("params.row",params.row)
    dispatch(setSingleSmileData(params.row));
    navigate("/adme/admet-pro/evaluations");
  };

  // Custom tooltip text and colors for cell value
  const handleNullValueOfColumn = (
    params: GridRenderCellParams<any, number>
  ) => {
    if (!params.value) {
      return <div>...</div>;
    }

    if (admeToxLowerUpperLimit[params.field]) {
      if (
        admeToxLowerUpperLimit[params.field].upper &&
        params.value > admeToxLowerUpperLimit[params.field].upper
      ) {
        return (
          <Tooltip title="Above Upper Limit" placement="top" arrow>
            <p style={{ color: "red", fontWeight: "bold" }}>
              {params.value.toFixed(3)}
            </p>
          </Tooltip>
        );
      }
      if (
        admeToxLowerUpperLimit[params.field].lower &&
        params.value < admeToxLowerUpperLimit[params.field].lower
      ) {
        return (
          <Tooltip title="Below Lower Limit" placement="top" arrow>
            <p style={{ color: "red", fontWeight: "bold" }}>
              {params.value.toFixed(3)}
            </p>
          </Tooltip>
        );
      }

      if (
        !admeToxLowerUpperLimit[params.field].lower &&
        !admeToxLowerUpperLimit[params.field].upper
      ) {
        return (
          <Tooltip title="" placement="top" arrow>
            <p>{params.value.toFixed(3)}</p>
          </Tooltip>
        );
      }
    }

    return (
      <Tooltip title="Between Lower and Upper Limit" placement="top" arrow>
        <p style={{ color: "green" }}>{params.value.toFixed(3)}</p>
      </Tooltip>
    );
  };

  // Custom tooltip text for header name
  const handleCustomTooltipOfColumn = (params: GridColumnHeaderParams) => {
    let tooltipText = "";
    if (
      !admeToxLowerUpperLimit[params.colDef.field] ||
      !admeToxLowerUpperLimit[params.colDef.field].columnName
    ) {
      tooltipText = params.colDef.headerName;
    } else {
      tooltipText = admeToxLowerUpperLimit[params.colDef.field].columnName;
    }
    return (
      <Tooltip title={tooltipText} arrow>
        <div style={{fontWeight:"550",fontSize:"0.9rem"}}>{params.colDef.headerName}</div>
      </Tooltip>
    );
  };

  // added table state in the localstorage : Density and No. of rows
  const handleTableStateChange = (newState: any) => {
    // extrating existing table state from localstorage
    const densityAndRowsOfTable = JSON.parse(
      localStorage.getItem("admetTableState")
    );
    // newState.pagination.paginationModel.pageSize != 5 &&
    // newState.density.value != "standard" &&

    if (!densityAndRowsOfTable) {
      // if table state is not present in localstorage then will add it.
      const densityAndRowsOfTable: tableStateType = {
        density: "standard",
        pageSize: 5,
      };
      densityAndRowsOfTable.density = newState.density.value;
      densityAndRowsOfTable.pageSize =
        newState.pagination.paginationModel.pageSize;
      localStorage.setItem(
        "admetTableState",
        JSON.stringify(densityAndRowsOfTable)
      );
    } else {
      // If previous table state is present in localstorage then we will update it with new state
      if (
        newState.pagination &&
        newState.pagination.paginationModel.pageSize !==
          densityAndRowsOfTable.pageSize
      ) {
        densityAndRowsOfTable.pageSize =
          newState.pagination.paginationModel.pageSize;
      }
      if (
        newState.pagination &&
        newState.density.value !== densityAndRows.density
      ) {
        densityAndRowsOfTable.density = newState.density.value;
      }
    }

    if (densityAndRowsOfTable) {
      localStorage.setItem(
        "admetTableState",
        JSON.stringify(densityAndRowsOfTable)
      );
    }
  };

  const customOperators: GridFilterOperator[] = [
    ...getGridNumericOperators().filter((op) =>
      ["=", "!=", ">", ">=", "<", "<="].includes(op.value)
    ),
    {
      label: "Pass",
      value: "between",
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        // if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
        //   return null;
        // }
        // if (filterItem.value[0] == null || filterItem.value[1] == null) {
        //   return null;
        // }

        return ({ value }) => {
          if (admeToxLowerUpperLimit[filterItem.field]) {
            const upperValue = admeToxLowerUpperLimit[filterItem.field].upper;
            const lowerValue = admeToxLowerUpperLimit[filterItem.field].lower;

            if (!upperValue && !lowerValue) {
              console.log(value !== null);

              return value !== null;
            } else if (!lowerValue && upperValue) {
              return value !== null && value <= upperValue;
            } else if (!upperValue && lowerValue) {
              return value !== null && value >= lowerValue;
            }

            return value !== null && lowerValue <= value && value <= upperValue;
          }

          return value !== null;
        };
      },
      // InputComponent: InputNumberInterval,
    },
    {
      label: "Fail",
      value: "outOfRange",
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        return ({ value }) => {
          if (admeToxLowerUpperLimit[filterItem.field]) {
            const upperValue = admeToxLowerUpperLimit[filterItem.field].upper;
            const lowerValue = admeToxLowerUpperLimit[filterItem.field].lower;

            if (!upperValue && !lowerValue) {
              return value !== null;
            } else if (!lowerValue && upperValue) {
              return value !== null && value > upperValue;
            } else if (!upperValue && lowerValue) {
              return value !== null && lowerValue > value;
            }

            return value !== null && (lowerValue > value || value > upperValue);
          }

          return value !== null;
        };
      },
      // InputComponent: InputNumberInterval,
    },
  ];

  const numOperators = getGridNumericOperators().filter((op) =>
    ["=", "!=", ">", ">=", "<", "<="].includes(op.value)
  );

  //columns for data grid table
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      type: "number",
      headerAlign: "center",
      align: "center",
      filterOperators: numOperators,
    },
    {
      field: "smiles",

      headerName: "SMILES",
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "img",
      headerName: "2D Representation",
      description: "This column has images and is not sortable.",
      sortable: false,
      filterable: false,
      disableExport: true,
      width: 120,

      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img
          src={`data:image/svg+xml;base64,${btoa(params.value)}`}
          alt="2D svg representation"
          style={{ height: "200px", width: "80%" }}
        />
      ),
    },
    {
      field: "clintox",
      headerName: "Clinical Toxicity",
      type: "number",
      editable: false,
      width: 120,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
    },
    {
      field: "cardio",
      headerName: "Cardiotoxicity",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
      width: 150,
    },
    {
      field: "hepato",
      headerName: "Hepatotoxicity",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
      width: 150,
    },
    {
      field: "respiratory",
      headerName: "Respiratory Toxicity",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      width: 150,
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
    },
    {
      field: "cytotoxicity",
      headerName: "Cytotoxicity",
      type: "number",
      editable: false,
      width: 120,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
    },
    {
      field: "mutagen",
      headerName: "Mutagenicity",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
    },
    {
      field: "carci",
      headerName: "Carcinogenicity",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
      width: 150,
    },
    {
      field: "mouse_oral",
      headerName: "Lethal Dose in Mouse via Oral Route (LD50)",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      width: 200,
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
    },
    {
      field: "caco",
      headerName: "Caco-2 permeability",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
      width: 180,
    },
    {
      field: "hia_hou",
      headerName: "HIA (Human Intestinal Absorption)",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
      width: 170,
    },
    {
      field: "pampa",
      headerName: "PAMPA Permeability",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
      width: 170,
    },
    {
      field: "pgp",
      headerName: "Pgp Inhibition",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
      width: 150,
    },
    {
      field: "solubility",
      headerName: "Aqueous Solubility (LogS)",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
      width: 180,
    },
    {
      field: "lipo",
      headerName: "Lipophilicity (LogD value at pH 7.4)",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
      width: 200,
    },
    {
      field: "bbb",
      headerName: "BBB (Blood-Brain Barrier) Penetration",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
      width: 170,
    },
    {
      field: "CYP1A2_in",
      headerName: "CYP1A2 Inhibitor",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
      width: 150,
    },
    {
      field: "CYP2C19_in",
      headerName: "CYP2C19 Inhibitor",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
      width: 150,
    },
    {
      field: "CYP2C9_sub",
      headerName: "CYP2C9 Substrate",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
      width: 150,
    },
    {
      field: "CYP2C9_in",
      headerName: "CYP2C9 Inhibitor",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
      width: 150,
    },
    {
      field: "CYP2D6_sub",
      headerName: "CYP2D6 Substrate",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
      width: 150,
    },
    {
      field: "CYP2D6_in",
      headerName: "CYP2D6 Inhibitor",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
      width: 150,
    },
    {
      field: "CYP3A4_sub",
      headerName: "CYP3A4 Substrate",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
      width: 150,
    },
    {
      field: "CYP3A4_in",
      headerName: "CYP3A4 Inhibitor",
      type: "number",
      editable: false,
      headerAlign: "center",
      align: "center",
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      filterOperators: customOperators,
      width: 150,
    },
    {
      field: "LOAEL",
      headerName: "Lowest-Observed-Adverse-Effect Level (LOAEL)",
      type: "number",
      editable: false,
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      headerAlign: "center",
      align: "center",
      filterOperators: customOperators,
      width: 170,
    },
    // {
    //   field: "hl",
    //   headerName: "HL",
    //   type: "number",
    //   editable: false,
    //   renderHeader: handleCustomTooltipOfColumn,
    //   renderCell: handleNullValueOfColumn,
    //   headerAlign: "center",
    //   align: "center",
    //   filterOperators: customOperators,
    // },
    // {
    //   field: "hl_log",
    //   headerName: "Half-Life in Hours",
    //   type: "number",
    //   editable: false,
    //   renderHeader: handleCustomTooltipOfColumn,
    //   renderCell: handleNullValueOfColumn,
    //   headerAlign: "center",
    //   align: "center",
    //   filterOperators: customOperators,
    //   width: 150,
    // },
    {
      field: "ppbr",
      headerName: "PPBR (Plasma Protein Binding Rate)",
      type: "number",
      editable: false,
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      headerAlign: "center",
      align: "center",
      filterOperators: customOperators,
      width: 170,
    },
    //  {
    //   field: "vdss",
    //   headerName: "VDSS",
    //   type: "number",
    //   editable: false,
    //   renderHeader: handleCustomTooltipOfColumn,
    //   renderCell: handleNullValueOfColumn,
    //   headerAlign: "center",
    //   align: "center",
    //   filterOperators: customOperators,
    // },
    {
      field: "vdss_log",
      headerName: "VDss (Volume of Distribution at Steady State)",
      type: "number",
      editable: false,
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      headerAlign: "center",
      align: "center",
      filterOperators: customOperators,
      width: 170,
    },
    {
      field: "NR-AR",
      headerName: "NR-AR (Androgen Receptor)",
      type: "number",
      editable: false,
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      headerAlign: "center",
      align: "center",
      filterOperators: customOperators,
      width: 150,
    },
    {
      field: "NR-AR-LBD",
      headerName: "NR-AR-LBD (Androgen Receptor Ligand-Binding Domain)",
      type: "number",
      editable: false,
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      headerAlign: "center",
      align: "center",
      filterOperators: customOperators,
      width: 170,
    },
    {
      field: "NR-AhR",
      headerName: "NR-AhR (Aryl Hydrocarbon Receptor)",
      type: "number",
      editable: false,
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      headerAlign: "center",
      align: "center",
      filterOperators: customOperators,
      width: 170,
    },
    {
      field: "NR-Aromatase",
      headerName: "NR-Aromatase",
      type: "number",
      editable: false,
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      headerAlign: "center",
      align: "center",
      filterOperators: customOperators,
      width: 150,
    },
    {
      field: "NR-ER",
      headerName: "NR-ER (Estrogen Receptor)",
      type: "number",
      editable: false,
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      headerAlign: "center",
      align: "center",
      filterOperators: customOperators,
      width: 150,
    },
    {
      field: "NR-ER-LBD",
      headerName: "NR-ER-LBD (Estrogen Receptor Ligand-Binding Domain)",
      type: "number",
      editable: false,
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      headerAlign: "center",
      align: "center",
      filterOperators: customOperators,
      width: 170,
    },
    {
      field: "NR-PPAR-gamma",
      headerName:
        "NR-PPAR-gamma (Peroxisome Proliferator-Activated Receptor Gamma)",
      type: "number",
      editable: false,
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      headerAlign: "center",
      align: "right",
      filterOperators: customOperators,
      width: 170,
    },
    {
      field: "SR-ARE",
      headerName: "SR-ARE (Antioxidant Response Element)",
      type: "number",
      editable: false,
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      headerAlign: "center",
      align: "center",
      filterOperators: customOperators,
      width: 170,
    },
    {
      field: "SR-ATAD5",
      headerName: "SR-ATAD5",
      type: "number",
      editable: false,
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      headerAlign: "center",
      align: "center",
      filterOperators: customOperators,
    },
    {
      field: "SR-HSE",
      headerName: "SR-HSE (Heat Shock Element)",
      type: "number",
      editable: false,
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      headerAlign: "center",
      align: "center",
      filterOperators: customOperators,
      width: 170,
    },
    {
      field: "SR-MMP",
      headerName: "SR-MMP (Mitochondrial Membrane Potential)",
      type: "number",
      editable: false,
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      headerAlign: "center",
      align: "center",
      filterOperators: customOperators,
      width: 200,
    },
    {
      field: "SR-p53",
      headerName: "SR-p53",
      type: "number",
      editable: false,
      renderHeader: handleCustomTooltipOfColumn,
      renderCell: handleNullValueOfColumn,
      headerAlign: "center",
      align: "center",
      filterOperators: customOperators,
    },
  ];

  // default filter handler for only pass and fail operator for each column
  const defaultFilterHandler = (filterType: boolean) => {
    // console.log("filter type", filterType);
    const filterConf: GridFilterModel = getFilterType(filterType);
    table.current.setFilterModel(filterConf);
  };

  const findKeysType = (obj: any, key: any) => {
    return typeof obj[key];
  };

  useEffect(() => {
    if (
      typeof admeProperties.data != "undefined" &&
      admeProperties.data.results
    ) {
      const modifiedRows = admeProperties.data.results.map(
        (propertyObject: any) => {
          // Define a safe accessor function
          const safeAccess = (obj: any, key: string, defaultValue: any) => {
            return obj && obj[key] !== undefined ? obj[key] : defaultValue;
          };

          return {
            ...propertyObject,
            id: propertyObject.id + 1,
            ppbr: parseFloat(
              ADD_ADME_TOX_CUSTOM_VALUES(
                "ppbr",
                safeAccess(propertyObject, "ppbr", "0").toString(),
                "0"
              )
            ),
            vdss_log: parseFloat(
              ADD_ADME_TOX_CUSTOM_VALUES(
                "vdss_log",
                safeAccess(propertyObject, "vdss_log", "0").toString(),
                "0"
              )
            ),
            hl_log: parseFloat(
              ADD_ADME_TOX_CUSTOM_VALUES(
                "hl_log",
                safeAccess(propertyObject, "hl_log", "0").toString(),
                "0"
              )
            ),
            LOAEL: parseFloat(
              ADD_ADME_TOX_CUSTOM_VALUES(
                "LOAEL",
                safeAccess(propertyObject, "LOAEL", "0").toString(),
                "0"
              )
            ),
            mouse_oral: parseFloat(
              ADD_ADME_TOX_CUSTOM_VALUES(
                "mouse_oral",
                safeAccess(propertyObject, "mouse_oral", "0").toString(),
                safeAccess(propertyObject.properties, "MolWt", "0").toString()
              )
            ),
          };
        }
      );

      const densityAndRowsOfTable = JSON.parse(
        localStorage.getItem("admetTableState")
      );

      if (densityAndRowsOfTable) {
        setDensityAndRows(densityAndRowsOfTable);
      }

      // Get predefined column fields
      const predefinedFields = columns.map((col) => col.field);
      const tobeRemoved = [
        "properties",
        "vdss",
        "clear_hep",
        "clear_hep_log",
        "hl",
        "SMILES"
      ];
      predefinedFields.push(...tobeRemoved);

      // Identify dynamic fields (User Defined Field)
      const dynamicFields = modifiedRows.reduce((fields: any, item: any) => {
        Object.keys(item).forEach((key) => {
          if (!predefinedFields.includes(key) && !fields.includes(key)) {
            fields.push(key);
          }
        });
        return fields;
      }, []);

      // Create dynamic columns
      const dynamicColumns = dynamicFields.map((field: any) => ({
        field,
        headerName: field.toUpperCase(),
        headerAlign: "center",
        align: "center",
        width: 150,
        type: findKeysType(modifiedRows[0], field),
        renderCell: (params: any) => {
          const typeOfValue = typeof params.value;
          if (typeOfValue === "boolean") {
            if (params.value) {
              return (
                <Tooltip title={"TRUE"} placement="top" arrow>
                  <div>TRUE</div>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip title={"FALSE"} placement="top" arrow>
                  <div>TRUE</div>
                </Tooltip>
              );
            }
          } else if (typeOfValue === "number") {
            return (
              <Tooltip title={params.value} placement="top" arrow>
                <div>{params.value.toFixed(3)}</div>
              </Tooltip>
            );
          }

          return (
            <Tooltip title={params.value} placement="top" arrow>
              <div>{params.value}</div>
            </Tooltip>
          );
        },
      }));

      // console.log('dynamic columns',dynamicColumns);

      const isNameExist = dynamicColumns.filter(
        (col: any) => col.field === "name"
      );
      columns.splice(1, 0, ...isNameExist);

      const withoutName = dynamicColumns.filter(
        (col: any) => col.field !== "name"
      );
      columns.push(...withoutName);

      setAllColumns(columns);
      setRows(modifiedRows);
      setRo8TableVisible(true);
    } else if (
      (typeof admeProperties.data == "undefined" ||
        typeof admeProperties.data.results == "undefined") &&
      !admeProperties.loading
    ) {
      navigate("/adme");
    }
  }, [admeProperties]);

  return (
    <>
      <Grid container spacing={2} p={2} height={"95%"}>
        <Grid item container px={0} pt={3} height={"100%"}>
          <Grid item width={"100%"} sx={{ overflowY: "auto" }} height={"100%"}>
            <Card sx={{ position: "relative" }}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="filter tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label="Predictions" value="1" />
                  </TabList>
                </Box>
                {/* Predictions */}
                <TabPanel value="1">
                  {ro8TableVisible && (
                    <Box sx={{ width: "100%", height: "700px" }}>
                      <DataGridPro
                        apiRef={table}
                        sx={{
                          "& .MuiDataGrid-columnHeaderTitle": {
                            whiteSpace: "normal",
                            lineHeight: "normal",
                            fontWeight: "550", // Force font weight to bold
                            fontSize: "0.9rem",
                          },
                          "& .MuiDataGrid-columnHeader": {
                            // Forced to use important since overriding inline styles
                            height: "unset !important",
                          },
                          "& .MuiDataGrid-columnHeaders": {
                            // Forced to use important since overriding inline styles
                            maxHeight: "175px !important",
                            textAlign: "center",
                          },
                        }}
                        rows={rows}
                        columns={allColumns}
                        onRowClick={handleRowClick}
                        onStateChange={handleTableStateChange}
                        rowHeight={100}
                        pagination
                        density={
                          densityAndRows.density
                            ? densityAndRows.density
                            : "standard"
                        }
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: densityAndRows.pageSize
                                ? densityAndRows.pageSize
                                : 5,
                            },
                          },
                          // filter: {
                          //   filterModel: {
                          //     items: [
                          //       {
                          //         field: "clintox",
                          //         operator: ">",
                          //         value: "2.5",
                          //       },
                          //     ],
                          //   },
                          // },

                          // pinnedColumns: { left: ["id", "smiles"] },
                        }}
                        pageSizeOptions={[5, 20,30]}
                        // autoHeight
                        // slots={{ toolbar: GridToolbar }}

                        slots={{
                          toolbar: (props) => (
                            <CustomToolbar
                              defaultFilterHandler={defaultFilterHandler}
                              {...props}
                            />
                          ),
                        }}

                        // slotProps={{
                        //   toolbar: {
                        //     printOptions: { disableToolbarButton: true },
                        //   },
                        // }}
                      />
                    </Box>
                  )}
                </TabPanel>
              </TabContext>
            </Card>
          </Grid>
        </Grid>
        <IndefiniteLoader state={admeProperties.loading} />
      </Grid>
    </>
  );
}

export default ADMEPropertyFilter;
