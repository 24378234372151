interface GenieCardsTypes {
  label: string;
  link: string;
  description: string;
  dashIcon: string;
  navlabel: string;
}

export const genieCards: GenieCardsTypes[] = [
  {
    label: "MAIMol",
    link: "maimol",
    description: "",
    dashIcon: "images/new-dashcards-images/maimol_dashcard_moleculegen.png",
    navlabel: "MAIMol",
  },
  {
    label: "Ranker",
    link: "ranker",
    description:
      "Efficient Docking with ADMET and screenie Filtering and MAIMol Integration",
    dashIcon: "images/new-dashcards-images/ranker_dashcard_moleculegen.png",
    navlabel: "Ranker",
  },
];
