interface MoleculeDesignCardsTypes {
  label: string;
  link: string;
  description: string;
  dashIcon: string;
  navlabel: string;
}

export const moleculeDesignCards: MoleculeDesignCardsTypes[] = [
  {
    label: "Molecule Copilot",
    link: "copilot",
    description:
      "Optimize molecules with interactive natural language feedback",
    dashIcon: "images/new-dashcards-images/copilot_dashcard2_moleculegen.png",
    navlabel: "Molecule CoPilot",
  },
  {
    label: "MolBench",
    link: "molbench",
    description:
      "An interactive editor for creating, editing, and analyzing molecules",
    dashIcon: "/images/new-dashcards-images/molbench_dashcard_moleculegen.png",
    navlabel: "MolBench",
  },
];
