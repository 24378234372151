import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  TextField,
  Typography,
  Alert,
  Snackbar,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchProperties,
  selectProperties,
  setProperties,
} from "../../views/Properties/propertiesSlice";

import {
  fetchPropertiesV2,
  selectPropertiesV2,
  fetchMedchemFilters,
  fetchMaiFilters,
  fetchToxicFilters,
  fetchAllRowsFilters,
} from "../../views/Properties/propertiesSliceV2";
import { fetchPropertiesV3 } from "../../views/Properties/propertiesSmilesStringSliceV3";
import FileUploader from "../common/FileUploader";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import IndefiniteLoader from "../common/IndefiniteLoader";
import BetaModal from "../common/BetaModal";
import { JOB_SERVER_URL, RUNTIME } from "../../config";

import { useLocation } from "react-router-dom";
import Papa from "papaparse";
import { setScreenieRequirements } from "../../views/Properties/propertiesHelper";
import { useUserAuth } from "../../context";
import http from "../../net/http-common";

function PropertiesInput({
  inFilterPage,
  handleCloseModal,
  setDoLoadJobs,
  setShowSubmitMsgFromHome,
  setJobID,
}: {
  inFilterPage: boolean;
  handleCloseModal?: () => void;
  setDoLoadJobs?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSubmitMsgFromHome?: React.Dispatch<React.SetStateAction<boolean>>;
  setJobID?: React.Dispatch<any>;
}) {
  const [smilesFile, setSmilesFile] = useState<any>("");
  const [SMILESFile, setSMILESFile] = useState<File>(null);
  const [smilesFileToGetSmileArray, setSmilesFileToGetSmileArray] =
    useState<any>("");
  const [smiles, setSmiles] = useState("");
  const from_model = false;
  const [model_name, setmodel_name] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [job_id, setJob_id] = useState("");

  const [headerName, setheaderName] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [showError, setShowError] = useState(false);
  const [betaOpen, setBetaOpen] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [submittedSmiles, setSubmittedSmiles] = useState<string[]>([]);
  const [showSubmitMsg, SetShowSubmitMsg] = useState(false);

  const state = useLocation();

  const runInProd = RUNTIME === "PROD";

  const { user } = useUserAuth();
  const dispatch = useAppDispatch();
  const { properties } = useAppSelector(selectPropertiesV2);

  const singleSmileScreenieV3 = useAppSelector(
    (state) => state.propertiesV3.properties
  );

  const singleSmileForBridge = useAppSelector(
    (state) => state.admeProperty.properties.singleSmileForBridge
  );

  const navigate = useNavigate();

  // function to delete file
  const deleteHandlerDisable = () => {
    setSMILESFile(null);
  };

  const getAsBase64 = (files: FileList) => {
    // console.log("files are");

    setSmilesFile(files);
    setSMILESFile(files[0]);
    const smilesFile: File = files[0];
    setSmilesFileToGetSmileArray(files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(smilesFile);

    reader.onload = () => {
      setSmilesFile(reader.result.toString());
    };
  };

  const handleSubmit = async (e: any) => {
    // removed old code, 23 May 2024
    setInProgress(true);

    SetShowSubmitMsg(false);
    if (!smilesFile && !smiles) {
      alert("No file uploaded");
      setInProgress(false);
      return;
    }

    if (smiles) {
      // submit a single smile string to API if SMILES input exists, else submit as job
      dispatch(
        fetchPropertiesV3({
          smiles: smiles,
        })
      )
        .then((res) => {
          const result = res as any;

          if (!result.payload) {
            throw new Error("Invalid Smiles, Please Add correct Smiles");
          }

          if (result.payload.error) {
            if (handleCloseModal) handleCloseModal();
            setShowError(true);
            setErrorMsg(errorMessages.submitInputError);
          } else {
            if (handleCloseModal) handleCloseModal();
          }
        })
        .then(() => {
          setScreenieRequirements("tab", "1");
          if (!inFilterPage) navigate("evaluations");
        })
        .catch((error) => {
          if (error.message === "Invalid Smiles, Please Add correct Smiles")
            setErrorMsg(error.message);
          else setErrorMsg(errorMessages.submitInputError);

          setShowError(true);
          setInProgress(false);
        });
    } else {
      const data = new FormData();
      // do validation

      data.append("uid", user.uid);
      data.append("smiles_file", SMILESFile);
      data.append("uid", user.uid);
      data.append("smiles_column", headerName);
      http
        .post(`${JOB_SERVER_URL}/screenie/submit`, data, {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "http://localhost:3000",
          },
        })
        .then((response: any) => {
          setInProgress(false);
          setJob_id(response.data.task_id);
          setJobID(response.data.task_id);
          setDoLoadJobs(true);
          SetShowSubmitMsg(true);
          setShowSubmitMsgFromHome(true);
        })
        .catch((error) => {
          setInProgress(false);
          setShowError(true);
          setErrorMsg(errorMessages.submitJobError);
        });
    }
  };

  useEffect(() => {
    // Disable submit button if the 'smiles' state is empty
    setSubmitButtonDisabled(!smiles && !smilesFile);
  }, [smiles, smilesFile]);

  const onBetaClick = () => {
    setBetaOpen(true);
  };

  // when headerName changed : We can get all the smiles values from that column
  useEffect(() => {
    // console.log(smilesFileToGetSmileArray);

    if (smilesFileToGetSmileArray) {
      Papa.parse(smilesFileToGetSmileArray, {
        header: true,
        dynamicTyping: true,
        complete: (result) => {
          if (result.data.length > 0) {
            let smilesArray = result.data
              .map((row: any) => {
                return row[headerName];
              })
              .filter((value: any) => value !== null && value !== undefined);
            setSubmittedSmiles(smilesArray);
            // console.log("all smiles", smilesArray);
          }
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
        },
      });
    }
  }, [headerName]);

  useEffect(() => {
    // console.log(properties);
    // if (!properties.data) return
    // if(inFilterPage) return;
  }, [properties.data]);

  useEffect(() => {
    if (singleSmileForBridge) {
      setSmiles(singleSmileForBridge);

      const path: string = window.location.pathname;
      if (path.includes("v3")) {
        dispatch(
          fetchPropertiesV3({
            smiles: singleSmileForBridge,
          })
        )
          .then((res) => {
            const result = res as any;
            // //console.log(result);
            if (result.payload.error) {
              if (handleCloseModal) handleCloseModal();
              setShowError(true);
              setErrorMsg(errorMessages.submitInputError);
            } else {
              if (handleCloseModal) handleCloseModal();
              // if (!inFilterPage) navigate("filter");
            }
          })
          .catch((error) => {
            setShowError(true);
            setErrorMsg(errorMessages.submitInputError);
          });
      } else {
        dispatch(
          fetchProperties({
            smiles,
            smilesFile,
            from_model,
            model_name,
            job_id,
          })
        )
          .then(() => {
            if (handleCloseModal) handleCloseModal();

            if (!inFilterPage) navigate("filter");
          })
          .catch((error) => {
            setShowError(true);
            setErrorMsg(errorMessages.submitInputError);
          });
      }
      // console.log("Admet to screenie",state.state.smileName)

      setTimeout(() => {
        navigate("/virtual-screening/properties-v3/evaluations");
      }, 1800);
      window.history.replaceState({}, "");
    }
  }, []);

  const handleDownloadSample = () => {
    const link = document.createElement("a");
    link.download = "input_screenie.csv";

    // 👇️ set to relative path
    link.href = "/samples/screenie/input_screenie.csv";

    link.click();
  };

  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Card sx={{ height: 1, width: "100%" }}>
        <Grid item px={2} py={1.3} sx={{ backgroundColor: "grey.100" }}>
          <i className="ri-login-box-line"></i>
          <Typography
            display={"inline-block"}
            variant={"h6"}
            sx={{ color: "grey.900" }}
            ml={0.5}
            fontWeight={"600"}
          >
            {"Input"}
          </Typography>
        </Grid>
        {runInProd && (
          <Grid container direction="column" className="beta-anchor">
            <Grid item xs={12} sx={{ mt: 5 }}>
              <Typography>
                This is a Premium feature. Try with{" "}
                <Button variant="contained" onClick={onBetaClick}>
                  BETA ACCESS
                </Button>
                <BetaModal
                  open={betaOpen}
                  openHandler={setBetaOpen}
                ></BetaModal>
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          className={RUNTIME === "PROD" ? "blurry-bg" : ""}
          display="flex"
          flexDirection="column"
        >
          <CardContent>
            <Box>
              <Stack direction="column" spacing={1.5}>
                <Typography
                  sx={{
                    mt: 1,
                    color: "var(--shade-2900, #29283B)",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  SMILES File (.csv)
                  <Button variant="text" onClick={handleDownloadSample}>
                    {"Download Sample"}
                  </Button>
                </Typography>

                <FileUploader
                  accept=".csv"
                  handleFileUpload={getAsBase64}
                  headerSelector={true}
                  handleSelectedHeader={setheaderName}
                  deleteHandlerDisable={deleteHandlerDisable}
                  isDisabled={!!smiles}
                />
              </Stack>
            </Box>

            <Typography textAlign={"center"} my={1.5}>
              -- OR --
            </Typography>

            <Typography component="label" htmlFor="smiles-string">
              Enter a SMILES string
            </Typography>
            <TextField
              placeholder="SMILES string"
              fullWidth
              id="smiles-string"
              name="smiles-string"
              value={smiles}
              onChange={(e) => {
                setSmiles(e.target.value);
                if (!smilesFile) {
                  let singleSmileValue: string[] = [];
                  singleSmileValue.push(e.target.value);
                  setSubmittedSmiles(singleSmileValue);
                }
              }}
              disabled={!!smilesFile}
            />

            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                width: "100%",
                mt: 2,
                "&.Mui-disabled": {
                  background: "#BDADF9",
                  color: "#fff",
                },
              }}
              disabled={submitButtonDisabled}
            >
              Submit
            </Button>
          </CardContent>
        </Grid>
        <IndefiniteLoader state={inProgress || singleSmileScreenieV3.loading} />
      </Card>
    </>
  );
}

export default PropertiesInput;
