import { useEffect, useState } from "react";
import { JobsTable, WorkflowLandingTabs } from "../../components";
import { WorkflowLandingTab } from "../../models";
import http from "../../net/http-common";
import {
  Grid,
  Typography,
  Box,
  Snackbar,
  Alert,
  Card,
  CardContent,
} from "@mui/material";
import Loaders from "../../components/common/Loaders";

import { JOB_SERVER_URL } from "../../config";

import { useUserAuth } from "../../context";
import { errorMessages } from "../../common_variables/ErrorMsgs";
import LiftJobInput from "./LiftJobInput";

const tabs: Array<WorkflowLandingTab> = [
  {
    id: "0",
    label: "Description",
    value: (
      <>
        <Typography>
          The lift filter computes the interactions for a given set of ligands
          SMILES and a protein structure. These ligands can be filtered on the
          basis of key interactions to streamline the screening process by
          selecting ligands that exhibit strong interactions with the target
          protein.
        </Typography>
        <Typography>
          The protein and ligands are docked using the UniDock tool prior to
          computing the non-covalent interactions.
        </Typography>
        <Box
          component="img"
          src="/images/lift_filter_logo.png"
          alt="Protein Info Visual"
          sx={{
            width: "70%", // Reduces the width to 80% of the parent container
            height: "auto",
            mt: 4, // Adds margin-top for space above the image
            mb: 2, // Adds margin-bottom for space below the image
            mx: "auto", // Centers the image horizontally
            display: "block", // Ensures the Box behaves like a block element
            p: 2, // Adds padding around the image inside the Box
          }}
        />
      </>
    ),
  },
];

function LiftFilterMolecules() {
  const MODEL_NAME = "lift";
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const [showSubmitMsg, SetShowSubmitMsg] = useState(false);
  const [jobID, setJobID] = useState(-1);
  const [doLoadJobs, setDoLoadJobs] = useState(true);
  const [rows, setRows] = useState<[]>();
  const { user } = useUserAuth();

  useEffect(() => {
    if (!doLoadJobs || !user) return;

    http
      .get(`${JOB_SERVER_URL}/userjobs`, {
        params: {
          user_id: user.uid,
          model_name: MODEL_NAME,
          start: "0",
          end: "10",
        },
        headers: {
          accept: "application/json",
        },
      })
      .then((res) => {
        setDoLoadJobs(false);
        setRows(res.data);
      })
      .catch((error) => {
        setShowError(true);
        setErrorMsg(errorMessages.jobTableError);
        setDoLoadJobs(false);
        setRows([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doLoadJobs]);
  return (
    <>
      <Snackbar
        open={showError}
        autoHideDuration={9000}
        sx={{ width: "50%" }}
        onClose={() => {
          setShowError(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setShowError(false);
          }}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
      <Grid px={3} container spacing={2.5} mt={-1}>
        <Grid item sm={12} md={8} sx={{ width: "100%" }} display={"flex"}>
          <WorkflowLandingTabs tabs={tabs} />
        </Grid>
        <Grid item sm={12} md={4} sx={{ width: "100%" }} display={"flex"}>
          <Card sx={{ width: "100%" }}>
            <LiftJobInput
              setJobID={setJobID}
              SetShowSubmitMsg={SetShowSubmitMsg}
              setDoLoadJobs={setDoLoadJobs}
            />
          </Card>
        </Grid>
      </Grid>

      <Grid sx={{ width: "50%", mx: "auto" }}>
        {showSubmitMsg && (
          <Alert
            onClose={() => {
              SetShowSubmitMsg(false);
            }}
            sx={{ mt: 2 }}
            variant="filled"
            severity="success"
          >{`Job submitted with id ${jobID}`}</Alert>
        )}
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className="jobs-container"
        mt={2}
        px={3}
      >
        <Card sx={{ width: 1 }}>
          <CardContent>
            {doLoadJobs && <Loaders type={"table"} />}
            {!doLoadJobs && (
              <JobsTable
                jobs={rows}
                setDoLoadJobs={setDoLoadJobs}
                nextUrl="visualize"
                setErrorMsg={setErrorMsg}
                setShowError={setShowError}
                // disableVisualize={true}
              ></JobsTable>
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default LiftFilterMolecules;
