import {
    Box,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Button
} from "@mui/material";
import { removeSession } from "../../views/Copilot/copilotApi";

interface CoPilotPopUpAlertProps {
    textPrimary: string;
    textSecondary: string;
    textAlertDespriction: string;
    textAction: string;
    openDeleteDialog: boolean;
    setOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>;
    actionId: number
    actionParams?: any
    onActionCompleted: () => void
    onActionFailed: () => void
}

const CoPilotPopUpAlert = ({
    textPrimary,
    textSecondary,
    textAlertDespriction,
    textAction,
    openDeleteDialog,
    setOpenDeleteDialog,
    actionId,
    actionParams,
    onActionCompleted,
    onActionFailed,
}:
    CoPilotPopUpAlertProps
) => {

    const handleAction = async () => {
        try {
            switch (actionId) {
                case 1: {
                    if (actionParams) {
                        await removeSession(actionParams.sessionId);
                        setOpenDeleteDialog(false);
                        await onActionCompleted();
                    }
                    break;
                }
                default:
                    console.warn("No action defined for this actionId");
            }
        } catch (error) {
            console.error("Action failed", error);
            onActionFailed();
        }
    };

    return (
        <Dialog
            open={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent
                sx={{
                    textAlign: "left",
                }}
            >
                <Box
                    sx={{
                        p: 4,
                    }}
                >
                    <Typography variant="h5">
                        {textPrimary}
                    </Typography>
                    {textSecondary && (
                        <Typography color={"Highlight"} fontWeight={"bold"}>
                            {textSecondary}
                        </Typography>
                    )}
                    <Typography color={"error"} fontWeight={"bold"}>
                        {textAlertDespriction}
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => setOpenDeleteDialog(false)}>
                    <Typography variant="h6">Later</Typography>
                </Button>

                <Button
                    // variant="contained"
                    autoFocus
                    color="error"
                    onClick={handleAction}
                >
                    {textAction}
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default CoPilotPopUpAlert;