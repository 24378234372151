import {
    Autocomplete,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
  } from "@mui/material";
  import { useEffect, useState } from "react";
  import { interactionTypesList } from "../LiftFilter/HelperModule";
  import CancelIcon from "@mui/icons-material/Cancel";
  
  const  InputModal = ({
    openSearchInput,
    setOpenSearchInput,
    allListedInteractions,
    setSelectedInteractions,
  }: {
    openSearchInput: boolean;
    setOpenSearchInput: React.Dispatch<React.SetStateAction<boolean>>;
    allListedInteractions: any[];
    setSelectedInteractions: React.Dispatch<React.SetStateAction<any[]>>;
  }) => {
    // inputs and states for the custom input interactions
    const [residue, setResidue] = useState<string>("");
    const [residueNumber, setResidueNumber] = useState<number | null>(null);
    const [chain, setChain] = useState<string>("");
///

    // all selected interaction (list + custom)
    let allInteraction : string[] = [];
    let lsInteraction  = localStorage.getItem("selectedInteractions");
    if (lsInteraction) {
      allInteraction = JSON.parse(lsInteraction) || [];
    }

    // custom interaction
    let lsCustomInteraction : string[] = [];
    lsInteraction  = localStorage.getItem("rankerCustomInteractions");
    if (lsInteraction) {
      lsCustomInteraction = JSON.parse(lsInteraction) || [];
    }

    let dropdownOnlyInteractions: string[] = allInteraction.filter(
      (interaction: string) =>
        !lsCustomInteraction.some(
          (manual: string) =>
            JSON.stringify(manual) === JSON.stringify(interaction)
        )
    );

    // dropdwon interaction 



////

    const [interactionTypes, setInteractionTypes] =
      useState<string[]>(interactionTypesList);
    const [selectedInteractionType, setSelectedInteractionType] =
      useState<string>(interactionTypesList[0]);
    const [customInteractions, setCustomInteractions] = 
      useState<string[]>(JSON.parse(JSON.stringify(lsCustomInteraction)));
  
    // inputs and states for the listed interactions
    const [selectedInteractionsFromList, setSelectedInteractionsFromList] =
      useState<string[]>(JSON.parse(JSON.stringify(dropdownOnlyInteractions)));

    // const [customInteractions, setCustomInteractions] = useState<string[]>([]);
  
    // // inputs and states for the listed interactions
    // const [selectedInteractionsFromList, setSelectedInteractionsFromList] =
    //   useState<string[]>([]);
  
    // Function to add new interactions by concatinating
    const addCustomInteractions = () => {
      // concatenating a new interaction with the given values
      let concatenatedInteraction =
        residue +
        "_" +
        residueNumber +
        "_" +
        chain +
        "_" +
        selectedInteractionType;
  
      if (customInteractions.includes(concatenatedInteraction)) {
        return;
      }
  
      setCustomInteractions([...customInteractions, concatenatedInteraction]);
      // set in localstorage
      // const savedInteractions = localStorage.getItem("selectedInteractions");
    };

    useEffect(() => {
      localStorage.setItem(
        "rankerCustomInteractions",
        JSON.stringify(customInteractions)
      );
    }, [customInteractions]);
  
    // Function to remove a interaction from custom interaction list
    const removeCustomInteractions = (interaction: string) => {
      const newCustomInteractions = customInteractions.filter(
        (item) => item !== interaction
      );
      setCustomInteractions(newCustomInteractions);
    };
  
    //
    const setchoosedInteractionFromList = (event: any, newValue: any) => {
      setSelectedInteractionsFromList(newValue); // Update the selected options
    };
  
    // Function to filter the rows based on selected all the interactions (listed interactions + custom interactions)
    const filterRowsHandler = () => {
      let allEnteredInteractions: string[] = [
        ...customInteractions,
        ...selectedInteractionsFromList,
      ];

      // remove any duplicate interaction(s)
      allEnteredInteractions = [...new Set(allEnteredInteractions)];

      setResidue("");
      setResidueNumber(null);
      setChain("");
      setSelectedInteractions(allEnteredInteractions);
      setOpenSearchInput(false);
    };
  
    // Reset all the Interaction Filter
    const resetInteractionFilter = () => {
      setResidue("");
      setResidueNumber(null);
      setChain("");
      setSelectedInteractionType(interactionTypesList[0]);
      setSelectedInteractionsFromList([]);
      setCustomInteractions([]);
      setSelectedInteractions([]);
      setOpenSearchInput(false);
    };
  
    return (
      <Dialog
        open={openSearchInput}
        onClose={() => setOpenSearchInput(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .css-1q3t5pl-MuiPaper-root-MuiDialog-paper": {
            width: "700px",
            maxWidth: "1100px",
          },
          "& .css-esw9ho": {
            width: "700px",
            maxWidth: "1100px !important",
          },
        }}
      >
        <Box textAlign={"right"}>
          <IconButton onClick={() => setOpenSearchInput(false)}>
            <CancelIcon />
          </IconButton>
        </Box>
  
        <DialogTitle id="alert-dialog-title" textAlign={"center"}>
          Select Interactions
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <Stack direction="column" spacing={1.5}>
              <FormControl>
                <Typography
                  sx={{
                    mt: 1,
                    color: "var(--shade-2900, #29283B)",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Interactions
                </Typography>
                <Box>
                  <Autocomplete
                    sx={{ mt: 2 }}
                    multiple
                    options={allListedInteractions} // Custom string array for options
                    value={selectedInteractionsFromList}
                    freeSolo
                    onChange={setchoosedInteractionFromList}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select Interactions"
                      />
                    )}
                  />
                </Box>
              </FormControl>
            </Stack>
  
            <Typography textAlign={"center"} my={1} mt={2}>
              -- OR --
            </Typography>
  
            <Stack direction="column" spacing={1.5}>
              <FormControl>
                <Typography
                  sx={{
                    mt: 1,
                    color: "var(--shade-2900, #29283B)",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Interaction Type
                </Typography>
                <Select
                  value={selectedInteractionType}
                  label="Interaction Type"
                  onChange={(e) => setSelectedInteractionType(e.target.value)}
                >
                  {interactionTypes?.map((v, i) => {
                    return (
                      <MenuItem key={i} value={v}>
                        {v}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
  
            <Stack direction="column" spacing={1.5}>
              <FormControl>
                <Typography
                  sx={{
                    mt: 1,
                    color: "var(--shade-2900, #29283B)",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Residue Number
                </Typography>
                <Box>
                  <TextField
                    type="number"
                    fullWidth
                    value={residueNumber}
                    onChange={(e) => setResidueNumber(Number(e.target.value))}
                  />
                </Box>
              </FormControl>
            </Stack>
  
            <Stack direction="column" spacing={1.5}>
              <FormControl>
                <Typography
                  sx={{
                    mt: 1,
                    color: "var(--shade-2900, #29283B)",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Residue
                </Typography>
                <Box>
                  <TextField
                    fullWidth
                    value={residue}
                    onChange={(e) => setResidue((e.target.value).toUpperCase())}
                  />
                </Box>
              </FormControl>
            </Stack>
  
            <Stack direction="column" spacing={1.5} mb={2}>
              <FormControl>
                <Typography
                  sx={{
                    mt: 1,
                    color: "var(--shade-2900, #29283B)",
                    fontSize: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  Chain
                </Typography>
                <Box>
                  <TextField
                    fullWidth
                    value={chain}
                    onChange={(e) => setChain((e.target.value).toUpperCase())}
                  />
                </Box>
              </FormControl>
            </Stack>
  
            <Button
              autoFocus
              variant="contained"
              size="small"
              disabled={
                !selectedInteractionType || !residue || !chain || !residueNumber
              }
              onClick={addCustomInteractions}
            >
              Add
            </Button>
          </FormControl>
  
          <Box>
            {customInteractions.map((item, index) => (
              <Chip
                sx={{ m: 1 }}
                key={index}
                label={item}
                onDelete={() => removeCustomInteractions(item)}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetInteractionFilter}>Reset</Button>
          <Button
            autoFocus
            variant="contained"
            size="small"
            disabled={
              selectedInteractionsFromList.length === 0 &&
              customInteractions.length === 0
            }
            onClick={filterRowsHandler}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default InputModal;
  