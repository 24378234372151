import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Paper,
  Button,
  Typography,
  TextField,
  Card,
  CardContent,
  Link,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import useTheme from "@mui/system/useTheme";

import ToxicityTable from "../../components/ADME/ToxicityTable";
import ADMETable from "../../components/ADME/ADMETable";

import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { mkConfig, generateCsv, download } from "export-to-csv";
import Chart from "chart.js/auto";
import IndefiniteLoader from "../../components/common/IndefiniteLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setSingleSimleForBridge,
  setSingleSmileData,
  setSingleSmileDataFromADMEPage,
} from "./AdmeSlice";
import { admeToxLowerUpperLimit } from "../../components/ADME/predict_vars";
import {
  ADME_TOX_ORDER,
  ADME_TOX_TOOLTIP,
  ADD_ADME_TOX_CUSTOM_VALUES,
  ADME_TOX_CUSTOM_ENDPOINTS,
} from "../../components/ADME/orderArray";
import { setSinglesmileData } from "../Properties/propertiesSliceV2";
import ExploreIcon from "@mui/icons-material/Explore";
import { ADMET_REST_URL } from "../../config";

const ADMEEvaluations: React.FC = () => {
  const theme = useTheme();
  const [smiles, setSmiles] = useState(null);
  const [finalSmile, setFinalSmile] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);

  const location = useLocation();

  const navigate = useNavigate();

  const admeProperties = useSelector(
    (state: any) => state.admeProperty.properties.singleSmileData
  );
  const smileInfo = useSelector(
    (state: any) => state.admeProperty.properties.dataOnSingleSmile.data
  );

  const dispatch = useDispatch();

  interface Item {
    apiName: string;
    title: string;
    value: string;
    color: string;
    info: string;
    lowerValue: number | null;
    upperValue: number | null;
    toolTipText: string;
    toolTipColor: string;
  }

  interface Item2 {
    title: string;
    value: boolean;
    details: string[];
    info: string;
  }

  interface Category {
    heading: string;
    data: Item[];
  }
  interface Category2 {
    heading: string;
    data: Item2[];
  }
  const MED_CHEM_DATA: Category2[] = [
    {
      heading: "Medicinal Chemistry",
      data: [],
    },
  ];
  const ADME_DATA: Category[] = [
    { heading: "Absorption", data: [] },
    { heading: "Distribution", data: [] },
    { heading: "Metabolism", data: [] },
    { heading: "Excretion", data: [] },
  ];

  const TOXICITY_DATA: Category[] = [{ heading: "Toxicity", data: [] }];

  const [adme, setADME] = useState(ADME_DATA);
  const [tox, setTox] = useState(TOXICITY_DATA);
  const [filterData, setFilterData] = useState(MED_CHEM_DATA);
  const [radarValData, setRadarValData] = useState([]);

  const modelMap: Record<string, string> = {
    CYP1A2_in: "Metabolism",
    CYP2C19_in: "Metabolism",
    CYP2C9_in: "Metabolism",
    CYP2D6_in: "Metabolism",
    CYP3A4_in: "Metabolism",
    CYP2C9_sub: "Metabolism",
    CYP2D6_sub: "Metabolism",
    CYP3A4_sub: "Metabolism",
    bbb: "Distribution",
    caco: "Absorption",
    carci: "Toxicity",
    cardio: "Toxicity",
    clear_hep: "Excretion",
    clintox: "Toxicity",
    cytotoxicity: "Toxicity",
    hepato: "Toxicity",
    hia_hou: "Absorption",
    // hl: "Excretion",
    lipo: "Absorption",
    mouse_oral: "Toxicity",
    mutagen: "Toxicity",
    pampa: "Absorption",
    pgp: "Absorption",
    respiratory: "Toxicity",
    solubility: "Absorption",
    "NR-AR": "Toxicity",
    "NR-AR-LBD": "Toxicity",
    "NR-AhR": "Toxicity",
    "NR-Aromatase": "Toxicity",
    "NR-ER": "Toxicity",
    "NR-ER-LBD": "Toxicity",
    "NR-PPAR-gamma": "Toxicity",
    "SR-ARE": "Toxicity",
    "SR-ATAD5": "Toxicity",
    "SR-HSE": "Toxicity",
    "SR-MMP": "Toxicity",
    "SR-p53": "Toxicity",
    LOAEL: "Toxicity",
    clear_hep_log: "Excretion",
    hl_log: "Excretion",
    ppbr: "Distribution",
    vdss: "Distribution",
    vdss_log: "Distribution",
  };

  // Properties to render on spider chart
  const Predict_vars = [
    "clintox",
    "cardio",
    "hepato",
    "respiratory",
    "cytotoxicity",
    "mutagen",
    "carci",
    // "mouse_oral",
    // "caco",
    // "pampa",
    // "pgp",
    // "solubility",
    "lipo",
    // "bbb",
    "CYP1A2_in",
    "CYP3A4_in",
    // "hl",
    "NR-AR",
    "NR-AR-LBD",
    "NR-AhR",
    "NR-Aromatase",
    "NR-ER",
    "NR-ER-LBD",
    "NR-PPAR-gamma",
    "SR-ARE",
    "SR-ATAD5",
    "SR-HSE",
    "SR-MMP",
    "SR-p53",
    // "LOAEL",
    "ppbr",
  ];

  // Lower value of spider chart properties
  const Predict_vars_lower = Predict_vars.map(
    (item) =>
      admeToxLowerUpperLimit[item].lower / admeToxLowerUpperLimit[item].upper
  );
  // Upper value of spider chart properties
  const Predict_vars_upper = Predict_vars.map(
    (item) =>
      admeToxLowerUpperLimit[item].upper / admeToxLowerUpperLimit[item].upper
  );

  interface MolInfo {
    SMILE: string;
    IMAGE1: string;
    IMAGE2: string;
    description: string;
  }

  const MOL_INFO: MolInfo = {
    SMILE: finalSmile,
    IMAGE1: "https://source.unsplash.com/user/c_v_r/1900x300",
    IMAGE2: "https://source.unsplash.com/user/c_v_r/1910x1300",
    description:
      "For the classification endpoints , the prediction probability values are transformed into six symbols: 0-0.1(---), 0.1-0.3(--), 0.3-0.5(-), 0.5-0.7(+), 0.7-0.9(++), and 0.9-1.0(+++).",
  };

  Chart.defaults.font.size = 15;
  const radarData = {
    labels: Predict_vars,
    // "hia_hou", "clear_hep",
    datasets: [
      {
        label: "Lower Limit",
        data: Predict_vars_lower,
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgb(255, 99, 132)",
        pointBackgroundColor: "rgb(255, 99, 132)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(255, 99, 132)",
      },
      {
        label: "Value",
        data: radarValData,
        fill: true,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgb(75, 192, 192)",
        pointBackgroundColor: "rgb(75, 192, 192)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(75, 192, 192)",
      },
      {
        label: "Upper Limit",
        data: Predict_vars_upper,
        fill: true,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgb(54, 162, 235)",
        pointBackgroundColor: "rgb(54, 162, 235)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(54, 162, 235)",
      },
    ],
  };

  const chartRef = useRef(null);

  const exportCSV = async () => {
    const csvConfig = mkConfig({ useKeysAsHeaders: true });

    const csvData = [];
    csvData.push({ Name: "SMILES", Value: finalSmile });
    for (let index = 0; index < Predict_vars.length; index++) {
      csvData.push({
        Name: Predict_vars[index],
        Value: radarValData[index],
      });
    }
    // Converts your Array<Object> to a CsvOutput string based on the configs
    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig)(csv);
  };

  // handler for adme and screenie bridge
  const hanleAdmeScreenieBridge = () => {
    //    //console.log("clicked", finalSmile);
    dispatch(setSingleSimleForBridge(finalSmile));
    navigate("/virtual-screening/properties-v3", {
      state: {
        smilename: finalSmile,
      },
    });
  };

  useEffect(() => {
    //console.log("admeProperties",admeProperties);
    //console.log("smileInfo",smileInfo);
    const setADMEAndToxValues = (DATA: any) => {
      // //console.log("data is ", DATA, admeProperties);

      setFinalSmile(DATA.smiles);
      const tempDATA = [];
      for (const element of Predict_vars) {   
        tempDATA.push(DATA[element] / admeToxLowerUpperLimit[element].upper);
      }
      

      setRadarValData(tempDATA);
      setImage(DATA["img"]);
      for (const key in DATA) {
        if (modelMap[key] && admeToxLowerUpperLimit[key]) {
          const heading = modelMap[key];
          const value = DATA[key];

          // adding an endpoint properties
          const newItem = {
            apiName: key,
            title: admeToxLowerUpperLimit[key].columnName
              ? admeToxLowerUpperLimit[key].columnName
              : key.toUpperCase(),
            value: value.toString(),
            color: "",
            info: admeToxLowerUpperLimit[key].info
              ? admeToxLowerUpperLimit[key].info
              : key.toUpperCase(),
            lowerValue: admeToxLowerUpperLimit[key].lower,
            upperValue: admeToxLowerUpperLimit[key].upper,
            toolTipText: "",
            toolTipColor: "",
          };

          // handling custom values of endpoints
          if (
            admeProperties == null &&
            ADME_TOX_CUSTOM_ENDPOINTS.includes(key)
          ) {
            newItem.value = ADD_ADME_TOX_CUSTOM_VALUES(
              key,
              newItem.value,
              DATA.properties.MolWt.toString()
            );
          }

          // adding tooltip text and color for color identifications
          const toolTipColorAndText = ADME_TOX_TOOLTIP(
            newItem.value,
            newItem.lowerValue,
            newItem.upperValue
          );
          newItem.toolTipText = toolTipColorAndText[0];
          newItem.toolTipColor = toolTipColorAndText[1];

          // adding properties and toxicity and adme array
          if (heading === "Toxicity") {
            const headingIndex = TOXICITY_DATA.findIndex(
              (item) => item.heading === heading
            );
            if (headingIndex !== -1) {
              TOXICITY_DATA[headingIndex].data.push(newItem);
            }
          } else {
            const headingIndex = ADME_DATA.findIndex(
              (item) => item.heading === heading
            );
            if (headingIndex !== -1) {
              ADME_DATA[headingIndex].data.push(newItem);
            }
          }
        }
      }

      // making adme data in order
      for (let i = 0; i < ADME_DATA.length; i++) {
        ADME_DATA[i].data = ADME_TOX_ORDER(
          ADME_DATA[i].data,
          ADME_DATA[i].heading
        );
      }

      // making toxicity data in order
      for (let i = 0; i < TOXICITY_DATA.length; i++) {
        TOXICITY_DATA[i].data = ADME_TOX_ORDER(
          TOXICITY_DATA[i].data,
          TOXICITY_DATA[i].heading
        );
      }

      setADME(ADME_DATA);
      setTox(TOXICITY_DATA);
      // setData(DATA);
    };

    const collectData = async (smileName: string) => {
      // //console.log("smileName", smileName);
      setLoading(true);

      try {
        await axios
          .post(
            `${ADMET_REST_URL}/predict_smiles_array/`,
            // 'http://gamma.knowdisdata.com:9070/predict_smiles_array/',
            [smileName],
            {
              headers: {
                accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {

            const DATA = response.data.response[0];
            setADMEAndToxValues(DATA);
          })
          .catch((err) => {
            ////console.log("error", err);
          });
      } catch (error) {
        ////console.log(error);
      } finally {
        setLoading(false);
        // dispatch(setSinglesmileData(null));
        ////console.log(image);
      }
    };

    if (smileInfo && smileInfo.response && !smiles) {
      // console.log("smileinfo", smileInfo);
      setSmiles(smileInfo.response[0].smiles);
      // collectData(smileInfo);
      setADMEAndToxValues(smileInfo.response[0]);
      dispatch(setSingleSmileDataFromADMEPage(null));
    } else if (admeProperties) {
      // //console.log("admeProperties",admeProperties)
      setADMEAndToxValues(admeProperties);
      dispatch(setSingleSmileData(null));
    } else if (!finalSmile && !loading) {
      navigate(-1);
    }

    if (chartRef.current) {
      chartRef.current.destroy();
    }
    if (finalSmile) {
      const ctx = document.getElementById("myChart") as HTMLCanvasElement;

      chartRef.current = new Chart(ctx, {
        type: "radar",
        data: radarData,
      });
    }
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [finalSmile]);

  return (
    <>
      {finalSmile && (
        <Box width="100%" py={2} pr={2} pl={2} sx={{ overflowX: "hidden" }}>
          <Box
            width="100%"
            mb={2}
            pr={1}
            pl={1}
            sx={{
              backgroundColor: "#cce5ff",
              borderRadius: 2,
              border: "1.5px solid #b8daff",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box
                  width="100%"
                  sx={{ display: "flex", alignItems: "center", height: "100%" }}
                >
                  <Typography
                    sx={{
                      color: "#004085",
                      fontWeight: 700,
                      fontSize: 18,
                      whiteSpace: "normal",
                    }}
                  >
                    {MOL_INFO.SMILE}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  width="100%"
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<DownloadIcon sx={{ fontSize: 12 }} />}
                    sx={{
                      fontSize: 12,
                      paddingTop: 1,
                      paddingBottom: 1,
                      margin: 1,
                    }}
                    onClick={exportCSV}
                  >
                    Download as CSV
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<ExploreIcon sx={{ fontSize: 12 }} />}
                    sx={{
                      fontSize: 12,
                      paddingTop: 1,
                      paddingBottom: 1,
                      margin: 1,
                      backgroundColor: "#582FF1;",
                    }}
                    onClick={hanleAdmeScreenieBridge}
                  >
                    Get Molecular Properties
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Grid item md={12} xs={12}>
                {/* <img src={''} style={{ width: '100%', height: '40vh' }} /> */}
                <Paper
                  variant="outlined"
                  sx={{ justifyContent: "center", display: "flex" }}
                  style={{ width: "100%", height: "490px" }}
                >
                  <canvas id="myChart" width={480}></canvas>
                </Paper>

                {/* <Paper variant="outlined">
                                    <img src={MOL_INFO.IMAGE2} style={{ width: '100%', height: '100%' }} />
                                </Paper> */}

                {/* <PhysicochemTable phychem_data={PHY_CHEM_DATA} /> */}
                {/* Physiochemical Table */}
                <ADMETable adme_data={adme} />
                {/* ADME Table */}

                {/* <Paper variant="outlined" sx={{ backgroundColor: '#d1ecf1', borderRadius: 2, padding: 2, marginTop: 2 }} square>
                                    <Typography sx={{ color: "#0c5460" }}>
                                        <span style={{ fontWeight: 700 }}>Tip : </span> {MOL_INFO.description}
                                    </Typography>
                                </Paper> */}
              </Grid>
            </Grid>

            <Grid item md={6} sm={12} xs={12}>
              <Grid item md={12} xs={12}>
                {image && (
                  <div
                    style={{
                      width: "100%",
                      height: 490,
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "center",
                      overflow: "hidden",
                      alignItems: "center",
                      borderRadius: "4px",
                    }}
                  >
                    <img
                      alt="smile string"
                      src={`data:image/svg+xml;base64,${btoa(image)}`}
                      style={{ width: "90%", height: "100%" }}
                    ></img>
                  </div>
                )}

                {/* <MedicinalchemTable medchem_data={filterData} /> */}
                {/* Medicinal Chemistry Table */}

                <ToxicityTable toxicity_data={tox} />
                {/* Toxicity Table */}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
      <IndefiniteLoader state={loading} />
    </>
  );
};

export default ADMEEvaluations;
